import React, { useCallback, useMemo } from 'react'
import { Table } from 'src/components/primitives/table'
import type { ExpandableColumn, TableSchema } from 'src/components/primitives/table'
import {
  FavoriteCell,
  CandidateNameCell,
  CandidateSourceCell,
  CandidateLastContactDateCell,
  CandidateStatusCell,
  CandidateCreatedDateCell,
  CandidateRejectionReasonCell,
  CandidateRejectedDateCell,
  CandidateNextContactDateCell,
  CandidateErrorStatusCell,
  CandidateErrorActionCell,
  CandidateJobTitleCell,
  CandidateCriteriaMatchCell,
  CandidateStageActionsCell,
  CandidateCriteriaMatchDetailsCell
} from '../candidate-table-cells'
import type { CandidateJobExpanded, CandidateJobRejectionReason } from 'src/libs/api/backend/candidate_jobs'
import { useDialog } from 'src/hooks/use-dialog'
import type { Row } from '@tanstack/react-table'
import { Flex } from 'src/components/primitives/flex'
import { DialogId } from 'src/contexts/dialogs'
import { useGlobalError } from 'src/hooks/use-global-error'
import { AllSizes, COLUMN, columnSorting } from './table-builder'
import type { ColumnSizes, DefaultTableSchema } from './table-builder'
import { usePauseCandidateSequence } from 'src/hooks/mutations/use-pause-candidate-job-sequence'
import { useRejectCandidate } from 'src/hooks/mutations/use-reject-candidate'

interface CandidatesTableProps {
  isLoading: boolean
  pageHeaderHeight?: number
  candidateJobs?: CandidateJobExpanded[]
  emptyState: React.ReactNode
  selectedRowsActions?: React.ReactNode
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  rowSelection: Record<string, boolean>
  visibleColumns: COLUMN[]
  expandableColumns?: ExpandableColumn[]
  onToggleExpandableColumn?: (columnId: string | null) => void
}

export const CandidatesTable = ({
  isLoading,
  candidateJobs,
  emptyState,
  pageHeaderHeight = 0,
  setRowSelection,
  rowSelection,
  selectedRowsActions,
  visibleColumns,
  onToggleExpandableColumn,
  expandableColumns
}: CandidatesTableProps): JSX.Element => {
  const { openDialog } = useDialog()
  const { isGlobalErrorOpen } = useGlobalError()
  const { setCandidateSequencePause } = usePauseCandidateSequence()
  const { rejectCandidate } = useRejectCandidate()

  const handlePauseSequence = useCallback((candidateJobIds: string[], isPaused: boolean): void => {
    setCandidateSequencePause({
      candidateJobIds,
      pause: isPaused
    })
  }, [setCandidateSequencePause])

  const rejectCandidates = useCallback((candidateJobIds: string[], rejectionReason: CandidateJobRejectionReason): void => {
    rejectCandidate({ candidateJobIds, rejectionReason })
  }, [rejectCandidate])

  const tableSchema: TableSchema<DefaultTableSchema> = useMemo(() => {
    const schema: Partial<TableSchema<DefaultTableSchema>> = {}

    visibleColumns.forEach((column) => {
      switch (column) {
        case COLUMN.FAVORITE:
          schema.favorite = 'Favorite'
          break
        case COLUMN.NAME:
          schema.name = 'Name'
          break
        case COLUMN.STATUS:
          schema.status = 'Status'
          break
        case COLUMN.SOURCE:
          schema.source = 'Source'
          break
        case COLUMN.JOB_TITLE:
          schema.jobTitle = 'Job Title'
          break
        case COLUMN.CRITERIA:
          schema.criteria = 'Criteria'
          break
        case COLUMN.CRITERIA_EXPANDED:
          schema.criteriaExpanded = ''
          break
        case COLUMN.CANDIDATE_STAGE_ACTIONS:
          schema.candidateStageActions = ''
          break
        case COLUMN.REJECTION_REASON:
          schema.rejectionReason = 'Reason'
          break
        case COLUMN.LAST_CONTACT:
          schema.lastContact = 'Last email'
          break
        case COLUMN.NEXT_CONTACT:
          schema.nextContact = 'Next email'
          break
        case COLUMN.CREATED_AT:
          schema.createdAt = 'Date added'
          break
        case COLUMN.REJECTED_AT:
          schema.rejectedAt = 'Archived at'
          break
        case COLUMN.ERROR:
          schema.error = 'Error'
          break
        case COLUMN.ERROR_ACTIONS:
          schema.errorActions = null
          break
        default:
          break
      }
    })

    return schema as TableSchema<DefaultTableSchema>
  }, [visibleColumns])

  const columnSizes = useMemo(() => {
    const sizes: ColumnSizes = {}
    const columns = visibleColumns as Array<keyof DefaultTableSchema>

    columns.forEach((column) => {
      if (column in AllSizes) {
        sizes[column] = AllSizes[column]
      }
    })

    return sizes
  }, [visibleColumns])

  const handleRowClick = useCallback((row: Row<DefaultTableSchema>): void => {
    openDialog(DialogId.CANDIDATE_DETAILS, row.original.candidateJob?.id)
  }, [openDialog])

  const mapCandidatesToSchema = useCallback((candidateJobs: CandidateJobExpanded[]): DefaultTableSchema[] => {
    return candidateJobs.map((candidateJob) => {
      const mappedData: Partial<DefaultTableSchema> = {
        id: candidateJob.id ?? '',
        candidateJob,
        favorite: <FavoriteCell candidateJob={candidateJob} />,
        name: <CandidateNameCell candidateJob={candidateJob} />
      }

      visibleColumns.forEach((column) => {
        switch (column) {
          case COLUMN.STATUS:
            mappedData.status = <CandidateStatusCell candidateJob={candidateJob} />
            break
          case COLUMN.SOURCE:
            mappedData.source = <CandidateSourceCell candidateJob={candidateJob} />
            break
          case COLUMN.JOB_TITLE:
            mappedData.jobTitle = <CandidateJobTitleCell candidateJob={candidateJob} />
            break
          case COLUMN.CRITERIA:
            mappedData.criteria = <CandidateCriteriaMatchCell candidateJob={candidateJob} />
            break
          case COLUMN.CRITERIA_EXPANDED:
            mappedData.criteriaExpanded = <CandidateCriteriaMatchDetailsCell candidateJob={candidateJob} />
            break
          case COLUMN.CANDIDATE_STAGE_ACTIONS:
            mappedData.candidateStageActions = <CandidateStageActionsCell candidateJob={candidateJob} />
            break
          case COLUMN.REJECTION_REASON:
            mappedData.rejectionReason = (
              <CandidateRejectionReasonCell candidateJob={candidateJob} />
            )
            break
          case COLUMN.LAST_CONTACT:
            mappedData.lastContact = <CandidateLastContactDateCell candidateJob={candidateJob} />
            break
          case COLUMN.NEXT_CONTACT:
            mappedData.nextContact = <CandidateNextContactDateCell candidateJob={candidateJob} />
            break
          case COLUMN.CREATED_AT:
            mappedData.createdAt = <CandidateCreatedDateCell candidateJob={candidateJob} />
            break
          case COLUMN.REJECTED_AT:
            mappedData.rejectedAt = <CandidateRejectedDateCell candidateJob={candidateJob} />
            break
          case COLUMN.ERROR:
            mappedData.error = <CandidateErrorStatusCell candidateJob={candidateJob} />
            break
          case COLUMN.ERROR_ACTIONS:
            mappedData.errorActions = (
              <CandidateErrorActionCell
                candidateJob={candidateJob}
                openDialog={openDialog}
                handlePauseSequence={handlePauseSequence}
                rejectCandidates={rejectCandidates}
              />
            )
            break
          default:
            break
        }
      })
      return mappedData as DefaultTableSchema
    })
  }, [handlePauseSequence, openDialog, rejectCandidates, visibleColumns])

  const tableData = useMemo(() => {
    return mapCandidatesToSchema(candidateJobs ?? [])
  // DO NOT REMOVE THIS ESLINT FLAG, or it will keep on refreshing on row selection
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateJobs])

  return (
    <>
      <Table<DefaultTableSchema>
        isLoading={isLoading}
        isGlobalErrorOpen={isGlobalErrorOpen}
        pageHeaderHeight={pageHeaderHeight}
        columnSorting={columnSorting}
        columnSizes={columnSizes}
        schema={tableSchema}
        tableData={tableData}
        onRowClick={(row) => {
          handleRowClick(row)
        }}
        onToggleExpandableColumn={onToggleExpandableColumn}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        ignoredCellsOnRowClick={[COLUMN.FAVORITE, COLUMN.STATUS, COLUMN.CANDIDATE_STAGE_ACTIONS, COLUMN.CRITERIA, COLUMN.CRITERIA_EXPANDED]}
        expandableColumns={expandableColumns}
        emptyState={emptyState}
        selectionActions={<Flex $gap={6}>{selectedRowsActions}</Flex>}
      />
    </>
  )
}
