import { DIALOG_HEADER_HEIGHT_LG } from 'src/styles/constants'
import styled from 'styled-components'

export const SequenceEditor = styled.div<{ $isEditable: boolean }>`
  position: relative;
  width: 100%;
  opacity: ${({ $isEditable }) => ($isEditable ? 1 : 0.5)};
  pointer-events: ${({ $isEditable }) => ($isEditable ? 'all' : 'none')};
  height: 100%;
  * {
    pointer-events: ${({ $isEditable }) => ($isEditable ? 'all' : 'none')} !important;
    user-select: ${({ $isEditable }) => ($isEditable ? 'auto' : 'none')} !important;
  }
`

export const Sidebar = styled.div`
  /* position: fixed; */
  position: absolute;
  /* top: ${DIALOG_HEADER_HEIGHT_LG}; */
  top: 0;
  width: 30%;
  height: 100%;
  /* height: calc(100% - ${DIALOG_HEADER_HEIGHT_LG}); */
  /* background-color: ${({ theme }) => theme.colors.bgPrimary}; */
  padding: 1.5rem 1.5rem 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
`

export const StepSelectors = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`

export const StepSelector = styled.button<{
  $isGenerating: boolean
  $isCurrentStep: boolean
}>`
  position: relative;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 6px;
  border: ${({ $isGenerating }) => ($isGenerating ? 'dashed 1px' : 'solid 1px')};
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:focus {
    outline: none;
    &:before {
      outline: none;
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      width: 100%;
      height: 100%;
      border: solid 2px;
      border-radius: 7px;
      border-color: ${({ theme }) => theme.colors.tintBg};
    }
  }
  &:before {
    content: '';
    display: ${({ $isCurrentStep }) => ($isCurrentStep ? 'block' : 'none')};
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: solid 1px;
    border-radius: 7px;
    border-color: ${({ theme }) => theme.colors.tintBg};
  }
  &:not(:last-of-type) {
    &:after {
      content: '';
      position: absolute;
      bottom: calc(-0.625rem - 1px);
      left: 50%;
      width: 1px;
      height: 0.625rem;
      background-color: ${({ theme }) => theme.colors.borderTranslucent};
    }
  }
`

export const StepSelectorSpinner = styled.div`
  width: 1rem;
  height: 1rem;
`

export const StepsSection = styled.div`
  position: relative;
  left: 30%;
  width: 70%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  padding: 0 2.5rem 1.5rem 2.5rem;
  overflow: hidden;
`

export const SequenceStep = styled.div`
  width: 100%;
  /* subtract bottom margin of header */
  height: 100%;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
`

export const StepHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  min-height: 2.5rem;
  padding: 0 0.75rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
`

export const StepContent = styled.div`
  position: relative;
  border-radius: 6px;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderOpaque};
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  flex-grow: 1;
`

export const AddStepButton = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.75rem 0 0 0;
  justify-content: center;
  button {
    z-index: 2;
    border-color: ${({ theme }) => theme.colors.borderTranslucent};
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 0.75rem;
    background-color: ${({ theme }) => theme.colors.borderTranslucent};
    z-index: 1;
  }
`

export const AutoArchiveStep = styled(StepHeader)`
  width: 100%;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderOpaque};
`

export const Actions = styled.div`
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SequenceInstructions = styled.div``

export const AutoArchiveBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0.75rem 0 0 0;
  justify-content: center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 0.75rem;
    background-color: ${({ theme }) => theme.colors.borderTranslucent};
    z-index: 1;
  }
`

export const AutoArchiveContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  gap: 0.375rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  justify-content: center;
`

export const SendPreviewEmailInputPopup = styled.div`
  position: absolute;
  bottom: calc(48px + 12px);
  left: 12px;
  width: calc(100% - (12px * 2));
  padding: 8px 8px 8px 12px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgTertiary};
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  form {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    input {
      width: 100%;
      flex: 1 1 100%;
      height: 32px;
    }
  }
`
