export enum LocalStorageKey {
  VIEW_MODE = 'viewMode',
  LAST_VIEWED_JOB = 'lastViewedJob',
  HAS_LOGGED_IN = 'hasLoggedIn',
  SUBMIT_FEEDBACK_NOTICED = 'submitFeedbackNoticed',
  CHROME_EXTENSION_NOTICED = 'chromeExtensionNoticed',
  JOB_SOURCING_STATUS = 'jobSourcingStatus',
  REPORT_FEEDBACK_BANNER_CLOSED = 'reportFeedbackBannerClosed',
}

export enum ViewMode {
  DEFAULT = 'default',
  COMPACT = 'compact',
  TABLE = 'table'
}

export const PinExtensionId = 'infcaknhippabnplinmgidmjojcbkijd'
export const PinExtensionChromeStoreUrl = `https://chromewebstore.google.com/detail/${PinExtensionId}`
