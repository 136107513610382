import { useEffect, useMemo } from 'react'
import { Button } from 'src/components/primitives/button'
import { OrgJobsDropdown } from '../org-jobs-dropdown'
import RouteBuilder from 'src/libs/route-builder'
import { useParams } from 'react-router'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'
import { When } from '../when'
import { useSession } from 'src/hooks/use-session'
import { Flex } from 'src/components/primitives/flex'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { Heading } from 'src/components/primitives/typography'
import * as S from './navigation.styled'
import { useQueryParams } from 'src/hooks/use-query-params'
import { generateUrlWithParams } from 'src/utils/format-url'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { CHANNEL_TYPE, EVENT_TYPE, getChannelName } from 'src/libs/api/backend/websockets'
import { SequenceStepGenerationState, SequenceStepType } from 'src/libs/api/backend/sequences'
import { isSequenceStepsEmpty, sortAndFilterSequenceSteps } from 'src/libs/sequence'
import { useJobStatsQuery } from 'src/hooks/queries/use-job-stats'
import { useJobQuery } from 'src/hooks/queries/use-job'
import type { Job } from 'src/libs/api/backend/jobs'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { NavigationItem } from './navigation-item'
import type { NavItem, NavigationSubItem } from './navigation-item'
import { NavigationBottom } from './navigation-bottom'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'
import { useChannel } from 'ably/react'

interface NavigationProps {
  isCollapsed: boolean
  onCollapseChange: (value: boolean) => void
}

const createSequenceName = (position?: number, subject?: string | null): string => {
  if (position === -1) {
    return 'Not Yet Contacted'
  }
  if (position === 0) {
    return 'Initial Email'
  }
  if (isNil(subject)) {
    return `Follow Up #${position}`
  }
  return 'New Thread'
}

// const matchCount = (candidateMatchCount: number | null | undefined): string | number | undefined => {
//   if (!candidateMatchCount) {
//     return undefined
//   }
//
//   return candidateMatchCount > 1000 ? `~${Math.round(candidateMatchCount / 1000)}k` : candidateMatchCount
// }

export const Navigation = ({ isCollapsed, onCollapseChange }: NavigationProps): JSX.Element => {
  const { user: currentUser, featureFlags } = useSession()
  const { openDialog, controlDialog, isDialogOpen } = useDialog()
  const { jobId } = useParams()
  const { data: sequence } = useJobSequenceQuery()
  const { data: jobData } = useJobQuery()
  const { data: candidateJobCounts } = useCandidateJobCountsQuery()
  const [reportFeedbackBannerClosed] = useLocalStorage(LocalStorageKey.REPORT_FEEDBACK_BANNER_CLOSED, false)
  const searchDialogIsOpen = isDialogOpen(DialogId.SEARCH_CANDIDATES)
  const { setParam } = useQueryParams()
  const { data: jobSearchRefinements = [] } = useListJobSearchRefinementsQuery()
  const { data: jobStats } = useJobStatsQuery()
  const CANDIDATES_BASE_PATH = useMemo(() => `/jobs/${jobId}/candidates`, [jobId])

  useChannel({ channelName: getChannelName(CHANNEL_TYPE.NOTIFICATIONS) }, (message) => {
    const { name: eventName, data } = message
    if (eventName === EVENT_TYPE.ORG_NOTIFICATION || eventName === currentUser?.id) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.notifications]
      })
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.notificationsCount]
      })
    }
    if (eventName === jobId && data?.sequenceStepStatus === SequenceStepGenerationState.COMPLETED) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.jobSequence, jobId]
      })
    }
  })

  const readyToSendCount = useMemo(() => {
    if (!featureFlags?.includes(FeatureFlags.MANUAL_OUTREACH)) {
      return 0
    }
    return candidateJobCounts?.readyToSend ?? 0
  }, [candidateJobCounts?.readyToSend, featureFlags])

  const hasCandidateJobsError = useMemo(() => {
    return !isNil(candidateJobCounts?.prospectingErrors) && candidateJobCounts.prospectingErrors > 0
  }, [candidateJobCounts?.prospectingErrors])

  const sortedSteps = sortAndFilterSequenceSteps(sequence).filter((step) => step.generationState === null)

  const isEmptySequence = isSequenceStepsEmpty(sequence)

  const outreachBadgeText = useMemo(() => {
    if (sequence?.active === false) {
      return 'PAUSED'
    }

    if (jobId && jobStats && jobStats[jobId]?.hasDeactivatedSenders) {
      return 'PAUSED'
    }

    if (isEmptySequence) {
      return 'PAUSED'
    }
    return undefined
  }, [sequence?.active, jobId, jobStats, isEmptySequence])

  const aiAutomationsCount = useMemo((): { activated: number, total: number } => {
    let count = 0
    for (const key in jobData) {
      if (key.startsWith('automate') && !key.includes('Daily') && jobData[key as keyof Job]) {
        count++
      }
    }
    if (sequence?.active) {
      count++
    }
    if (!isNil(jobData?.autoExportCandidate)) {
      count++
    }
    return {
      activated: count,
      total: featureFlags?.includes(FeatureFlags.ATS_INTEGRATION) ? 6 : 5
    }
  }, [jobData, sequence?.active, featureFlags])

  const sequenceSteps: NavigationSubItem[] = useMemo(() => {
    let steps: NavigationSubItem[] = []
    if (hasCandidateJobsError) {
      steps.push(
        {
          id: 'sequence-errors',
          title: 'Errors',
          badgeVariant: 'negative',
          icon: Icons.alertTriangle,
          iconColor: 'fgPrimary',
          count: candidateJobCounts?.prospectingErrors,
          href: '/errors'
        }
      )
    }
    if (sequence?.sequenceSteps?.some((step) => step.type === SequenceStepType.MANUAL_EMAIL)) {
      steps.push(
        {
          id: 'ready-to-send',
          title: 'Ready To Send',
          searchParams: generateUrlWithParams('', { readyToSend: 'true' }),
          badgeVariant: readyToSendCount > 0 ? 'tint' : 'bgSecondary',
          icon: Icons.send,
          iconColor: 'fgPrimary',
          count: readyToSendCount,
          featureFlag: FeatureFlags.MANUAL_OUTREACH
        }
      )
    }

    steps = steps.concat([
      {
        id: 'all-in-outreach',
        title: 'All In Outreach',
        href: '/',
        count: candidateJobCounts?.stages[CandidateJobStage.PROSPECTING]
      },
      {
        id: 'not-yet-contacted',
        title: createSequenceName(-1),
        searchParams: generateUrlWithParams('', { position: '-1', title: createSequenceName(-1) }),
        count: candidateJobCounts?.sequenceSteps[-1]
      }
    ])

    if (!isEmptySequence) {
      let lastNewThreadIndex = 0
      steps = steps.concat(sortedSteps.map((step, i) => {
        if (i === 0) {
          return {
            id: step.id,
            title: createSequenceName(i),
            searchParams: generateUrlWithParams('', {
              position: i.toString(),
              title: createSequenceName(i)
            }),
            count: candidateJobCounts?.sequenceSteps[i]
          }
        } else if (isNil(step.subject)) {
          return {
            id: step.id,
            title: createSequenceName(i - lastNewThreadIndex, step?.subject),
            searchParams: generateUrlWithParams('', {
              position: i.toString(),
              title: createSequenceName(i - lastNewThreadIndex, step?.subject)
            }),
            count: candidateJobCounts?.sequenceSteps[i]
          }
        } else {
          lastNewThreadIndex = i
          return {
            id: step.id,
            title: createSequenceName(i),
            searchParams: generateUrlWithParams('', {
              position: i.toString(),
              title: createSequenceName(i)
            }),
            count: candidateJobCounts?.sequenceSteps[i]
          }
        }
      }))
    }
    return steps
  }, [
    candidateJobCounts?.prospectingErrors,
    candidateJobCounts?.sequenceSteps,
    candidateJobCounts?.stages,
    hasCandidateJobsError,
    isEmptySequence,
    readyToSendCount,
    sequence?.sequenceSteps,
    sortedSteps
  ])

  const navigationItems: NavItem[] = useMemo(() => ([
    {
      id: 'sourcing',
      title: 'Sourcing',
      type: 'link',
      icon: Icons.binoculars,
      basePath: `${CANDIDATES_BASE_PATH}/sourcing`,
      href: jobSearchRefinements.length > 0
        ? generateUrlWithParams('', { jobSearchRefinementId: jobSearchRefinements[0].id })
        : '/search',
      count: (candidateJobCounts?.manuallyAdded ?? 0) + (candidateJobCounts?.shortlisted?.sourced ?? 0),
      subItems: [
        // {
        //   title: 'All sourced',
        //   href: '/',
        //   count: candidateJobCounts?.stages[CandidateJobStage.SOURCED]
        // },
        {
          id: 'manually-added',
          title: 'Manually Added',
          href: '/manual',
          count: candidateJobCounts?.manuallyAdded
        },
        {
          id: 'shortlisted',
          title: 'Shortlisted',
          href: '/shortlisted',
          count: candidateJobCounts?.shortlisted?.sourced ?? 0,
          icon: Icons.star
        },
        ...((!jobData?.deleted || jobSearchRefinements.length > 0)
          ? [{
              id: 'ai-sourced',
              title: 'AI Sourced',
              type: 'separator' as const
            }]
          : []),
        ...jobSearchRefinements.map((refinement, i) => ({
          id: refinement.id,
          title: refinement.title ?? `Search #${i + 1}`,
          loading: !refinement.title,
          // count: matchCount(refinement.candidateMatchCount),
          searchParams: generateUrlWithParams('', { jobSearchRefinementId: refinement.id })
        })),
        ...(!jobData?.deleted
          ? [{
              id: 'new-search',
              title: 'New Pin Search',
              href: '/search',
              icon: Icons.plusCircle
            }]
          : [])
        // {
        //   // XXX
        //   title: 'New Pin Search',
        //   href: '/search',
        //   icon: 'plus-circle'
        // }
      ]
    },
    // {
    //   title: 'Shortlisted',
    //   type: 'link',
    //   basePath: `${CANDIDATES_BASE_PATH}/shortlisted`,
    //   href: '/',
    //   icon: Icons.star,
    //   count: candidateJobCounts?.shortlisted
    //   // subItems: [
    //   //   {
    //   //     title: 'All shortlisted',
    //   //     href: '/',
    //   //     count: candidateJobCounts?.shortlisted
    //   //   }
    //   // ]
    // },
    {
      id: 'outreach',
      title: 'Outreach',
      type: 'link',
      icon: Icons.mails,
      count:
        readyToSendCount > 0
          ? readyToSendCount
          : hasCandidateJobsError
            ? candidateJobCounts?.prospectingErrors
            : candidateJobCounts?.stages[CandidateJobStage.PROSPECTING],
      expandedCount: candidateJobCounts?.stages[CandidateJobStage.PROSPECTING],
      badgeText: outreachBadgeText,
      badgeVariant: outreachBadgeText ? 'negativeLight' : hasCandidateJobsError ? 'negative' : readyToSendCount > 0 ? 'tint' : 'bgSecondary',
      basePath: `${CANDIDATES_BASE_PATH}/in-sequence`,
      href: readyToSendCount > 0 ? generateUrlWithParams('', { readyToSend: 'true' }) : hasCandidateJobsError ? '/errors' : '/',
      subItems: sequenceSteps
    },
    {
      id: 'inbox',
      title: 'Inbox',
      type: 'link',
      basePath: RouteBuilder.build('JOBS_INBOX', { jobId: jobId ?? '' }),
      href: '/candidate_jobs',
      icon: Icons.inbox,
      count: candidateJobCounts?.inbox.totalCommunicating,
      badgeVariant: candidateJobCounts?.inbox.totalAwaitingResponse ? 'tint' : 'bgSecondary',
      subItems: [
        {
          id: 'all-in-inbox',
          title: 'All In Inbox',
          href: '/candidate_jobs',
          count: candidateJobCounts?.inbox.totalCommunicating,
          icon: Icons.folder,
          badgeVariant: candidateJobCounts?.inbox.totalAwaitingResponse ? 'tint' : 'bgSecondary'
        },
        {
          id: 'my-communications',
          title: 'My Communications',
          searchParams: `?lastCommunicatedWithUserId=${currentUser?.id}`,
          count: candidateJobCounts?.inbox.myTotalCommunicating,
          icon: Icons.folder,
          badgeVariant: candidateJobCounts?.inbox.myTotalAwaitingResponse ? 'tint' : 'bgSecondary'
        }
      ]
    },
    {
      id: 'separator-1',
      title: '',
      type: 'separator'
    },
    {
      id: 'all-candidates',
      title: 'All Candidates',
      basePath: RouteBuilder.build('JOBS_CANDIDATES_ALL', { jobId }),
      count: (candidateJobCounts?.total.totalWithoutDataWarehouseSourcedCount ?? 0) - (candidateJobCounts?.stages?.REJECTED ?? 0),
      icon: Icons.users,
      href: '/',
      type: 'link',
      subItems: [
        {
          id: 'all-candidates-sub',
          title: 'All Candidates',
          href: '/',
          count: (candidateJobCounts?.total.totalWithoutDataWarehouseSourcedCount ?? 0) - (candidateJobCounts?.stages?.REJECTED ?? 0)
        },
        {
          id: 'shortlisted',
          title: 'Shortlisted',
          href: '/shortlisted',
          icon: Icons.star,
          count: candidateJobCounts?.shortlisted.total
        },
        {
          id: 'archived',
          title: 'Archived',
          href: '/archived',
          icon: Icons.archive,
          count: candidateJobCounts?.stages[CandidateJobStage.REJECTED]
        }
      ]
    },
    {
      id: 'reports',
      title: 'Reports',
      basePath: RouteBuilder.build('JOBS_REPORTS', { jobId: jobId ?? '' }),
      icon: Icons.pieChart,
      href: '/candidates',
      type: 'link',
      subItems: [
        {
          id: 'report-candidates',
          title: 'Candidates',
          href: '/candidates'
        },
        {
          id: 'report-communications',
          title: 'Communications',
          href: '/communications'
        },
        ...(reportFeedbackBannerClosed
          ? [{
              id: 'suggest-report',
              title: 'Suggest a report',
              icon: Icons.conciergeBell,
              onClick: () => {
                openDialog(DialogId.SEND_FEEDBACK, {
                  type: FeedbackType.IDEA,
                  defaultContent: 'I have a suggestion for reports:<br>'
                })
              }
            }]
          : []
        )
      ]
    },
    {
      id: 'settings',
      title: 'Job Settings',
      icon: Icons.settings2,
      type: 'link',
      basePath: `${RouteBuilder.build('SETTINGS_JOB')}/${jobId}`,
      href: '/details',
      subItems: [
        {
          id: 'position-details',
          title: 'Position Details',
          href: '/details',
          icon: Icons.info
        },
        {
          id: 'outreach-sequence',
          title: 'Outreach Sequence',
          href: '/email-sequence',
          icon: Icons.mail
        },
        {
          id: 'company-ratings',
          title: 'Companies Ratings',
          href: '/companies-ratings',
          icon: Icons.thumbsUp,
          featureFlag: FeatureFlags.COMPANY_PREFERENCES
        },
        {
          id: 'email-preferences',
          title: 'Email Preferences',
          href: '/email-preferences',
          icon: Icons.mail
        },
        {
          id: 'calendar-preferences',
          title: 'Calendar Preferences',
          href: '/calendar-preferences',
          icon: Icons.calendar,
          featureFlag: FeatureFlags.CALENDAR
        },
        {
          id: 'user-team-settings',
          title: 'User & Team Settings',
          icon: Icons.settings,
          onClick: () => {
            setParam('settings', SettingParams.PROFILE)
            openDialog(DialogId.DEFAULT_SETTINGS)
          }
          // // iconColor: 'tintBg',
          // colorTheme: 'tint'
        }
      ]
    },
    {
      id: 'pin-automation',
      title: 'Pin Automation',
      icon: Icons.pinNormal,
      type: 'link',
      count: `${aiAutomationsCount.activated} / ${aiAutomationsCount.total}`,
      basePath: RouteBuilder.build('JOBS_AUTOMATION', { jobId: jobId ?? '' }),
      href: '/',
      featureFlag: FeatureFlags.AI_AUTOMATION
    }
  ]), [CANDIDATES_BASE_PATH, candidateJobCounts, currentUser?.id, aiAutomationsCount, hasCandidateJobsError, jobData?.deleted, jobId, jobSearchRefinements, openDialog, outreachBadgeText, readyToSendCount, reportFeedbackBannerClosed, sequenceSteps, setParam])

  // Control search dialog with cmd+k
  useEffect(() => {
    const down = (event: KeyboardEvent): void => {
      if (event.key === 'k' && event.metaKey) {
        event.preventDefault()
        controlDialog(DialogId.SEARCH_CANDIDATES, !searchDialogIsOpen)
      }
    }

    document.addEventListener('keydown', down)
    return () => {
      document.removeEventListener('keydown', down)
    }
  }, [searchDialogIsOpen, controlDialog])

  return (
    <S.Navigation data-name="Navigation" $isCollapsed={isCollapsed}>
      <S.MainContent data-name="MainContent">
        <S.Top $isCollapsed={isCollapsed}>
          <When condition={!isCollapsed}>
            <Flex $align="center" $gap={4}>
              <Icon name="pin-normal" size={18} />
              <Heading $whiteSpace="nowrap" $ellipsis>
                Pin
              </Heading>
            </Flex>
          </When>
          <Flex $gap={2} $justify={isCollapsed ? 'center' : 'flex-end'} $width="auto">
            <When condition={!isCollapsed}>
              <Button
                ariaLabel='New job position'
                leadingIcon={Icons.plusCircle}
                $variant="ghost"
                $colorTheme="muted"
                $height={32}
                $width={32}
                onClick={() => {
                  openDialog(DialogId.CREATE_NEW_JOB)
                }}
                tooltip={{
                  text: 'New job position',
                  position: 'bottom'
                }}
              />
              <Button
                ariaLabel="Open search modal"
                leadingIcon={Icons.search}
                $variant="ghost"
                $colorTheme="muted"
                $height={32}
                $width={32}
                onClick={() => {
                  openDialog(DialogId.SEARCH_CANDIDATES)
                }}
                tooltip={{
                  text: 'Search',
                  position: 'bottom',
                  keys: ['cmd', 'k']
                }}
              />
            </When>
            <Button
              ariaLabel="Collapse navigation bar"
              leadingIcon={isCollapsed ? Icons.chevronsRight : Icons.chevronsLeft}
              $variant="ghost"
              $colorTheme="muted"
              $height={32}
              $width={32}
              onClick={() => {
                onCollapseChange(!isCollapsed)
              }}
              $borderRadius={9999}
            />
          </Flex>
        </S.Top>
        <OrgJobsDropdown isCollapsed={isCollapsed} />
        <S.NavigationItems $isCollapsed={isCollapsed}>
          {navigationItems.map((navItem) => (
            <NavigationItem key={navItem.id} navItem={navItem} isCollapsed={isCollapsed} featureFlags={featureFlags} />
          ))}
        </S.NavigationItems>
      </S.MainContent>
      <NavigationBottom isCollapsed={isCollapsed} />
    </S.Navigation>
  )
}
