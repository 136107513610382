import { useMemo, useState } from 'react'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import * as S from './email-statistics.styled'
import { Flex } from 'src/components/primitives/flex'
import { Icon } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { Paragraph } from 'src/components/primitives/typography'
import type { EmailMessage } from 'src/libs/api/backend/candidate_activities'
import { When } from '../when'
import { pluralize } from 'src/libs/pluralize'
import { format, formatDistanceToNow } from 'date-fns'

interface EmailStatisticsProps {
  emailMessage: EmailMessage
}

interface RowProps {
  isOpen: boolean
  isTrigger: boolean
  icon: IconName
  label: string
  happenedAt?: Date
}

const Row = ({ isOpen, icon, label, happenedAt, isTrigger }: RowProps): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false)

  const formattedDate = happenedAt ? format(happenedAt, 'MMM d yyyy h:mmaaa') : ''
  const relativeDate = happenedAt ? formatDistanceToNow(happenedAt, { addSuffix: true }) : ''

  return (
    <S.Row
      $isOpen={isOpen}
      onMouseEnter={() => { setIsHovering(true) }}
      onMouseLeave={() => { setIsHovering(false) }}
      >
      <Flex $gap={8} $align="center">
        <Icon name={icon} size={12} color="fgSecondary" />
        <Paragraph size="XS" $color="fgSecondary" $whiteSpace="nowrap">
          {label}
        </Paragraph>
      </Flex>
      <Paragraph size="XS" $color="fgTertiary" $whiteSpace="nowrap">
        {isTrigger
          ? `last opened ${relativeDate}`
          : isHovering
            ? formattedDate
            : relativeDate
        }
      </Paragraph>
    </S.Row>
  )
}

export const EmailStatistics = ({ emailMessage }: EmailStatisticsProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  const preparedOpenStats = useMemo(() => {
    const emailOpens = emailMessage?.manualEmailMessage?.candidateEmailOpens ?? []
    const linkOpens = emailMessage?.manualEmailMessage?.candidateEmailLinkOpens ?? []
    const allOpens = [...emailOpens, ...linkOpens]
    const uniqueOpens = allOpens.reduce<Array<typeof emailOpens[0] | typeof linkOpens[0]>>((acc, current) => {
      const x = acc.find(item => item?.createdAt === current?.createdAt)
      if (!x && current) {
        return [...acc, current]
      } else {
        return acc
      }
    }, [])
    return uniqueOpens.sort((a, b) => {
      const dateB = b?.createdAt ? new Date(b.createdAt).getTime() : 0
      const dateA = a?.createdAt ? new Date(a.createdAt).getTime() : 0
      return dateB - dateA
    })
  }, [emailMessage])

  const openCount = useMemo(() => preparedOpenStats?.length ?? 0, [preparedOpenStats])
  const clickCount = useMemo(() => preparedOpenStats?.filter(open => open && 'link' in open).length ?? 0, [preparedOpenStats])

  if (!openCount && !clickCount) {
    return <></>
  }

  const triggerLabel = (): string => {
    const parts = []
    if (openCount > 0) {
      parts.push(`Email opened ${pluralize(openCount, 'time')}`)
    }
    if (clickCount > 0) {
      parts.push(`Link clicked ${pluralize(clickCount, 'time')}.`)
    }
    return parts.join('. ')
  }

  return (
    <S.EmailStatistics $isOpen={isOpen}>
      <DivAsButton
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        ariaLabel="Toggle email statistics"
      >
        <When condition={!isOpen}>
          <S.Trigger>
            <Row
              isOpen={isOpen}
              isTrigger
              icon="mail-open"
              // label={`Email opened ${pluralize(openCount, 'time')}`}
              label={triggerLabel()}
              happenedAt={preparedOpenStats?.[0]?.createdAt}
            />
          </S.Trigger>
        </When>
        <When condition={isOpen}>
          <S.EmailStatisticsDetails>
            {preparedOpenStats?.map((open) => {
              if (!open) return null
              const isLinkOpen = 'link' in open
              return (
                <Row
                  key={open.id}
                  isOpen={isOpen}
                  icon={isLinkOpen ? 'mouse-pointer-click' : 'mail-open'}
                  label={isLinkOpen ? 'Link clicked' : 'Email opened'}
                  happenedAt={open?.createdAt}
                  isTrigger={false}
                />
              )
            })}
          </S.EmailStatisticsDetails>
        </When>
      </DivAsButton>
    </S.EmailStatistics>
  )
}
