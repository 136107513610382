import { CANDIDATES_TABLE_HEADER_HEIGHT } from 'src/styles/constants'
import styled, { css } from 'styled-components'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
`

export const Head = styled.thead`
  width: 100%;
  display: contents;
  position: relative;
  tr {
    display: contents;
  }
`

export const ColumnHead = styled.th<{ $isExpandable?: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  z-index: 99;
  top: 0;
  position: sticky;
  padding: 1.25rem 0.5rem;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  line-height: ${({ theme }) => theme.lineHeights['1.33']};
  letter-spacing: ${({ theme }) => theme.letterSpacings['0.12']};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.colors.fgPrimary};
  white-space: nowrap;
  svg {
    /* optical alignment fix */
    transform: translateY(-1px);
  }
  ${({ $isExpandable }) => $isExpandable && css`
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.borderTranslucent};
    }
    &::before {
      width: calc(100% - 1px);
      background-color: ${({ theme }) => theme.colors.bgTertiary};
      left: -1px;
      border-left: solid 1px;
      border-color: ${({ theme }) => theme.colors.borderTranslucent};
    }
    &::after {
      right: 0px;
    }
  `}
`

export const ColumnHeadInner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-right: 0.5rem;
`

export const HeadActions = styled.th`
  position: absolute;
  top: 0;
  left: 48px;
  width: calc(100% - 48px);
  height: 100%;
  max-height: ${CANDIDATES_TABLE_HEADER_HEIGHT};
  padding: 1.25rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  p {
    font-variant-numeric: tabular-nums;
  }
`

export const Body = styled.tbody`
  display: contents;
  tr {
    display: contents;
    // &:nth-child(even) {
    //   background-color: ${({ theme }) => theme.colors.bgSecondary};
    // }
    &[data-selection='active'] {
      background-color: ${({ theme }) => theme.colors.tintTranslucent5} !important;
    }
    @media (hover: hover) {
      &:hover {
        background-color: ${({ theme }) => theme.colors.bgTertiary} !important;
      }
    }
  }
`

export const CheckboxIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const Checkbox = styled.div`
  position: relative;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    &:checked + label {
      background-color: ${({ theme }) => theme.colors.tintBg};
    }
    &:not(:checked) + label {
      background-color: ${({ theme }) => theme.colors.bgPrimary};
    }
    &:checked + label + ${CheckboxIcon} {
      svg {
        &:nth-of-type(1) {
          display: block;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
    &:not(:checked) + label + ${CheckboxIcon} {
      svg {
        &:nth-of-type(1) {
          display: none;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
    &:indeterminate + label {
      background-color: ${({ theme }) => theme.colors.bgSecondary};
    }
    &:indeterminate + label + ${CheckboxIcon} {
      svg {
        stroke: ${({ theme }) => theme.colors.fgTertiary} !important;
        &:nth-of-type(1) {
          display: none;
        }
        &:nth-of-type(2) {
          display: block;
        }
      }
    }
  }
  label {
    all: unset;
    flex: 0 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    border: solid 0.5px;
    border-color: ${({ theme }) => theme.colors.borderTranslucent};
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
    z-index: 2;
  }
`

export const TableRow = styled.tr`
  cursor: pointer;
  display: contents;
`

export const TableCell = styled.td<{ $isExpandable?: boolean }>`
  padding: 0.75rem 0.5rem;
  background-color: inherit;
  display: flex;
  align-items: center;
  ${({ $isExpandable }) => $isExpandable && css`
    border-right: solid 1px;
    border-color: ${({ theme }) => theme.colors.borderTranslucent};
  `}
  @media (hover: hover) {
    &:hover {
      background-color: inherit;
    }
  }

`

export const TableWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
`
