import { EmailComposer } from '../email-composer'
import type { EmailComposerProps } from '../email-composer'
import { Paragraph } from 'src/components/primitives/typography'
import { When } from '../when'
import { Button } from 'src/components/primitives/button'
import * as S from './inbox-email-editor.styled'
import { StandaloneSwitch } from '../standalone-switch'
import { useParams } from 'react-router'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { useState } from 'react'

export interface InboxEmailEditorProps extends EmailComposerProps {
  isAiGeneratedEmail?: boolean
  onClose?: () => void
  onSendEmail?: () => void
  onGoToNextCandidateChange?: (value: boolean) => void
}

export const InboxEmailEditor = ({
  isAiGeneratedEmail = false,
  onClose,
  onSendEmail,
  onGoToNextCandidateChange,
  currentData,
  ...props
}: InboxEmailEditorProps): JSX.Element => {
  const { candidateJobId } = useParams()
  const { data: candidateJob } = useCandidateJobQuery({
    candidateJobId
  })
  const [isSendable, setIsSendable] = useState(false)

  return (
    <S.InboxEmailEditor>
      <S.Editor $isAiGeneratedEmail={isAiGeneratedEmail}>
        <When condition={isAiGeneratedEmail}>
          <S.AiGeneratedBanner>
            <Paragraph size="XS" $color='fgPrimary'>
              Pin's suggested reply
            </Paragraph>
          </S.AiGeneratedBanner>
        </When>
        <S.EditorInner>
          <EmailComposer
            forceEditorFocus
            onClose={onClose}
            onSendableStateChange={(sendable) => {
              setIsSendable(sendable)
            }}
            $editorHeight='fit-content'
            useAttachments={true}
            leftActions={
              <Button
                $variant="flat"
                $colorTheme="tint"
                $height={24}
                $fontSize={12}
                disabled={!isSendable}
                onClick={() => {
                  if (onSendEmail) {
                    onSendEmail()
                  }
                }}
              >
                Queue send
              </Button>
            }
            rightActions={
              <StandaloneSwitch
                name="goToNextCandidate"
                label="Go to next candidate"
                defaultChecked={false}
                onChange={(value) => {
                  if (onGoToNextCandidateChange) {
                    onGoToNextCandidateChange(value)
                  }
                }}
                $direction="row-reverse"
                $variant="ghost"
                $toggleSize={12}
              />
            }
            currentData={{ ...currentData, recipients: candidateJob ? [candidateJob] : null }}
            {...props}
          />
        </S.EditorInner>
      </S.Editor>
    </S.InboxEmailEditor>
  )
}
