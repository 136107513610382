import { isNil } from 'lodash'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import { Flex } from 'src/components/primitives/flex'
import { Caption } from 'src/components/primitives/typography'
import {
  CandidateJobSequenceFilter,
  candidateJobSequenceFilterDisplay,
  getCandidateJobSequenceFilterFromDisplay
} from 'src/libs/api/backend/candidate_jobs'
import * as S from './candidates-in-sequence-stats.styled'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useMemo } from 'react'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { When } from '../when'

interface BaseProps {
  isFilterable?: boolean
}

interface FilterButtonProps extends BaseProps {
  title: string
  count: number
  percentage?: number
}

interface CandidatesInSequenceStatsProps extends BaseProps {}
const calculatePercentage = (count: number, total: number): number =>
  total > 0 ? Math.round((count / total) * 100) : 0

const FilterButton = ({
  title,
  count,
  percentage,
  isFilterable
}: FilterButtonProps): JSX.Element => {
  const { setParam, getParam, resetParam } = useQueryParams()
  const candidateJobsFilter = getParam('candidateJobsFilter')
  const filterValue = getCandidateJobSequenceFilterFromDisplay(title)

  return (
    <DivAsButton
      ariaLabel={`Filter candidates by ${title}`}
      onClick={() => {
        if (filterValue === candidateJobsFilter) {
          resetParam('candidateJobsFilter')
        } else {
          setParam('candidateJobsFilter', filterValue ?? '')
        }
      }}
      isDisabled={!isFilterable || title.toLowerCase().includes('scheduled')}
    >
      <S.FilterButton
        $isActive={!!(isFilterable && getCandidateJobSequenceFilterFromDisplay(title) === candidateJobsFilter)}
        $isDisabled={!isFilterable || title.toLowerCase().includes('scheduled')}
      >
        <Caption size="XS">{title}</Caption>
        <Flex $align="center" $justify="space-between" $gap={12}>
          <Caption size="MD" $fontWeight={700}>
            {count}
          </Caption>
          {!isNil(percentage) && (
            <Caption size="SM" $fontWeight={400} $color="fgTertiary">
              {percentage}%
            </Caption>
          )}
        </Flex>
      </S.FilterButton>
    </DivAsButton>
  )
}

export const CandidatesInSequenceStats = ({ isFilterable = false }: CandidatesInSequenceStatsProps): JSX.Element => {
  const { isPending, data: candidateJobCounts } = useCandidateJobCountsQuery()

  const statsBlocks = useMemo(() => {
    if (
      !isNil(candidateJobCounts) &&
      Object.keys(candidateJobCounts.candidateOutreach).length > 0
    ) {
      const { replied, opened, scheduled, contacted, inSequence } =
        candidateJobCounts.candidateOutreach
      return [
        {
          title: candidateJobSequenceFilterDisplay[CandidateJobSequenceFilter.ACTIVE],
          count: inSequence
        },
        {
          title: candidateJobSequenceFilterDisplay[CandidateJobSequenceFilter.CONTACTED],
          count: contacted
        },
        {
          title: candidateJobSequenceFilterDisplay[CandidateJobSequenceFilter.OPENED],
          count: opened,
          percentage: calculatePercentage(opened, contacted)
        },
        {
          title: candidateJobSequenceFilterDisplay[CandidateJobSequenceFilter.REPLIED],
          count: replied,
          percentage: calculatePercentage(replied, contacted)
        },
        {
          title: candidateJobSequenceFilterDisplay[CandidateJobSequenceFilter.SCHEDULED],
          count: scheduled,
          percentage: calculatePercentage(scheduled, contacted)
        }
      ]
    }
    return []
  }, [candidateJobCounts])

  return (
    <When condition={!isPending && statsBlocks.length > 0}>
      <S.Wrapper>
        {statsBlocks.map((block) => (
          <FilterButton key={block.title} {...block} isFilterable={isFilterable} />
        ))}
      </S.Wrapper>
    </When>
  )
}
