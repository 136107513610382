import { CandidatesPageHeader } from '../candidates-page-header'
import type { CandidatesPageHeaderProps } from '../candidates-page-header'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { CANDIDATES_PAGES_MAX_WIDTH } from 'src/styles/constants'
import { useMemo } from 'react'
import type { ReactNode } from 'react'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import RouteBuilder from 'src/libs/route-builder'
import { useDeleteJobSearchRefinement } from 'src/hooks/mutations/use-delete-job-search-refinement'
import { cloneRefinementCriteriaAtom } from 'src/stores/jobRefinement'
import { useSetAtom } from 'jotai'
import { useNavigate } from 'react-router'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { usePrintView } from 'src/hooks/use-print-view'
import CONFIG from 'src/config'
import { useNotification } from 'src/hooks/use-notification'
import { Spinner } from 'src/components/primitives/spinner'
import { sleep } from 'src/libs/sleep'

interface SourcingPageHeaderProps extends Pick<CandidatesPageHeaderProps, 'isEditable' | 'onEdit' | 'headingLoading'> {
  title: string
  showActions?: boolean
  jobSearchRefinement?: JobSearchRefinement
  jobId?: string
  candidateJobIds: string[]
  isSequenceEmpty: boolean
  customActions?: ReactNode[]
  printUrl?: string
}

const onLoadIFrame = async (iframe: HTMLIFrameElement): Promise<void> => {
  const closePrint = (): void => {
    document.body.removeChild(iframe)
  }
  if (iframe.contentWindow) {
    iframe.contentWindow.onbeforeunload = closePrint
    iframe.contentWindow.onafterprint = closePrint
    await sleep(10000)
    iframe.contentWindow.print()
  }
}

export const SourcingPageHeader = ({
  headingLoading,
  title,
  showActions = true,
  jobId,
  jobSearchRefinement,
  printUrl,
  ...headerProps
}: SourcingPageHeaderProps): JSX.Element => {
  const { openDialog, openAlert } = useDialog()
  const { deleteSearchRefinement } = useDeleteJobSearchRefinement()
  const setCloneRefinementCriteria = useSetAtom(cloneRefinementCriteriaAtom)
  const isPrintView = usePrintView()
  const navigate = useNavigate()
  const { notify } = useNotification()

  const headerActions: CandidatesPageHeaderProps['actions'] = useMemo(() => ([
    {
      children: 'Add Candidate',
      icon: 'plus',
      // $variant: 'outline',
      $colorTheme: 'tint',
      onClick: () => {
        openDialog(DialogId.ADD_CANDIDATE)
      }
    }
  ]), [openDialog])

  const contextItems = useMemo(() => {
    const items: MenuItemProps[] = []
    if (jobSearchRefinement) {
      items.push({
        id: 'clone-ai-sourced',
        title: 'Clone Search',
        icon: Icons.copy,
        onSelect: () => {
          setCloneRefinementCriteria(jobSearchRefinement.searchCriteria ?? undefined)
          navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SEARCH', { jobId }))
        }
      }, {
        id: 'clone-ai-sourced-separator',
        title: 'Clone AI Sourced Separator',
        type: 'separator'
      })
    }
    if (printUrl) {
      items.push({
        id: 'print-search',
        title: 'Print Results',
        icon: Icons.printer,
        onSelect: async () => {
          notify({
            type: 'toast',
            variant: 'positive',
            position: 'bottom-right',
            iconNode: <Spinner />,
            message: 'Generating Print Results...',
            autoClose: true,
            closeDelay: 12000
          })
          const hideFrame = document.createElement('iframe')
          hideFrame.onload = async () => {
            await onLoadIFrame(hideFrame)
          }
          hideFrame.style.display = 'none' // hide iframe
          hideFrame.src = `${CONFIG.APP_DOMAIN}${printUrl}`
          document.body.appendChild(hideFrame)
        }
      })
    }
    items.push({
      id: 'position-details',
      title: 'Position Details',
      icon: Icons.settings2,
      href: RouteBuilder.build('SETTINGS_JOB_DETAILS', { jobId })
    })
    if (jobSearchRefinement) {
      items.push({
        id: 'delete-search',
        title: 'Delete Search',
        icon: Icons.trash,
        variant: 'negative',
        onSelect: () => {
          openAlert({
            message: 'Are you sure you want to delete this search?',
            description: 'All candidates within this search will be lost.',
            cancelText: 'Cancel',
            confirmText: 'Delete',
            onConfirm: () => {
              deleteSearchRefinement({ jobSearchRefinementId: jobSearchRefinement.id })
            }
          })
        }
      })
    }
    return items
  }, [deleteSearchRefinement, jobId, jobSearchRefinement, navigate, notify, openAlert, printUrl, setCloneRefinementCriteria])

  return (
    <div
      style={{
        maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
        width: '100%',
        pageBreakBefore: 'avoid',
        pageBreakAfter: 'avoid'
      }}
    >
      <CandidatesPageHeader
        heading={title}
        subHeading={jobSearchRefinement?.subtitle}
        headingLoading={headingLoading}
        actions={showActions ? headerActions : []}
        isPrintView={isPrintView}
        context={
          <Dropdown
            trigger={
              <Button
                nested
                ariaLabel="Options"
                $variant="ghost"
                $colorTheme="muted"
                $width={24}
                $height={24}
                $fontSize={12}
                leadingIcon="more-vertical"
              />
            }
            items={contextItems}
            menuPosition="end"
            size="small"
            $minWidth='150px'
            $menuWidth='auto'
          />
        }
        $padding={jobSearchRefinement ? { top: 10, bottom: 10 } : undefined}
        {...headerProps}
      />
    </div>
  )
}
