import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { Button } from 'src/components/primitives/button'
import { Flex } from 'src/components/primitives/flex'
import {
  CandidateEducationTimeline,
  CandidateExperienceTimeline,
  CandidateProfileInline,
  CandidateSummary,
  CandidateTimeline
} from 'src/components/blocks/candidate-details'
import RouteBuilder from 'src/libs/route-builder'
import * as S from './candidate-details-dialog.styled'
import { useCandidateJobQuery } from 'src/hooks/queries/use-candidate-job'
import { CandidateEmailsList } from 'src/components/blocks/candidate-emails-list'
import { DialogId } from 'src/contexts/dialogs'
import { Spacer } from 'src/components/primitives'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { useCandidateActivitiesQuery } from 'src/hooks/queries/use-candidate-activities'
import { CHANNEL_TYPE, EVENT_TYPE, getChannelName } from 'src/libs/api/backend/websockets'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useSession } from 'src/hooks/use-session'
import { useChannel } from 'ably/react'

interface CandidateDetailsDialogProps {
  candidateJobId: string
}

export const CandidateDetailsDialog = ({
  candidateJobId
}: CandidateDetailsDialogProps): JSX.Element => {
  const { data: candidateJob } = useCandidateJobQuery({ candidateJobId })
  const {
    data: candidateActivities
  } = useCandidateActivitiesQuery({
    candidateId: candidateJob?.candidate.id
  })
  const candidate = candidateJob?.candidate
  const { isDialogOpen, openDialog, controlDialog, closeDialog } = useDialog()
  const { org } = useSession()

  useChannel({ channelName: getChannelName(CHANNEL_TYPE.CANDIDATE_DETAILS) }, EVENT_TYPE.CANDIDATES_UPDATE, (message) => {
    const idsSet = new Set((message.data.ids as string[]) ?? [])
    if (candidate?.id && idsSet.has(candidate.id)) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateActivities, candidate.id]
      })
    }
  })

  if (!candidate) {
    return <></>
  }

  return (
    <Dialog.Root
      id="candidate-details"
      isOpen={isDialogOpen(DialogId.CANDIDATE_DETAILS)}
      onOpenChange={(value) => {
        controlDialog(DialogId.CANDIDATE_DETAILS, value)
      }}
      $position="right"
      $width="560px"
      $maxWidth="560px"
      $height="almostFull"
      $innerPadding={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <CompaniesPreferencesProvider>
        <Dialog.Portal>
          <S.DialogHeader>
            <Flex $gap={16} $align="center">
              {/* <ButtonGroup $variant="raised" $gap={0}>
                <Button
                  ariaLabel="Previous candidate"
                  leadingIcon="chevron-up-thin"
                  $height={24}
                  $width={24}
                  $colorTheme="muted"
                />
                <Button
                  ariaLabel="Next candidate"
                  leadingIcon="chevron-down-thin"
                  $height={24}
                  $width={24}
                  $colorTheme="muted"
                />
              </ButtonGroup> */}
              <Button
                leadingIcon="inbox"
                $variant="outline"
                $colorTheme="muted"
                $height={24}
                $fontSize={12}
                href={RouteBuilder.build('JOBS_INBOX_CANDIDATE_JOBS', {
                  jobId: candidateJob.jobId,
                  candidateJobId: candidateJob.id
                })}
                onClick={() => {
                  closeDialog(DialogId.CANDIDATE_DETAILS)
                }}
              >
                View on inbox
              </Button>
            </Flex>
            <Flex $gap={16} $align="center" $justify="flex-end">
              <Button
                leadingIcon="pen-line"
                $variant="ghost"
                $colorTheme="muted"
                $height={24}
                $fontSize={12}
                onClick={() => {
                  openDialog(DialogId.EDIT_CANDIDATE, candidateJob)
                }}
              >
                Edit candidate
              </Button>
              <Button
                nested
                ariaLabel="Close"
                leadingIcon="x"
                $variant="ghost"
                $colorTheme="muted"
                $width={24}
                $height={24}
                onClick={() => {
                  closeDialog(DialogId.CANDIDATE_DETAILS)
                }}
              />
            </Flex>
          </S.DialogHeader>
          <Dialog.Content>
            <S.DialogContent>
              <Flex $gap={16} $direction="column">
                <CandidateProfileInline org={org} candidateJob={candidateJob} avatarSize={40} includeSocialLinks={true} />
                <CandidateEmailsList candidateJob={candidateJob} />
                <CandidateSummary candidateJob={candidateJob} $padding={{ top: 0, right: 0, bottom: 0, left: 0 }} />
                <Spacer $size={2} />
                <CandidateExperienceTimeline
                  experiences={candidateJob.candidate.experiences}
                  $padding={{ left: 0, right: 0 }}
                />
                <CandidateEducationTimeline
                  educations={candidateJob.candidate.educations}
                  // isCollapsible
                  $padding={{ left: 0, right: 0 }}
                />
                <Spacer $size={4} />
                {candidateActivities && candidateActivities.length > 0 &&
                  <CandidateTimeline
                    candidate={candidateJob.candidate}
                    candidateActivites={candidateActivities}
                    $padding={{ left: 0, right: 0 }}
                    candidateJob={candidateJob}
                  />
                }
              </Flex>
            </S.DialogContent>
          </Dialog.Content>
        </Dialog.Portal>
      </CompaniesPreferencesProvider>
    </Dialog.Root>
  )
}
