import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { CreateProjectForm } from 'src/components/blocks/create-project-form'
import { useSession } from 'src/hooks/use-session'

interface CreateProjectDialogProps {
  departmentId?: string
}

export const CreateProjectDialog = ({ departmentId }: CreateProjectDialogProps): JSX.Element => {
  const { org } = useSession()
  console.log('departmentId', departmentId, org?.id)
  const { isDialogOpen, controlDialog } = useDialog()

  return (
    <Dialog.Root
      id={DialogId.CREATE_PROJECT}
      isOpen={isDialogOpen(DialogId.CREATE_PROJECT)}
      onOpenChange={(value) => {
        controlDialog(DialogId.CREATE_PROJECT, value)
      }}
      $width="480px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="New Project"
          onClose={() => {
            controlDialog(DialogId.CREATE_PROJECT, false)
          }}
        />
        <Dialog.Content>
          <CreateProjectForm preSelectedDepartmentId={departmentId} />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
