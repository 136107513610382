import { Button } from 'src/components/primitives/button'
import { useConnectedMergeIntegration } from 'src/hooks/use-connected-merge-integration'
import * as S from './candidate-found-in-ats-button.styled'
import type { CandidateExpanded } from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'
import { Tooltip } from 'src/components/primitives/tooltip'

interface CandidateFoundInAtsButtonProps {
  candidate: CandidateExpanded
}

export const CandidateFoundInAtsButton = ({ candidate }: CandidateFoundInAtsButtonProps): JSX.Element => {
  const connectedMergeIntegration = useConnectedMergeIntegration()

  if (isNil(candidate.mergeCandidateRemoteId)) return <></>

  return (
    <Tooltip
      position="top"
      disabled={isNil(connectedMergeIntegration?.appUrl)}
      trigger={
        <S.FoundInAtsButton>
          <Button
            href={connectedMergeIntegration?.appUrl ?? undefined}
            $variant="outline"
            $colorTheme="muted"
            $fontSize={12}
            $height={24}
            leadingIcon={<img src={connectedMergeIntegration?.square_image} width={16} height={16} />}
            disabled={isNil(connectedMergeIntegration?.appUrl)}
          >
            Found in ATS
          </Button>
        </S.FoundInAtsButton>
      }
    >
      Go to {connectedMergeIntegration?.name}
    </Tooltip>
  )
}
