import { z } from 'zod'
import Api from '..'
import type { EmailSequenceStep } from 'src/models/sequence'

export enum SequenceStepGenerationState {
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

export enum SequenceStepType {
  AUTOMATED_EMAIL = 'AUTOMATED_EMAIL',
  MANUAL_EMAIL = 'MANUAL_EMAIL',
  AUTOMATED_LINKEDIN_EMAIL = 'AUTOMATED_LINKEDIN_MESSAGE'
}

export const sequenceStepParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  subject: z.string().nullish(),
  body: z.string().nullable(),
  type: z.nativeEnum(SequenceStepType),
  position: z.number(),
  waitDays: z.number(),
  sendingUserId: z.string().uuid(),
  sendingEmailAccountId: z.string().uuid(),
  sendingEmailAlias: z.string().nullable(),
  sequenceId: z.string().uuid(),
  orgId: z.string().uuid(),
  deleted: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  generationState: z.nativeEnum(SequenceStepGenerationState).nullish()
})

export type SequenceStep = z.infer<typeof sequenceStepParser>

export const sequenceParser = z.object({
  id: z.string().uuid(),
  orgId: z.string().uuid(),
  jobId: z.string().uuid(),
  active: z.boolean()
})

export type Sequence = z.infer<typeof sequenceParser>

export enum SequenceReply {
  NEW_THREAD = 'NEW_THREAD',
  REPLY_TO_PREVIOUS_THREAD = 'REPLY_TO_PREVIOUS_THREAD'
}

const sequenceReplyType = z.nativeEnum(SequenceReply)

export type SequenceReplyType = z.infer<typeof sequenceReplyType>

const sequenceExpanded = sequenceParser.extend({
  sequenceSteps: z.array(sequenceStepParser)
})

export type SequenceExpanded = z.infer<typeof sequenceExpanded>

export async function fetchSequence (jobId: string): Promise<SequenceExpanded> {
  const { data } = await Api.get(`/jobs/${jobId}/sequence`, {
    include: ['steps']
  })
  return sequenceExpanded.parse(data)
}

export async function setSequenceActiveStatus (jobId: string, active: boolean): Promise<Sequence> {
  const { data } = await Api.put(`/jobs/${jobId}/sequence`, null, {
    active
  })
  return sequenceParser.parse(data)
}

const candidateEmailStatsParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  orgId: z.string().uuid(),
  candidateId: z.string().uuid(),
  candidateSequenceId: z.string().uuid().nullable(),
  sequenceId: z.string().uuid().nullable(),
  sequenceStepId: z.string().uuid().nullable(),
  position: z.number().int().nullable(),
  candidateSequenceStepId: z.string().uuid().nullable(),
  manualEmailMessageId: z.string().uuid().nullable(),
  ipAddress: z.string().nullable(),
  userAgent: z.string().nullable()
})

export const candidateEmailOpensParser = candidateEmailStatsParser.nullish()

export type CandidateEmailOpens = z.infer<typeof candidateEmailOpensParser>

export const candidateEmailLinkOpensParser = candidateEmailStatsParser.extend({
  link: z.string().nullable()
}).nullish()

export type CandidateEmailLinkOpens = z.infer<typeof candidateEmailLinkOpensParser>

export const manualEmailMessageParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  subject: z.string().nullable(),
  body: z.string(),
  candidateId: z.string().uuid(),
  sendingUserId: z.string().uuid(),
  sendingEmailAccountId: z.string().uuid(),
  opened: z.boolean(),
  openedAt: z.coerce.date().nullable(),
  openedCount: z.number(),
  cc: z.array(z.string()).nullable(),
  bcc: z.array(z.string()).nullable(),
  candidateEmailOpens: z.array(candidateEmailOpensParser).nullish(),
  candidateEmailLinkOpens: z.array(candidateEmailLinkOpensParser).nullish()
})

export type ManualEmailMessage = z.infer<typeof manualEmailMessageParser>

const sendManualEmailParser = z.object({
  subject: z.string().nullable(),
  body: z.string(),
  referenceEmailMessageId: z.string().uuid().optional(),
  candidateIds: z.array(z.string().uuid()),
  sendingUserId: z.string().uuid(),
  sendingEmailAccountId: z.string().uuid(),
  sendingEmailAlias: z.string().nullable(),
  cc: z.array(z.string().email()).optional(),
  bcc: z.array(z.string().email()).optional(),
  attachmentUploads: z.array(z.object({
    s3Key: z.string(),
    fileName: z.string()
  })).optional()
})

export type SendManualEmail = z.infer<typeof sendManualEmailParser>

interface SendManualEmailArgs {
  manualEmail: SendManualEmail
}

export async function sendManualEmail ({ manualEmail }: SendManualEmailArgs): Promise<ManualEmailMessage[]> {
  const { data } = await Api.post('/candidates/manual_email_message', null, manualEmail)
  const { manualEmailMessages } = z.object({
    manualEmailMessages: z.array(manualEmailMessageParser)
  }).parse(data)

  return manualEmailMessages
}

export interface SendTestEmailArgs {
  emails: string[]
  sequenceStep: EmailSequenceStep
}

export async function sendTestEmail ({ emails, sequenceStep }: SendTestEmailArgs): Promise<void> {
  const { status } = await Api.post(`/sequence_steps/${sequenceStep.id}/test_email`, null, { emails })

  if (status !== 200) {
    throw new Error('Error sending test email')
  }
}

interface BulkReassignSequenceSenderArgs {
  originalAccountId: string
  updatedAccountId: string
}

export async function bulkReassignSequenceSender ({ originalAccountId, updatedAccountId }: BulkReassignSequenceSenderArgs): Promise<any> {
  const { data, status } = await Api.post('/sequences/bulk_reassign_sequence_sender', null, {
    originalAccountId,
    updatedAccountId
  })
  if (status !== 200) {
    throw new Error('Error updating candidate')
  }
  return data
}
