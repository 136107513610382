import { Form, Input, Switch, Textarea } from 'src/components/forms'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { Spacer } from 'src/components/primitives'
import { SalaryRangePicker } from 'src/components/blocks/salary-range-picker'
import type { SalaryRangeArgs } from 'src/components/blocks/salary-range-picker'
import { useEffect, useMemo, useState } from 'react'
import { useDialog } from 'src/hooks/use-dialog'
import { sleep } from 'src/libs/sleep'
import { getSalaryInThousands } from 'src/utils/get-salary-in-thousands'
import type { Nullable } from 'src/utils/type-utils'
import { createNewPositionFormSchema } from '../create-job-form/types/create-new-position-form'
import type { CreateNewPositionForm } from '../create-job-form/types/create-new-position-form'
import { Select } from 'src/components/forms/select'
import type { SelectItem } from 'src/components/forms/select'
import { fetchSalaryRangePrediction } from 'src/libs/api/backend/salary_ranges'
import type { SalaryRangePrediction } from 'src/libs/api/backend/salary_ranges'
import { useSession } from 'src/hooks/use-session'
import { isNil } from 'lodash'
import { Combobox } from 'src/components/forms/combobox'
import { locations } from 'src/libs/data-location'
import { DialogId } from 'src/contexts/dialogs'
import { useGetDepartmentQuery } from 'src/hooks/queries/use-departments'
import { FormActionButtons } from '../form-action-buttons'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { DepartmentLogo } from '../department-logo'
import { Avatar } from 'src/components/primitives/avatar'
import { ConditionalFieldGroup } from 'src/components/forms/conditional-field-group'
import { Paragraph } from 'src/components/primitives/typography'
import { DeprecatedBanner } from './deprecated-banner'

const jobDetailsSchema = z
  .object({
    title: z.string(),
    departmentId: z.string(),
    // country: z.string().nullable().optional(),
    locations: z.array(z.string()).nullable().optional(),
    workspace: z.enum(['REMOTE', 'OFFICE', 'HYBRID']).nullable(),
    description: z.string().nullable().optional(),
    diversitySearch: z.boolean().nullish(),
    salaryMin: z.number(),
    salaryMax: z.number()
  })
  .refine((data) => data.salaryMax > data.salaryMin, {
    message: 'Max salary must be greater than min salary'
  })

export type JobDetailsProps = z.infer<typeof jobDetailsSchema>

interface JobDetailsFormProps {
  initialValues?: Partial<CreateNewPositionForm>
  jobData?: Nullable<Partial<CreateNewPositionForm>> | null
  onSubmit?: (data: CreateNewPositionForm) => void
  formIsSubmitting?: boolean
  submitButtonText?: string
  isCreating?: boolean
}

export const JobDetailsForm = ({
  onSubmit,
  formIsSubmitting,
  initialValues,
  jobData,
  submitButtonText = 'Create job position',
  isCreating = false
}: JobDetailsFormProps): JSX.Element => {
  const { openDialog } = useDialog()
  const [isSubmitting, setIsSubmitting] = useState(formIsSubmitting ?? false)
  const [salaryRangeData, setSalaryRangeData] = useState<SalaryRangeArgs>({
    defaultSelectedMin: 85,
    defaultSelectedMax: 120
  })
  const { org, featureFlags, orgLogoUrl } = useSession()
  const { departments } = useGetDepartmentQuery()

  const { submit, errors, register, setValue, setValues, formData } =
    useForm<CreateNewPositionForm>({
      schema: createNewPositionFormSchema,
      initialValues: {
        ...initialValues,
        departmentId: org?.id
      }
    })

  const fetchSalaryRange = async (jobTitle: string): Promise<void> => {
    if (!jobTitle) {
      return
    }

    let data: SalaryRangePrediction
    try {
      data = await fetchSalaryRangePrediction({ jobTitle })
    } catch (error) {
      console.error(error)
      return
    }

    setSalaryRangeData((prev) => ({
      ...prev,
      idealMin: data.idealMin,
      idealMax: data.idealMax,
      defaultSelectedMin: data.idealMin ?? 85,
      defaultSelectedMax: data.idealMax ?? 120
    }))
  }

  useEffect(() => {
    const setData = async (): Promise<void> => {
      if (jobData) {
        setValues({
          ...jobData,
          departmentId: jobData.departmentId ?? org?.id
        })
        const {
          title,
          salaryMin,
          salaryMax
        } = jobData
        if (title) {
          await fetchSalaryRange(title)
        }
        if (salaryMin && typeof salaryMin === 'number') {
          setSalaryRangeData((prev) => ({
            ...prev,
            defaultSelectedMin: getSalaryInThousands(salaryMin)
          }))
        }
        if (salaryMax && typeof salaryMax === 'number') {
          setSalaryRangeData((prev) => ({
            ...prev,
            defaultSelectedMax: getSalaryInThousands(salaryMax)
          }))
        }
      }
    }
    void setData()
  }, [jobData, org?.id])

  const handleSalaryChange = (values: number[]): void => {
    setValue('salaryMin', values[0])
    setValue('salaryMax', values[1])
  }

  const handleSubmit = async (formData: CreateNewPositionForm): Promise<void> => {
    setIsSubmitting(true)
    if (onSubmit) {
      await sleep(300)
      onSubmit(formData)
      setIsSubmitting(false)
    }
  }

  const departmentItems = useMemo((): SelectItem[] => {
    if (isNil(org)) {
      return []
    }

    const orgListItem = {
      value: org.id,
      title: `${org.name} • ${org.domain}`,
      image: <Avatar
        $type="logo"
        $size={16}
        $border={false}
        photoUrl={orgLogoUrl}
        initials={org.name}
      />
    }
    const deptsListItems = departments?.filter((department) => !department.deleted).map((dept) => ({
      value: dept.id,
      title: `${dept.name} • ${dept.domain}`,
      image: <DepartmentLogo $size={16} departmentId={dept.id} />
    })) ?? []

    return [orgListItem, ...deptsListItems]
  }, [org, departments, orgLogoUrl])

  return (
    <Form onSubmit={submit(handleSubmit)} errors={errors}>
      <Input
        name="title"
        placeholder="Eg: Software Engineer"
        label="Position title"
        onBlurEvent={(event) => {
          void fetchSalaryRange(event.target.value)
        }}
        register={register}
      />
      <Select
        name="departmentId"
        label="Hiring for"
        placeholder="Select an organization"
        defaultValue={formData.departmentId as string}
        items={departmentItems}
        $maxHeight={406}
        createItem={{
          value: 'new-company',
          title: 'New company',
          onClick: () => {
            openDialog(DialogId.CREATE_DEPARTMENT, {
              setDepartmentId: (deptId: string): void => {
                setValue('departmentId', deptId)
              }
            })
          }
        }}
        createItemIsSticky={departmentItems?.length >= 8}
        register={register}
      />
      {
        !isNil(jobData?.description) && jobData?.description !== '' && (
          <>
            <Textarea
              rows={4}
              label="Describe your ideal candidate"
              name="description"
              register={register}
              $marginBottom={12}
              autoGrow
            />
            <DeprecatedBanner>The candidate description field is deprecated. If you want to update your search results use the input on the candidate sourcing page.</DeprecatedBanner>
          </>
        )
      }
      {featureFlags?.includes(FeatureFlags.SALARY_PICKER)
        ? <>
            <Spacer $size={12} />
            <SalaryRangePicker
              onValueChange={(values) => {
                handleSalaryChange(values)
              }}
              {...salaryRangeData}
            />
          </>
        : null}
      <Spacer $size={12} />
      {
        !(Array.isArray(jobData?.locations) && jobData.locations.length === 0) && (
          <>
          <ConditionalFieldGroup
            label="Candidate location"
            description="Narrow down your search by location"
            tooltip="When not specified, Pin will search for anyone in the United States"
            defaultChecked={Boolean(jobData?.locations?.length)}
            onStatusChange={(isChecked) => {
              if (isChecked) {
                setValue('workspace', 'OFFICE')
              }
              if (!isChecked) {
                setValue('workspace', undefined)
                setValue('locations', [])
              }
            }}
            checkedFields={
              <>
                <Combobox
                  selectType="multi"
                  name="locations"
                  icon="map-pin"
                  label="Candidate locations (US only)"
                  hiddenLabel={true}
                  placeholder="Select a location"
                  defaultValue={formData.locations as string[]}
                  options={locations}
                  register={register}
                  $marginBottom={0}
                />
                <Spacer $size={8} />
                <Paragraph size="XS" $color="fgTertiary">United States only.</Paragraph>
              </>
            }
          />
          <DeprecatedBanner>The location field is deprecated. If you want to update your search results use the input on the candidate sourcing page.</DeprecatedBanner>
          <Spacer $size={16} />
          </>
        )
      }
      <Switch
        $variant="outline"
        label="Focus on diverse candidates"
        name="diversitySearch"
        defaultChecked={jobData?.diversitySearch ?? false}
        register={register}
        tooltip="Pin will prioritize recommending highly qualified, under-represented candidates."
      />
      <FormActionButtons
        disabled={!formData.title || (isCreating && !formData.description)}
        saveText={submitButtonText}
        isSaving={isSubmitting}
      />
    </Form>
  )
}
