import type { Row } from '@tanstack/react-table'
import { ColumnWidthType } from 'src/components/primitives/table'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'

export enum COLUMN {
  FAVORITE = 'favorite',
  NAME = 'name',
  STATUS = 'status',
  SOURCE = 'source',
  JOB_TITLE = 'jobTitle',
  CRITERIA = 'criteria',
  CRITERIA_EXPANDED = 'criteriaExpanded',
  CREATED_AT = 'createdAt',
  REJECTION_REASON = 'rejectionReason',
  LAST_CONTACT = 'lastContact',
  NEXT_CONTACT = 'nextContact',
  REJECTED_AT = 'rejectedAt',
  ERROR = 'error',
  ERROR_ACTIONS = 'errorActions',
  BLANK = 'blank',
  CANDIDATE_STAGE_ACTIONS = 'candidateStageActions'
}

export interface DefaultTableSchema {
  id?: string
  candidateJob?: CandidateJobExpanded
  favorite: React.ReactNode
  name: React.ReactNode
  source: React.ReactNode
  status: React.ReactNode
  jobTitle: React.ReactNode
  criteria: React.ReactNode
  criteriaExpanded: React.ReactNode
  rejectionReason: React.ReactNode
  lastContact: React.ReactNode
  nextContact: React.ReactNode
  createdAt: React.ReactNode
  rejectedAt: React.ReactNode
  error: React.ReactNode
  errorActions: React.ReactNode
  blank: React.ReactNode
  candidateStageActions: React.ReactNode
}

export const AllSizes: ColumnSizes = {
  favorite: { type: ColumnWidthType.FIXED, value: 32 },
  name: { type: ColumnWidthType.RATIO, value: 1.5 }
}

export type ColumnSizes = {
  [key in keyof DefaultTableSchema]?: {
    type: ColumnWidthType
    value: number
  }
}

type SortableCandidateJobDateFields = 'createdAt' | 'updatedAt'

export const sortByCandidateJobDateField = (field: SortableCandidateJobDateFields) => {
  return (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
    const dateA = rowA.original.candidateJob?.[field] ?? ''
    const dateB = rowB.original.candidateJob?.[field] ?? ''

    if (dateA < dateB) return -1
    if (dateA > dateB) return 1
    return 0
  }
}

export type SortableCandidateDateFields = 'lastCommunicatedAt'

export const sortByCandidateDateField = (field: SortableCandidateDateFields) => {
  return (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
    const nameA = rowA.original.candidateJob?.candidate?.[field] ?? ''
    const nameB = rowB.original.candidateJob?.candidate?.[field] ?? ''

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
}

export type SortableCandidateSequenceDateFields = 'nextSequenceStepDate'

export const sortByCandidateSequenceDateField = (field: SortableCandidateSequenceDateFields) => {
  return (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
    const dateA = rowA.original.candidateJob?.candidateSequence?.[field] ?? ''
    const dateB = rowB.original.candidateJob?.candidateSequence?.[field] ?? ''

    if (dateA < dateB) return -1
    if (dateA > dateB) return 1
    return 0
  }
}

export const columnSorting = {
  favorite: {
    sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
      const a = rowA.original.candidateJob?.favorite ?? false
      const b = rowB.original.candidateJob?.favorite ?? false

      if (a < b) return -1
      if (a > b) return 1
      return 0
    }
  },
  name: {
    sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
      const nameA = rowA.original.candidateJob?.candidate?.name?.toLowerCase() ?? ''
      const nameB = rowB.original.candidateJob?.candidate?.name?.toLowerCase() ?? ''

      if (nameA < nameB) return 1
      if (nameA > nameB) return -1
      return 0
    }
  },
  jobTitle: {
    sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
      const jobA = rowA.original.candidateJob?.candidate?.experiences?.[0]?.title?.toLowerCase() ?? ''
      const jobB = rowB.original.candidateJob?.candidate?.experiences?.[0]?.title?.toLowerCase() ?? ''

      if (jobA < jobB) return 1
      if (jobA > jobB) return -1
      return 0
    }
  },
  criteria: {
    sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
      const countTrueValues = (obj: Record<string, boolean> | undefined): number => {
        return Object.values(obj ?? {}).filter(Boolean).length
      }

      const scoreA = countTrueValues(rowA.original.candidateJob?.sourcingScores?.criteria_matches ?? undefined)
      const scoreB = countTrueValues(rowB.original.candidateJob?.sourcingScores?.criteria_matches ?? undefined)

      return scoreA - scoreB
    }
  },
  createdAt: {
    sortingFn: sortByCandidateJobDateField('createdAt')
  },
  lastContact: {
    sortingFn: sortByCandidateDateField('lastCommunicatedAt')
  },
  nextContact: {
    sortingFn: sortByCandidateSequenceDateField('nextSequenceStepDate')
  },
  rejectedAt: {
    sortingFn: sortByCandidateJobDateField('updatedAt')
  },
  rejectionReason: {
    sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
      const reasonA = rowA.original.candidateJob?.rejectionReason?.toLowerCase() ?? ''
      const reasonB = rowB.original.candidateJob?.rejectionReason?.toLowerCase() ?? ''

      if (reasonA < reasonB) return -1
      if (reasonA > reasonB) return 1
      return 0
    }
  },
  status: {
    sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
      const statusA =
        `${rowA.original.candidateJob?.statusDisplay?.title?.toLowerCase()}${rowA.original.candidateJob?.statusDisplay?.subtitle?.toLowerCase()}` ??
        ''
      const statusB =
        `${rowB.original.candidateJob?.statusDisplay?.title?.toLowerCase()}${rowB.original.candidateJob?.statusDisplay?.subtitle?.toLowerCase()}` ??
        ''

      if (statusA < statusB) return -1
      if (statusA > statusB) return 1
      return 0
    }
  },
  // TODO: The actual values that's displayed in the table are not the
  // same as what where sorting by, this will need to be fixed
  source: {
    sortingFn: (rowA: Row<DefaultTableSchema>, rowB: Row<DefaultTableSchema>) => {
      const sourceA =
        rowA.original.candidateJob?.source ??
        rowA.original.candidateJob?.creatorUserId ??
        ''
      const sourceB =
        rowB.original.candidateJob?.source ??
        rowB.original.candidateJob?.creatorUserId ??
        ''

      if (sourceA < sourceB) return -1
      if (sourceA > sourceB) return 1
      return 0
    }
  }
}
