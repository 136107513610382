import { z } from 'zod'
import Api from '..'

export const userParser = z.object({
  id: z.string().uuid(),
  name: z.string()
})

export type User = z.infer<typeof userParser>

export const emailAliasesParser = z.array(z.object({
  sendAsEmail: z.string(),
  displayName: z.string().nullish()
})).nullish().transform(val => val ?? [])

const emailAccountParser = z.object({
  type: z.enum(['google', 'microsoft']),
  email: z.string(),
  emailAliases: emailAliasesParser,
  hasAccessToken: z.boolean(),
  usedInJobIds: z.array(z.string()),
  currentUserHasSendAsPermission: z.boolean(),
  id: z.string(),
  userId: z.string(),
  isPrimary: z.boolean()
})

export type EmailAccount = z.infer<typeof emailAccountParser>

const orgUserParser = z.object({
  id: z.string(),
  active: z.boolean(),
  email: z.string(),
  name: z.string(),
  emailAccounts: z.array(emailAccountParser),
  profilePhotoUrl: z.string().nullable().optional()
})

export type OrgUser = z.infer<typeof orgUserParser>

export async function listOrgUsers (): Promise<OrgUser[]> {
  const res = await Api.get('/org/users')
  return z
    .array(orgUserParser)
    .parse(res.data)
}

export async function disableUser (userId: string, updatedAccountId?: string): Promise<void> {
  await Api.delete(`/org/users/${userId}`, { updatedAccountId })
}

export async function activateUser (userId: string): Promise<void> {
  await Api.post(`/org/users/${userId}/activate`)
}
