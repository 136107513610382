import { ChannelProvider } from 'ably/react'
import { useMemo } from 'react'
import { useSession } from 'src/hooks/use-session'
import { CHANNEL_TYPE } from 'src/libs/api/backend/websockets'

interface ChannelsProviderProps {
  children: React.ReactNode
}

export const ChannelsProvider = ({ children }: ChannelsProviderProps): JSX.Element => {
  const { org } = useSession()
  const deriveOptions = useMemo(() => ({
    filter: `'headers.orgId = \`"${org?.id ?? ''}"\`'`
  }), [org?.id])
  return (
    <ChannelProvider channelName={CHANNEL_TYPE.CANDIDATE_SEARCH} deriveOptions={deriveOptions}>
      <ChannelProvider channelName={CHANNEL_TYPE.NOTIFICATIONS} deriveOptions={deriveOptions}>
        <ChannelProvider channelName={CHANNEL_TYPE.CANDIDATE_DETAILS} deriveOptions={deriveOptions}>
          <ChannelProvider channelName={CHANNEL_TYPE.PROSPECTING_ERROR} deriveOptions={deriveOptions}>
            <ChannelProvider channelName={CHANNEL_TYPE.MANUAL_OUTREACH} deriveOptions={deriveOptions}>
              {children}
            </ChannelProvider>
          </ChannelProvider>
        </ChannelProvider>
      </ChannelProvider>
    </ChannelProvider>
  )
}
