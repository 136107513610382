import type { Color } from 'src/styles/theme/types'
import styled from 'styled-components'

export const ConnectedAccount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(0, 0, 0, 0.02);
`

// Move the ConnectedAccount padding and border properties to a top half and a bottom half
export const ConnectedAccountTopHalf = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px 6px 0 0;
  background: ${({ theme }) => theme.colors.bgPrimary};
  padding: 0.75rem 0.75rem 0.5rem 1rem;
  box-shadow: 0px 0.1px 0px 0px hsla(240, 5%, 65%, 1);
  gap: 0.5rem;
`

export const ConnectedAccountBottomHalf = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 6px 6px;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  gap: 0.5rem;
`

export const StatusIndicator = styled.div<{ $color: Color }>`
  margin-top: 1px;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 999px;
  background-color: ${({ theme, $color }) => theme.colors[$color]};
`

export const EmailAccountGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  row-gap: 0.75rem;
  column-gap: 1rem;
  align-items: center;
`

export const EmailAliasRow = styled.div`
  display: flex;
  flex-direction: row;
  grid-column: 2 / 3;
`

export const EmailAliasRowSeparator = styled.div`
  grid-column: 1 / -1;
  height: 1px;
  box-shadow: 0px 0.1px 0px 0px hsla(240, 5%, 65%, 1);
`
