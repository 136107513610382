import { useRef, useState } from 'react'
import { Avatar } from 'src/components/primitives/avatar'
import { Flex } from 'src/components/primitives/flex'
import { Text } from 'src/components/primitives/text'
import { Icon, Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { formatDate } from 'src/utils/format-date'
import {
  CandidateEmailLookupStage,
  CandidateJobStage,
  getCandidateJobSourceDisplay,
  getCandidateRejectionReasonDisplay,
  CandidateJobRejectionReason
} from 'src/libs/api/backend/candidate_jobs'
import type {
  CandidateJobExpanded,
  CandidateJobSource
} from 'src/libs/api/backend/candidate_jobs'
import { Button } from 'src/components/primitives/button'
import type { BadgeStyleProps } from 'src/components/primitives/badge'
import { isEmpty, isNil } from 'lodash'
import * as S from './candidate-table-cells.styled'
import type { User } from 'src/libs/api/backend/me'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { useTruncateText } from 'src/hooks/use-truncate-text'
import { CandidateJobStatusDropdown } from 'src/components/blocks/candidate-job-status'
import { useToggleCandidateFavoriteStatus } from 'src/hooks/mutations/use-toggle-candidate-favorite-status'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import type { Color } from 'src/styles/theme/types'
import { Caption } from 'src/components/primitives/typography'
import { DialogId } from 'src/contexts/dialogs'
import type { DialogContextProps } from 'src/contexts/dialogs'
import { isToday } from 'date-fns'
import { Tooltip } from 'src/components/primitives/tooltip'
import { CandidateMatchedCriteria } from 'src/components/blocks/candidate-details'
import { AvailableCandidateActions, CandidateActions } from 'src/components/blocks/candidate-actions'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import { useDialog } from 'src/hooks/use-dialog'
// import { pluralize } from 'src/libs/pluralize'

interface NameCellProps {
  name: string
  profilePhoto?: string | null
  company?: string
  companyWebsite?: string | null
  companyLogoUrl?: string | null
}

interface FavoriteHeaderProps {
  sorting: 'asc' | 'desc' | false
}

interface GetIconReturnType {
  icon: IconName
  color: Color
}

export const FavoriteHeader = ({ sorting = false }: FavoriteHeaderProps): JSX.Element => {
  const getIcon = (): GetIconReturnType => {
    switch (sorting) {
      case 'asc': {
        return {
          icon: 'star',
          color: 'fgSecondary'
        }
      }
      case 'desc': {
        return {
          icon: 'star-fill',
          color: 'warningBg'
        }
      }
    }
    return {
      icon: 'star-fill',
      color: 'fgTranslucent10'
    }
  }

  return (
    <S.FavoriteHeader>
      <Icon name={getIcon().icon} color={getIcon().color} />
    </S.FavoriteHeader>
  )
}

export const NameCell = ({
  name,
  profilePhoto,
  company,
  companyWebsite,
  companyLogoUrl
}: NameCellProps): JSX.Element => {
  const cellRef = useRef<HTMLDivElement>(null)
  const truncatedCompany = useTruncateText({
    ref: cellRef,
    text: company
  })

  return (
    <Flex $gap={8} $align="center" ref={cellRef}>
      <Avatar
        key={profilePhoto ?? name}
        $size={36}
        $shape="soft"
        $type="photo"
        initials={name}
        photoUrl={profilePhoto}
        company={{ name: company, url: companyWebsite, logoUrl: companyLogoUrl }}
      />
      <Flex $direction="column">
        <Text $size={14} $weight={500} $lineHeight={1.43}>
          {name}
        </Text>
        <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary">
          {truncatedCompany}
        </Text>
      </Flex>
    </Flex>
  )
}

export const CandidateNameCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  const latestExperience = candidateJob.candidate.experiences?.[0]

  return (
    <NameCell
      key={candidateJob.candidate.id}
      name={candidateJob.candidate.name}
      profilePhoto={candidateJob.candidate.profilePhotoUrl}
      company={latestExperience?.company}
      companyWebsite={latestExperience?.companyInformation?.website}
      companyLogoUrl={latestExperience?.logoUrl}
    />
  )
}

interface FavoriteCellProps {
  candidateJob: CandidateJobExpanded
}

export const FavoriteCell = ({ candidateJob }: FavoriteCellProps): JSX.Element => {
  const [isFav, setIsFav] = useState(candidateJob?.favorite ?? false)
  const { toggleFavoriteStatus } = useToggleCandidateFavoriteStatus()

  return (
    <Flex $align="center" $justify="center">
      <Button
        leadingIcon={isFav ? 'star-fill' : 'star'}
        ariaLabel={`Toggle favorite status for candidate ${candidateJob.candidate.name}`}
        $variant="ghost"
        $colorTheme={isFav ? 'warning' : 'muted'}
        $height={24}
        $width={24}
        onClick={() => {
          setIsFav(!isFav)
          toggleFavoriteStatus({ candidateJobId: candidateJob.id, newFavoriteStatus: !isFav })
        }}
        tooltip={{
          text: isFav ? 'Remove from shortlist' : 'Add to shortlist'
        }}
      />
    </Flex>
  )
}

interface CompanyCellProps {
  company: string
  position: string
  startDate?: Date | null
  endDate?: Date | null
  website?: string | null
  logoUrl?: string | null
}

// function formatStartEndDate (
//   startDate: Date | undefined | null,
//   endDate: Date | undefined | null
// ): string {
//   const formattedStart = startDate && formatDate(startDate, { format: 'Year' })
//   const formattedEnd = !endDate ? 'Present' : formatDate(endDate, { format: 'Year' })
//   return `${formattedStart} – ${formattedEnd}`
// }

export const CompanyPositionCell = ({
  company,
  position,
  // startDate,
  // endDate,
  website,
  logoUrl
}: CompanyCellProps): JSX.Element => {
  return (
    <Flex $gap={8} $align="center">
      <Avatar
        $size={36}
        $shape="soft"
        $type="logo"
        initials={company}
        company={{ name: company, url: website, logoUrl }}
      />
      <Flex $direction="column">
        <Text $size={12} $weight={500} $lineHeight={1.43}>
          {company}
        </Text>
        <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary">
          {position}
        </Text>
        {/* <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary">
          {formatStartEndDate(startDate, endDate)}
        </Text> */}
      </Flex>
    </Flex>
  )
}

export const CandidateCompanyPositionCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  const latestExperience = candidateJob.candidate.experiences?.[0]

  return (
    <CompanyPositionCell
      company={latestExperience?.company || ''}
      position={latestExperience?.title || ''}
      startDate={latestExperience?.startDate}
      endDate={latestExperience?.endDate}
      website={latestExperience?.companyInformation?.website}
      logoUrl={latestExperience?.logoUrl}
    />
  )
}

interface DateCellProps {
  date?: Date | null
}

export const DateCell = ({ date }: DateCellProps): JSX.Element => {
  if (!date) {
    return (
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>
        &mdash;
      </Text>
    )
  }

  return (
    <Flex $direction="column">
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>
        {formatDate(date, { format: 'DaysPassed' })}
      </Text>
    </Flex>
  )
}

export const CandidateCreatedDateCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  return <DateCell date={candidateJob.createdAt} />
}

export const CandidateRejectedDateCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  return <DateCell date={candidateJob.updatedAt} />
}

export const CandidateLastContactDateCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  return (
    <Flex $align="center" $gap={6}>
      <OpenedIcon candidateJob={candidateJob} />
      <DateCell
        date={
          candidateJob.candidate.lastCommunicatedAt ?? candidateJob.candidateSequence?.latestStepAt
        }
      />
    </Flex>
  )
}

export const OpenedIcon = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element | null => {
  if (isNil(candidateJob.candidateSequence)) {
    return null
  }

  if (isNil(candidateJob.candidate.lastCommunicatedAt ?? candidateJob.candidateSequence?.latestStepAt)) {
    return null
  }

  if (candidateJob.candidateSequence.bounced) {
    return (
      <Icon
        name="mail-x"
        size={12}
        color="fgNegative"
      />
    )
  }

  if (!candidateJob.candidateSequence.opened) {
    return (
      <Icon
        name="mail-arrow-right"
        size={12}
        color="fgTertiary"
      />
    )
  }

  const text = ['Opened']

  // if (candidateJob.candidateSequence.openedCount) {
  //   text.push(`${pluralize(candidateJob.candidateSequence.openedCount, 'time')}`)
  // }

  if (candidateJob.candidateSequence.openedAt) {
    text.push(`${formatDate(candidateJob.candidateSequence.openedAt, { format: 'TimePassed' })}`)
  }

  return (
    <Tooltip
      position='top'
      delay={0}
      trigger={
        <span>
          <Icon
            name="mail-open"
            size={12}
            color='tintBg'
          />
        </span>
      }
    >
      {text.join(' ')}
    </Tooltip>
  )
}

export const CandidateNextContactDateCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  const { data: sequence } = useJobSequenceQuery(candidateJob.jobId)

  if (sequence?.active === false) {
    return (
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>
        Paused
      </Text>
    )
  }

  if (
    candidateJob.candidateSequence?.isNextSequenceStepManual &&
    candidateJob.candidateSequence?.nextSequenceStepDate &&
    isToday(candidateJob.candidateSequence?.nextSequenceStepDate)
  ) {
    return (
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>
        In Ready To Send
      </Text>
    )
  }

  return (
    <Flex $align="center" $gap={4}>
      <DateCell date={candidateJob.candidateSequence?.nextSequenceStepDate} />
    </Flex>
  )
}

interface RejectionReasonCellProps {
  candidateJob: CandidateJobExpanded
}

export const RejectionReasonCell = ({ candidateJob }: RejectionReasonCellProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()

  const getReason = (reason?: CandidateJobRejectionReason | null): string => {
    return getCandidateRejectionReasonDisplay(reason)
  }

  const getUser = (userId: User['id'] | null | undefined): string => {
    if (!userId) {
      return 'Auto-archived'
    }

    return orgUsers?.find((orgUser) => orgUser.id === userId)?.name ?? 'Unknown'
  }

  return (
    <Flex $align="flex-start" $gap={2} $direction="column">
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>
        {getReason(candidateJob.rejectionReason)}
      </Text>
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgTertiary" $letterSpacing={0.12}>
        {getUser(candidateJob.rejectedByUserId)}
      </Text>
    </Flex>
  )
}

export const CandidateRejectionReasonCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  if (candidateJob.stage !== CandidateJobStage.REJECTED) {
    return <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>&mdash;</Text>
  }

  return <RejectionReasonCell candidateJob={candidateJob} />
}

interface StageCellProps {
  stage: CandidateJobStage
}

interface StageAttributesReturn {
  icon: IconName
  title: string
  variant: BadgeStyleProps['$variant']
}

export const StageCell = ({ stage }: StageCellProps): JSX.Element => {
  const getStageAttributes = (): StageAttributesReturn => {
    switch (stage) {
      case CandidateJobStage.SOURCED: {
        return {
          icon: 'sparkles' as IconName,
          title: 'Sourced',
          variant: 'tintLight'
        }
      }
      case CandidateJobStage.PROSPECTING: {
        return {
          icon: 'mail-check' as IconName,
          title: 'In outreach',
          variant: 'positiveLight'
        }
      }
      case CandidateJobStage.REJECTED: {
        return {
          icon: 'x-ocaton' as IconName,
          title: 'Archived',
          variant: 'negativeLight'
        }
      }
      default: {
        return {
          icon: 'sparkles' as IconName,
          title: 'Sourced',
          variant: 'tintLight'
        }
      }
    }
  }
  return (
    <Flex>
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>
        {getStageAttributes().title}
      </Text>
    </Flex>
  )
}

export const CandidateStatusCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  return (
    <S.CandidateStatusCell>
      <CandidateJobStatusDropdown candidateJob={candidateJob} />
    </S.CandidateStatusCell>
  )
}

export const CandidateErrorStatusCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element | null => {
  const { statusDisplay } = candidateJob
  if (isEmpty(statusDisplay)) {
    return null
  }
  return (
    <S.CandidateStatusCell>
      <Caption $color='negativeFg' size='XS'>{statusDisplay.title}</Caption>
    </S.CandidateStatusCell>
  )
}

export const CandidateErrorActionCell = ({
  candidateJob,
  openDialog,
  handlePauseSequence,
  rejectCandidates
}: {
  candidateJob: CandidateJobExpanded
  openDialog: DialogContextProps['openDialog']
  handlePauseSequence: (candidateJobIds: string[], isPaused: boolean) => void
  rejectCandidates: (candidateJobIds: string[], reason: CandidateJobRejectionReason) => void
}): JSX.Element | null => {
  const { candidateSequence, candidate } = candidateJob
  const isBounced = candidateSequence?.bounced ?? false
  let actionButton = null

  let rejectionReason = CandidateJobRejectionReason.OTHER

  if (isBounced || candidate.emailLookupStage === CandidateEmailLookupStage.NOT_FOUND) {
    rejectionReason = CandidateJobRejectionReason.MISSING_EMAIL
    actionButton = (
      <Button
        leadingIcon={Icons.penLine}
        $variant="raised-light"
        $colorTheme="tint"
        $height={24}
        $fontSize={12}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          openDialog(DialogId.EDIT_CANDIDATE, candidateJob)
        }}
        $width={142}
        $align='center'
      >
        Edit candidate
      </Button>
    )
  }
  if (candidateSequence?.paused) {
    actionButton = (
      <Button
        leadingIcon={Icons.playCircle}
        $variant='raised-light'
        $colorTheme='tint'
        $fontSize={12}
        $height={24}
        $width={142}
        $align='center'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          handlePauseSequence([candidateJob.id], false)
        }}
      >
        Resume outreach
      </Button>
    )
  }
  return (
    <S.CandidateStatusCell style={{ width: '100%' }}>
      <Flex $align='center' $justify='flex-end' $gap={8}>
        {actionButton}
        <Button
          leadingIcon={Icons.archive}
          $variant='outline'
          $colorTheme='muted'
          $height={24}
          $width={24}
          $fontSize={12}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            rejectCandidates([candidateJob.id], rejectionReason)
          }}
        />
      </Flex>
    </S.CandidateStatusCell>
  )
}

interface SourceCellProps {
  source?: CandidateJobSource | null
  userId?: User['id'] | null
}

export const SourceCell = ({ source, userId }: SourceCellProps): JSX.Element => {
  const { data: orgUsers } = useOrgUsersQuery()
  const orgUser = orgUsers?.find((orgUser) => orgUser.id === userId)

  if (isNil(source)) {
    return (
      <Flex $align="center" $gap={4}>
        <Avatar
          $size={16}
          $shape="circle"
          $type="photo"
          photoUrl={orgUser?.profilePhotoUrl}
          initials={orgUser?.name}
        />
        <Text
          $size={12}
          $weight={400}
          $lineHeight={1.43}
          $color="fgSecondary"
          $letterSpacing={0.12}
        >
          {orgUser?.name ?? ''}
        </Text>
      </Flex>
    )
  }

  return (
    <Flex $align="center" $gap={4}>
      <Icon name="pin-normal" size={12} color="fgPrimary" />
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>
        {getCandidateJobSourceDisplay(source)}
      </Text>
    </Flex>
  )
}

export const CandidateSourceCell = ({
  candidateJob
}: {
  candidateJob: CandidateJobExpanded
}): JSX.Element => {
  return <SourceCell source={candidateJob.source} userId={candidateJob.creatorUserId} />
}

export const CandidateJobTitleCell = ({ candidateJob }: { candidateJob: CandidateJobExpanded }): JSX.Element => {
  return (
    <Flex $align="center" $gap={4}>
      <Text $size={12} $weight={400} $lineHeight={1.43} $color="fgSecondary" $letterSpacing={0.12}>
        {candidateJob.candidate.experiences?.[0]?.title}
      </Text>
    </Flex>
  )
}

export const CandidateCriteriaMatchCell = ({ candidateJob }: { candidateJob: CandidateJobExpanded }): JSX.Element => {
  return (
    <Flex $align="center" $gap={4}>
      <CandidateMatchedCriteria sourcingScores={candidateJob.sourcingScores} />
    </Flex>
  )
}

const CandidiateCriteriaGhostItem = (): JSX.Element => {
  return (
    <Button
      $variant='ghost'
      $colorTheme='muted'
      $height={24}
      $width={24}
      $fontSize={12}
      disabled
    />
  )
}

export const CandidateCriteriaMatchDetailsCell = ({ candidateJob }: { candidateJob: CandidateJobExpanded }): JSX.Element => {
  return (
    <S.CandidateCriteriaMatchDetailsCell>
      <S.CandidateCriteriaMatchDetailsCellInner>
        {candidateJob.sourcingScores?.criteria_matches_array?.map((score) => {
          return (
            <Button
              $variant='flat'
              $colorTheme={score.matched ? 'positive' : 'negative'}
              $height={24}
              $width={24}
              $fontSize={12}
              leadingIcon={score.matched ? Icons.check : Icons.x}
              tooltip={{
                text: score.description
              }}
            />
          )
        })}
        <CandidiateCriteriaGhostItem />
      </S.CandidateCriteriaMatchDetailsCellInner>
    </S.CandidateCriteriaMatchDetailsCell>
  )
}

export const CandidateStageActionsCell = ({ candidateJob }: { candidateJob: CandidateJobExpanded }): JSX.Element => {
  const { openDialog } = useDialog()
  const { addCandidateToSequence } = useAddCandidateToSequence()
  const { data: sequence } = useJobSequenceQuery()

  return (
    <S.CandidateStageActionsCell>
      <CandidateActions
        candidateJob={candidateJob}
        onAddToSequenceClick={() => {
          if (isSequenceStepsEmpty(sequence)) {
            openDialog(DialogId.CREATE_SEQUENCE)
          } else {
            addCandidateToSequence([candidateJob.id])
          }
        }}
        actions={[AvailableCandidateActions.ADD_TO_SEQUENCE, AvailableCandidateActions.REJECT]}
        rejectActionDisplayVariant='compact'
      />
    </S.CandidateStageActionsCell>
  )
}
