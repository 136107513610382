import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isNil } from 'lodash'
import { useNotification } from 'src/hooks/use-notification'
import { createProject } from 'src/libs/api/backend/projects'
import type { Project } from 'src/libs/api/backend/projects'
import { queryKeys } from 'src/libs/query-keys'

interface Args {
  name: string
  departmentId?: string | null
  onSuccess?: (newProject: Project) => void
  onError?: () => void
}

interface Res {
  createNewProject: ({ name, departmentId, onSuccess, onError }: Args) => void
}

export const useCreateProject = (): Res => {
  const queryClient = useQueryClient()
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ name, departmentId }: Args) => await createProject({ departmentId, name }),
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred while creating your project: ${err.message}`
      })

      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (createdProject: Project, variables) => {
      let isNewProject = true
      queryClient.setQueryData<Project[]>([queryKeys.projects], (oldProjects) => {
        if (isNil(oldProjects)) {
          return oldProjects
        }

        const updatedProjects = oldProjects.map((project) => {
          if (project.id === createdProject.id) {
            isNewProject = false
            return project
          }
          return project
        })
        const results = updatedProjects
        if (isNewProject) {
          results.push(createdProject)
        }

        return results.sort((a, b) => a.deleted === b.deleted ? 0 : a.deleted ? 1 : -1)
      })

      notify({
        type: 'toast',
        variant: 'positive',
        position: 'bottom-right',
        icon: 'check-check',
        message: isNewProject ? 'Successfully created project' : 'Successfully updated project'
      })
      if (variables.onSuccess) {
        variables.onSuccess(createdProject)
      }
    }
  })

  const createNewProject = (args: Args): void => {
    mutation.mutate(args)
  }

  return { createNewProject }
}
