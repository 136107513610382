import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import type { Project } from 'src/libs/api/backend/projects'
import { useForm } from 'src/hooks/use-form'
import { z } from 'zod'
import { Form, Input } from 'src/components/forms'
import { FormActionButtons } from 'src/components/blocks/form-action-buttons'
import { useState } from 'react'
import { useUpdateProject } from 'src/hooks/mutations/use-update-project'

interface EditProjectDialogProps {
  project?: Project
}

const editProjectFormSchema = z.object({
  name: z.string(),
  departmentId: z.string().nullish()
})

export type EditProjectFormSchema = z.infer<typeof editProjectFormSchema>

export const EditProjectDialog = ({ project }: EditProjectDialogProps): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isDialogOpen, controlDialog, closeDialog } = useDialog()
  const { updateProject } = useUpdateProject()

  const { submit, register } = useForm<EditProjectFormSchema>({
    schema: editProjectFormSchema,
    initialValues: {
      name: project?.name,
      departmentId: project?.departmentId
    }
  })

  const handleSubmit = async (formData: EditProjectFormSchema): Promise<void> => {
    if (!project) { return }
    setIsSubmitting(true)
    updateProject({
      id: project?.id,
      name: formData.name,
      onSuccess: () => {
        setIsSubmitting(false)
        closeDialog(DialogId.EDIT_PROJECT)
      }
    })
  }

  return (
    <Dialog.Root
      id={DialogId.EDIT_PROJECT}
      isOpen={isDialogOpen(DialogId.EDIT_PROJECT)}
      onOpenChange={(value) => {
        controlDialog(DialogId.EDIT_PROJECT, value)
      }}
      $width="480px"
    >
      <Dialog.Portal>
        <Dialog.Header
          title="Edit Project"
          onClose={() => {
            controlDialog(DialogId.EDIT_PROJECT, false)
          }}
        />
        <Dialog.Content>
        <Form onSubmit={submit(handleSubmit)}>
          <Input name="name" placeholder="" label="Project name" register={register} />
          <FormActionButtons
            saveText="Save changes"
            isSaving={isSubmitting}
            cancelText="Cancel"
            onCancel={() => {
              closeDialog(DialogId.EDIT_PROJECT)
            }}
          />
        </Form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
