import { SEO } from 'src/components/primitives/seo'
import * as S from './outreach-errors.styled'
import { Caption } from 'src/components/primitives/typography'
import { CandidatesTable } from 'src/components/tables/candidates-table'
import { EmptyState } from 'src/components/blocks/empty-state'
import { ExportToAtsButton, RejectButton, WriteEmailButton } from 'src/components/tables/candidate-table-actions'
import { useEffect, useState } from 'react'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { COLUMN } from 'src/components/tables/candidates-table/table-builder'
import { useNavigate, useParams } from 'react-router'
import RouteBuilder from 'src/libs/route-builder'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { Spacer } from 'src/components/primitives/spacer'
import { useChannel } from 'ably/react'
import { CHANNEL_TYPE, getChannelName } from 'src/libs/api/backend/websockets'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'

const DEFAULT_TITLE = 'Outreach · Error'

const Header = (): JSX.Element => {
  return (
    <S.OutreachErrorsHeader>
      <Caption as='h1' size='MD' $whiteSpace='nowrap'>
        {DEFAULT_TITLE}
      </Caption>
    </S.OutreachErrorsHeader>
  )
}

const OutreachErrorsPage = (): JSX.Element => {
  const navigate = useNavigate()
  const { jobId } = useParams()
  const { data: job } = useJobQuery()
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    errored: true
  })

  useChannel({ channelName: getChannelName(CHANNEL_TYPE.PROSPECTING_ERROR) }, (message) => {
    const { name: eventName } = message
    if (eventName === jobId) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobs, jobId, { errored: true }]
      })
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
    }
  })

  useEffect(() => {
    if (!isPending && candidateJobs?.length === 0) {
      navigate(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }))
    }
  }, [candidateJobs?.length, isPending, jobId, navigate])
  return (
    <S.OutreachErrorsPageInner>
      <SEO title={DEFAULT_TITLE} />
      <S.OutreachErrorsPageWrapper>
        <S.OutreachErrorsPage>
          <Header />
          <Spacer $size={4} />
          <CandidatesTable
            visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.ERROR, COLUMN.ERROR_ACTIONS]}
            isLoading={isPending}
            candidateJobs={candidateJobs}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
            emptyState={
              <EmptyState
                heading="No candidates"
                description="Looks like you don’t have any prospects yet, start sourcing candidates and any errors will show up right here."
                svg="userCards"
              />
            }
            selectedRowsActions={
              <>
                <RejectButton
                  selectedRows={rowSelection}
                  onResetSelectedRows={() => {
                    setRowSelection({})
                  }}
                />
                <WriteEmailButton selectedRows={rowSelection} candidateJobs={candidateJobs} />
                <ExportToAtsButton selectedRows={rowSelection} candidateJobs={candidateJobs} job={job} />
              </>
            }
          />
        </S.OutreachErrorsPage>
      </S.OutreachErrorsPageWrapper>
    </S.OutreachErrorsPageInner>
  )
}

export default OutreachErrorsPage
