import { useVirtualizer } from '@tanstack/react-virtual'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { CandidateActions } from 'src/components/blocks/candidate-actions'
import { CandidateDetailsCard } from 'src/components/blocks/candidate-details-card'
import { EmptyState } from 'src/components/blocks/empty-state'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { SourcingPageHeader } from 'src/components/blocks/sourcing'
import { ToggleCandidateView } from 'src/components/blocks/toggle-candidate-view'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { DialogId } from 'src/contexts/dialogs'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useDialog } from 'src/hooks/use-dialog'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import RouteBuilder from 'src/libs/route-builder'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { CandidatesSourcedTable } from 'src/components/tables/candidates-sourced-table'
import { CHANNEL_TYPE, EVENT_TYPE, getChannelName } from 'src/libs/api/backend/websockets'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { useChannel } from 'ably/react'
import { COLUMN } from 'src/components/tables/candidates-table/table-builder'
import { usePrintView } from 'src/hooks/use-print-view'
import { Logo } from 'src/components/primitives/logo'
import { IfElse } from 'src/components/blocks/if-else'
import { SEO } from 'src/components/primitives/seo'

const JobCandidatesShortlistedPage = (): JSX.Element => {
  const { openDialog } = useDialog()
  const { jobId } = useParams()
  const [currViewMode] = useLocalStorage(LocalStorageKey.VIEW_MODE, ViewMode.DEFAULT)
  const isPrintView = usePrintView()
  const { height } = useWindowSize()

  const [renderedCandidates, setRenderedCandidates] = useState<CandidateJobExpanded[]>([])

  const { data: job } = useJobQuery()
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    stage: CandidateJobStage.SOURCED,
    source: undefined,
    favorite: true
  })

  useChannel({ channelName: getChannelName(CHANNEL_TYPE.CANDIDATE_DETAILS) }, EVENT_TYPE.CANDIDATES_UPDATE, (message) => {
    const idsSet = new Set((message.data.ids as string[]) ?? [])
    renderedCandidates.forEach((c) => {
      if (idsSet.has(c.candidateId)) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateActivities, c.candidateId]
        })
      }
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobs, jobId, {
        stage: CandidateJobStage.SOURCED,
        source: undefined,
        favorite: true
      }]
    })
  })

  const { isPending: isSequenceLoading, data: sequence } = useJobSequenceQuery()
  const { addCandidateToSequence } = useAddCandidateToSequence()

  const parentRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: renderedCandidates?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 300
  })

  useEffect(() => {
    if (candidateJobs) {
      const sourcedCandidates = candidateJobs?.filter((c) => c.stage === 'SOURCED')
      setRenderedCandidates(sourcedCandidates)
    }
  }, [candidateJobs])

  const candidatesPageHeader = useMemo(
    (): JSX.Element => {
      return (
        <>
          {isPrintView && <Logo variant="dark" size={64} />}
          <SourcingPageHeader
            title='Shortlisted'
            jobId={jobId}
            candidateJobIds={renderedCandidates?.map((c) => c.id) ?? []}
            isSequenceEmpty={isSequenceStepsEmpty(sequence)}
            customActions={!job?.deleted ? [<ToggleCandidateView />] : []}
            printUrl={RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SHORTLISTED', { jobId }, { print: true })}
          />
        </>
      )
    },
    [isPrintView, job?.deleted, jobId, renderedCandidates, sequence]
  )

  const emptyState = useMemo(() => {
    if (renderedCandidates?.length) {
      return null
    }

    if (job?.deleted) {
      return (
        <EmptyStateArchivedJob />
      )
    }

    return (
      <EmptyState
        heading="Shortlisted"
        description="Shortlist candidates from Sourcing to create a list of favorite candidates to share with teammates."
        svg="shortlistedCandidates"
        $height="100%"
        actions={[
          {
            href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
            children: 'Go to sourcing'
          }
        ]}
      />
    )
  }, [jobId, renderedCandidates?.length, job?.deleted])

  if (isPending || isSequenceLoading || !job) {
    return (
      <div
        style={{
          padding: `0 ${CONTENT_PADDING}`
        }}
      >
        {candidatesPageHeader}
        <LoadingSkeleton $variant="CandidateDetailsCard" />
      </div>
    )
  }

  return (
    <>
      <SEO title="Shortlisted" />
      <CompaniesPreferencesProvider>
        <IfElse
          condition={isPrintView}
          ifNode={
            <div
              ref={parentRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
                overflow: 'auto'
              }}
            >
              {candidatesPageHeader}
              {renderedCandidates?.map((candidateJob, index) => (
                <div
                  style={{
                    width: '100%',
                    maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                    breakBefore: index === 0 ? 'avoid-page' : 'page',
                    breakInside: 'avoid',
                    breakAfter: 'avoid-page'
                  }}
                >
                  <CandidateDetailsCard
                    isPrintView={isPrintView}
                    stage={candidateJob.stage}
                    candidate={candidateJob.candidate}
                    candidateJob={candidateJob}
                    viewMode={ViewMode.DEFAULT}
                  />
                </div>
              ))}
            </div>
          }
          elseNode={
            <IfElse
              condition={currViewMode === ViewMode.TABLE && (renderedCandidates?.length ?? 0) > 0}
              ifNode={
                <div style={{ padding: `0 ${CONTENT_PADDING}`, height: '100%' }}>
                  {candidatesPageHeader}
                  <CandidatesTablePagesContentInner
                    data-component="CandidatesTablePagesContentInner"
                    $padding={0}
                    $maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
                    $maxHeight={`${(height) - 64}px`}
                  >
                    <CandidatesSourcedTable
                      candidateJobs={renderedCandidates ?? []}
                      isLoading={isPending}
                      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.JOB_TITLE, COLUMN.CANDIDATE_STAGE_ACTIONS]}
                      pageHeaderHeight={64}
                    />
                  </CandidatesTablePagesContentInner>
                </div>
              }
              elseNode={
                <div
                  ref={parentRef}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    contain: 'strict',
                    padding: `0 ${CONTENT_PADDING}`,
                    scrollBehavior: 'smooth'
                  }}
                >
                  {candidatesPageHeader}
                  <div
                    style={{
                      height: virtualizer.getTotalSize(),
                      position: 'relative'
                    }}
                  >
                    {virtualizer.getVirtualItems().map((virtualRow) => {
                      const candidateJob = renderedCandidates?.[virtualRow.index]
                      if (!candidateJob) {
                        return <></>
                      }
                      return (
                        <div
                          style={{
                            width: '100%',
                            maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                            position: 'absolute',
                            top: `${virtualRow.start}px`
                          }}
                          key={virtualRow.key}
                          data-index={virtualRow.index}
                          ref={virtualizer.measureElement}
                        >
                          <CandidateDetailsCard
                            key={candidateJob.id}
                            stage={candidateJob.stage}
                            candidate={candidateJob.candidate}
                            candidateJob={candidateJob}
                            actions={
                              <CandidateActions
                                candidateJob={candidateJob}
                                onAddToSequenceClick={() => {
                                  if (isSequenceStepsEmpty(sequence)) {
                                    openDialog(DialogId.CREATE_SEQUENCE)
                                  } else {
                                    addCandidateToSequence([candidateJob.id])
                                  }
                                }}
                              />
                            }
                            viewMode={currViewMode}
                          />
                        </div>
                      )
                    })}
                  </div>
                  <div
                    style={{
                      // maxWidth: renderedCandidates?.length ? CANDIDATES_PAGES_MAX_WIDTH : '100%',
                      maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                      flex: 1
                      // paddingBottom: spacing[48]
                    }}
                  >
                    {emptyState}
                  </div>
                </div>
              }
            />
          }
        />
      </CompaniesPreferencesProvider>
    </>
  )
}

export default JobCandidatesShortlistedPage
