import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import type {
  Table as TanstackTable,
  AccessorKeyColumnDef,
  RowData,
  Row,
  ColumnDef,
  SortingFn
} from '@tanstack/react-table'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
// import { Icon } from '../icon'
import { Caption } from '../typography'
import * as S from './table.styled'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useTheme } from 'styled-components'
import { isNil, isString } from 'lodash'
import {
  CONTENT_PADDING,
  HEADER_PADDING,
  CANDIDATES_TABLE_HEADER_HEIGHT,
  ERROR_BLOCK_HEIGHT
} from 'src/styles/constants'
import { Flex } from '../flex'
import { ColumnWidthType } from './column-width-type'
import { FavoriteHeader } from 'src/components/tables/candidate-table-cells'
import { useLocation } from 'react-router-dom'
import { IndeterminateCheckbox } from './IndeterminateCheckbox'
import { Button } from '../button'
import { When } from 'src/components/blocks/when'

interface ColumnWidth {
  type: ColumnWidthType
  value: number
}

interface ColumnSorting<T>
  extends Record<string, Partial<ColumnDef<T>> & { sortingFn?: SortingFn<T> }> {}

export interface ExpandableColumn {
  columnId: string
  expandableColumnId: string
  width: number
  expandableColumnHeader?: React.ReactNode
}

interface TableProps<T> {
  isLoading?: boolean
  schema: Record<string, string | React.ReactNode>
  tableData: T[]
  columnSizes?: Record<string, ColumnWidth>
  columnSorting?: ColumnSorting<T>
  onRowClick?: (row: Row<T>) => void
  setRowSelection: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  rowSelection: Record<string, boolean>
  ignoredCellsOnRowClick?: string[]
  expandableColumns?: ExpandableColumn[]
  onToggleExpandableColumn?: (columnId: string | null) => void
  selectionActions?: React.ReactNode
  emptyState?: React.ReactNode
  pageHeaderHeight?: number
  height?: string
  isGlobalErrorOpen?: boolean
}

export type TableSchema<T> = {
  [K in keyof T]: string | React.ReactNode
}

const createAccessorColumn = <TData extends RowData>(
  key: keyof TData,
  header: string | React.ReactNode,
  columnSorting?: ColumnSorting<TData>[string]
): AccessorKeyColumnDef<TData, unknown> => {
  return {
    accessorKey: key,
    header: () => <>{header}</>,
    cell: (info) => <>{info.getValue()}</>,
    enableSorting: Boolean(columnSorting?.sortingFn),
    ...columnSorting
  }
}

export const SELECTION_COLUMN_ID = 'selection'
export const ACTIONS_COLUMN_ID = 'actions'
export const FAVORITE_COLUMN_ID = 'favorite'
const DEFAULT_SYSTEM_COLUMNS_SIZES = {
  [SELECTION_COLUMN_ID]: { type: ColumnWidthType.FIXED, value: 48 },
  [ACTIONS_COLUMN_ID]: { type: ColumnWidthType.FIXED, value: 32 }
}

export const Table = <T extends object>({
  isLoading = false,
  schema,
  tableData,
  columnSizes,
  columnSorting,
  onRowClick,
  setRowSelection,
  rowSelection,
  ignoredCellsOnRowClick,
  expandableColumns,
  onToggleExpandableColumn,
  selectionActions,
  emptyState,
  pageHeaderHeight = 0,
  isGlobalErrorOpen = false
}: TableProps<T>): JSX.Element => {
  const location = useLocation()
  const cellsToIgnoreOnClick = useMemo(() => [...(ignoredCellsOnRowClick ?? []), SELECTION_COLUMN_ID, ACTIONS_COLUMN_ID], [ignoredCellsOnRowClick])
  const [rangeSelectionStart, setRangeSelectionStart] = useState<number | null>(null)

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility: Record<string, boolean> = {}
    // Object.keys(schema).forEach(key => {
    //   initialVisibility[key] = true
    // })
    Object.keys(schema).forEach(key => {
      initialVisibility[key] = !key.includes('Expanded')
    })
    initialVisibility[SELECTION_COLUMN_ID] = true
    initialVisibility[ACTIONS_COLUMN_ID] = true
    initialVisibility[FAVORITE_COLUMN_ID] = true
    return initialVisibility
  })

  const theme = useTheme()

  const maxHeight = useMemo(() => {
    const errorBlockHeight = isGlobalErrorOpen ? ERROR_BLOCK_HEIGHT : '0px'
    if (pageHeaderHeight > 0) {
      return `calc(100vh - ${pageHeaderHeight}px - ${CONTENT_PADDING} - ${errorBlockHeight})`
    }
    return `calc(100vh - 62px - ${HEADER_PADDING}*3 - ${CONTENT_PADDING} - ${errorBlockHeight})`
    // return `calc(100vh - ${HEADER_PADDING}*3 - ${CONTENT_PADDING} - ${errorBlockHeight})`
  }, [isGlobalErrorOpen, pageHeaderHeight])

  const columns = useMemo(() => {
    const visibleColumns = Object.keys(schema).filter(key => columnVisibility[key])
    return [
      {
        id: SELECTION_COLUMN_ID,
        accessorKey: SELECTION_COLUMN_ID,
        header: ({ table }: { table: TanstackTable<T> }) => (
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row, table }: { row: Row<T>, table: TanstackTable<T> }) => (
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            onClick={(event) => {
              const rowIndex = row.index
              if (event.shiftKey) {
                if (!isNil(rangeSelectionStart)) {
                  const rows = table.getRowModel().rows
                  const start = Math.min(rangeSelectionStart, rowIndex)
                  const end = Math.max(rangeSelectionStart, rowIndex)
                  const newSelection: Record<string, boolean> = { ...rowSelection }
                  for (let i = start; i <= end; i++) {
                    const rowId = rows[i].id
                    newSelection[rowId] = true
                  }
                  setRowSelection((current) => ({ ...current, ...newSelection }))
                }
              } else {
                setRangeSelectionStart(row.index)
              }
            }}
          />
        ),
        enableSorting: false
      },
      ...visibleColumns.map((key) =>
        createAccessorColumn(key as keyof T, schema[key], columnSorting?.[key])
      )
    ]
  }, [columnSorting, rangeSelectionStart, rowSelection, schema, setRowSelection, columnVisibility])

  // To avoid from accidentally selecting text on the site,
  // we want to disable user-select while there is a rowSelection active.
  useEffect(() => {
    const hasSelectedRows = Object.keys(rowSelection).length >= 1

    if (hasSelectedRows) {
      document.body.style.userSelect = 'none'
    } else {
      document.body.style.userSelect = ''
    }

    return () => {
      document.body.style.userSelect = ''
    }
  }, [rowSelection])

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      columnVisibility
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => (row as Row<T>).id,
    defaultColumn: {
      minSize: 0,
      size: 0
    },
    // enableSorting: true,
    getSortedRowModel: getSortedRowModel(),
    enableHiding: true,
    onColumnVisibilityChange: (updater) => {
      const newVisibility = typeof updater === 'function' ? updater(columnVisibility) : updater
      setColumnVisibility(newVisibility as typeof columnVisibility)
    }
  })

  const gridColumnSizes = useMemo((): string => {
    const mergedColumnSizes: TableProps<T>['columnSizes'] = { ...DEFAULT_SYSTEM_COLUMNS_SIZES, ...columnSizes }
    return columns.map((column) => {
      const key = column.id ?? column.accessorKey
      if (!isString(key)) {
        return `minmax(${column.minSize ?? 0}, 1fr)`
      }
      const expandedColumn = expandableColumns?.find(ec => ec.expandableColumnId === key)
      if (expandedColumn) {
        return `${expandedColumn.width}px`
      }

      if (isNil(mergedColumnSizes[key])) {
        return `minmax(${column.minSize ?? 0}, 1fr)`
      }
      if (mergedColumnSizes[key].type === ColumnWidthType.FIXED) {
        return `${mergedColumnSizes[key].value}px`
      } else {
        return `minmax(${column.minSize ?? 0}, ${mergedColumnSizes[key].value}fr)`
      }
    }).join(' ')
  }, [columnSizes, columns, expandableColumns])

  // We want to ignore candidateId params in the url
  // to reset the table, otherwise this will result in
  // recalculating the table columns when opening
  // or closing the candidate details dialog.
  const candidateIdParams = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('candidateId')
  }, [location.search])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const isCandidateIdPresent = params.has('candidateId')

    if (!isCandidateIdPresent) {
      // Detect URL route key change and reset table
      table.reset()
      table.resetRowSelection()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, candidateIdParams, tableData, table])

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 54,
    getScrollElement: () => tableContainerRef.current,
    // measureElement:
    //   typeof window !== 'undefined' &&
    //   navigator.userAgent.includes('Firefox')
    //     ? element => element?.getBoundingClientRect().height
    //     : undefined,
    overscan: 5
  })

  const items = rowVirtualizer.getVirtualItems()
  const [beforeBuffer, afterBuffer] = useMemo(() => {
    if (items.length === 0) {
      return [0, 0]
    }
    return [
      items[0].start - rowVirtualizer.options.scrollMargin,
      rowVirtualizer.getTotalSize() - items[items.length - 1].end
    ]
  }, [items, rowVirtualizer])

  const handleToggleExpandableColumn = useCallback((columnId: string) => {
    onToggleExpandableColumn?.(columnVisibility[columnId] ? null : columnId)
    setColumnVisibility(prev => ({
      ...prev,
      [columnId]: !prev[columnId]
    }))
  }, [columnVisibility, onToggleExpandableColumn])

  if (isLoading) {
    return <LoadingSkeleton $variant="CandidatesTable" />
  }

  if (tableData?.length === 0) {
    return <>{emptyState}</>
  }

  // console.log('Last row:', tableData[tableData.length - 1]?.candidateJob?.candidate?.name)

  return (
    <S.TableWrapper>
      <div
        ref={tableContainerRef}
        style={{
          // position: 'relative',
          overflow: 'auto',
          maxHeight,
          height: '100%',
          width: '100%'
        }}
      >
        <S.Table
          style={{
            minWidth: '100%',
            display: 'grid',
            position: 'relative',
            gridTemplateColumns: gridColumnSizes,
            paddingTop: beforeBuffer,
            paddingBottom: afterBuffer
          }}
        >
          <S.Head
            style={{
              height: CANDIDATES_TABLE_HEADER_HEIGHT
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  if (!header.column.getIsVisible()) return null
                  return (
                    <S.ColumnHead
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        height: '100%',
                        cursor: header.column.getCanSort() ? 'pointer' : 'default'
                      }}
                      $isExpandable={header.column.id.includes('criteriaExpanded')}
                    >
                      <S.ColumnHeadInner>
                        <When condition={header.column.id === 'criteriaExpanded'}>
                          <>
                            {expandableColumns?.find(ec => ec.expandableColumnId === 'criteriaExpanded')?.expandableColumnHeader}
                          </>
                        </When>
                        <When condition={header.column.id !== 'criteriaExpanded'}>
                          {
                            header.column.id === FAVORITE_COLUMN_ID
                              ? (
                                  <FavoriteHeader sorting={header.column.getIsSorted()} />
                                )
                              : (
                                  flexRender(header.column.columnDef.header, header.getContext())
                                )
                          }
                          {header.column.id !== FAVORITE_COLUMN_ID && header.column.getIsSorted()
                            ? {
                                asc: (
                                  <Button
                                    $variant="ghost"
                                    $colorTheme="muted"
                                    leadingIcon="arrow-up-narrow-wide"
                                    $width={12}
                                    $height={12}
                                    iconSize={10}
                                    onClick={
                                      header.column.getCanSort()
                                        ? (event) => {
                                            const toggleSortingHandler = header.column.getToggleSortingHandler()
                                            if (typeof toggleSortingHandler === 'function') {
                                              toggleSortingHandler(event)
                                            }
                                          }
                                        : undefined
                                    }
                                  />
                                ),
                                desc: (
                                  <Button
                                    $variant="ghost"
                                    $colorTheme="muted"
                                    leadingIcon="arrow-down-wide-narrow"
                                    $width={12}
                                    $height={12}
                                    iconSize={10}
                                    onClick={
                                      header.column.getCanSort()
                                        ? (event) => {
                                            const toggleSortingHandler = header.column.getToggleSortingHandler()
                                            if (typeof toggleSortingHandler === 'function') {
                                              toggleSortingHandler(event)
                                            }
                                          }
                                        : undefined
                                    }
                                  />
                                )
                              }[header.column.getIsSorted() as 'asc' | 'desc']
                            : header.column.id !== FAVORITE_COLUMN_ID && header.column.getCanSort() && (
                              <Flex $align="center" $justify="space-between" $gap={8}>
                                <Button
                                  $variant="ghost"
                                  $colorTheme="muted"
                                  leadingIcon="arrow-up-down"
                                  $width={12}
                                  $height={12}
                                  iconSize={10}
                                  onClick={
                                    header.column.getCanSort()
                                      ? (event) => {
                                          const toggleSortingHandler = header.column.getToggleSortingHandler()
                                          if (typeof toggleSortingHandler === 'function') {
                                            toggleSortingHandler(event)
                                          }
                                        }
                                      : undefined
                                  }
                                />
                                {
                                  // expandableColumns?.includes(header.column.id) && (
                                  expandableColumns?.some(ec => ec.columnId.includes(header.column.id)) && (
                                    <Button
                                      $variant="flat"
                                      $colorTheme="muted"
                                      leadingIcon={columnVisibility?.criteriaExpanded ? 'chevrons-left' : 'chevrons-right'}
                                      $width={12}
                                      $height={12}
                                      iconSize={10}
                                      onClick={() => { handleToggleExpandableColumn('criteriaExpanded') }}
                                    />
                                  )
                                }
                              </Flex>
                            )
                          }
                        </When>
                      </S.ColumnHeadInner>
                    </S.ColumnHead>
                  )
                })}
              </tr>
            ))}
            {Object.keys(rowSelection)?.length >= 1 && selectionActions && (
              <tr>
                <S.HeadActions>
                  <Caption size="XS" $whiteSpace="nowrap">
                    {Object.keys(rowSelection).length} selected
                  </Caption>
                  {selectionActions}
                </S.HeadActions>
              </tr>
            )}
          </S.Head>
          <S.Body>
            {items.map((virtualRow) => {
              const row = rows[virtualRow.index]
              return (
                <S.TableRow
                  data-index={virtualRow.index}
                  // ref={(node) => { rowVirtualizer.measureElement(node) }}
                  key={row.id}
                  style={{
                    backgroundColor:
                      virtualRow.index % 2 === 0
                        ? theme.colors.bgSecondary
                        : theme.colors.bgPrimary,
                    height: `${virtualRow.size}px`
                  }}
                  data-selection={row.getIsSelected() ? 'active' : 'inactive'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <S.TableCell
                      key={cell.id}
                      $isExpandable={expandableColumns?.some(ec => ec.columnId.includes(cell.column.id))}
                      onClick={(e) => {
                        if (
                          !cellsToIgnoreOnClick?.some((ignoreValue) =>
                            cell.id.includes(ignoreValue)
                          ) &&
                          onRowClick
                        ) {
                          onRowClick(row)
                        }

                        if (cell.id.includes(SELECTION_COLUMN_ID)) {
                          row.getToggleSelectedHandler()(e)
                        }
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </S.TableCell>
                  ))}
                </S.TableRow>
              )
            })}
          </S.Body>
        </S.Table>
      </div>
    </S.TableWrapper>
  )
}
