import { useState } from 'react'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import { Paragraph } from 'src/components/primitives/typography'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'

interface RateThisSearchProps {
  disabled: boolean
}

export const RateThisSearch = ({ disabled }: RateThisSearchProps): JSX.Element => {
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false)
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false)
  const { openDialog } = useDialog()
  return (
    <Flex $align='center' $gap={8} $width='fit-content'>
      <Paragraph size='XS' $fontWeight={500}>
        Rate this search
      </Paragraph>
      <Flex $align='center' $gap={4} $width='fit-content'>
        <Button
          leadingIcon={thumbsUpClicked ? Icons.thumbsUpFill : Icons.thumbsUp}
          $variant='flat'
          $colorTheme={thumbsUpClicked ? 'positive' : 'muted'}
          $height={24}
          $width={24}
          $fontSize={12}
          disabled={disabled || thumbsDownClicked || thumbsUpClicked}
          onClick={() => {
            setThumbsUpClicked(true)
          }}
        />
         <Button
          leadingIcon={thumbsDownClicked ? Icons.thumbsDownFill : Icons.thumbsDown}
          $variant='flat'
          $colorTheme={thumbsDownClicked ? 'negative' : 'muted'}
          $height={24}
          $width={24}
          $fontSize={12}
          disabled={disabled || thumbsDownClicked || thumbsUpClicked}
          onClick={() => {
            setThumbsDownClicked(true)
            openDialog(DialogId.REFINEMENT_FEEDBACK)
          }}
        />
      </Flex>
    </Flex>
  )
}
