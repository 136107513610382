import { useState } from 'react'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { EmptyState } from 'src/components/blocks/empty-state'
import RouteBuilder from 'src/libs/route-builder'
import { CandidateJobSequenceFilter, CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { useParams, useSearchParams } from 'react-router-dom'
import { isNil, isString, parseInt } from 'lodash'
import { findCandidatesWithErrors } from 'src/libs/find-candidates-with-errors'
import { CandidatesTable } from '../candidates-table'
import {
  ExportToAtsButton,
  MoveToAnotherJobButton,
  PauseResumeSequenceButton,
  RejectButton,
  WriteEmailButton
} from '../candidate-table-actions'
import { COLUMN } from '../candidates-table/table-builder'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useQueryParams } from 'src/hooks/use-query-params'
import { useFilteredCandidateJobs } from 'src/hooks/use-filtered-candidate-jobs'

interface CandidatesInSequenceTableProps {
  pageHeaderHeight: number
}

export const CandidatesInSequenceTable = ({
  pageHeaderHeight = 0
}: CandidatesInSequenceTableProps): JSX.Element => {
  const { jobId } = useParams()
  const { data: job } = useJobQuery()
  const [searchParams] = useSearchParams()
  const [rowSelection, setRowSelection] = useState({})
  const positionSearchParam = searchParams.get('position')
  const position: number | undefined = isString(positionSearchParam)
    ? parseInt(positionSearchParam)
    : undefined
  const filterSearchParams = searchParams.get('filter')
  const { getParam } = useQueryParams()
  const candidateJobSequenceFilterParams = getParam('candidateJobsFilter')
  console.log('candidateJobSequenceFilterParams', candidateJobSequenceFilterParams)

  const { isPending, data: candidateJobs } = useCandidateJobsQuery(
    {
      stage: candidateJobSequenceFilterParams ? undefined : CandidateJobStage.PROSPECTING,
      sequenceStepPosition: !isNil(position) && !isNaN(position) ? position : undefined
    },
    {},
    candidateJobSequenceFilterParams
  )

  const getEmptyStateCopy = (): { heading: string, description: string } => {
    switch (candidateJobSequenceFilterParams) {
      case CandidateJobSequenceFilter.ACTIVE:
        return {
          heading: 'No active candidates in sequence',
          description: 'There are no candidates currently active in the outreach sequence.'
        }
      case CandidateJobSequenceFilter.REPLIED:
        return {
          heading: 'No replies received',
          description: 'None of the candidates in sequence have replied yet.'
        }
      case CandidateJobSequenceFilter.OPENED:
        return {
          heading: 'No emails opened',
          description: 'None of the candidates have opened the outreach emails yet.'
        }
      case CandidateJobSequenceFilter.SCHEDULED:
        return {
          heading: 'No interviews scheduled',
          description: 'No candidates have scheduled interviews from the outreach sequence.'
        }
      case CandidateJobSequenceFilter.CONTACTED:
        return {
          heading: 'No candidates contacted',
          description: 'No candidates have been contacted through the outreach sequence yet.'
        }
      default:
        return {
          heading: 'No one in sequence',
          description: "Looks like you don't have any prospects yet, start sourcing candidates and all of them will show up right here"
        }
    }
  }

  const emptyStateRenderedCopy = getEmptyStateCopy()

  let emptyState = (
    <EmptyState
      heading={emptyStateRenderedCopy.heading}
      description={emptyStateRenderedCopy.description}
      svg="profileCard"
      actions={[
        {
          href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
          children: 'Go to sourcing'
        }
      ]}
    />
  )

  if (!isNil(position)) {
    emptyState = (
      <EmptyState
        heading="No one at this step"
        description="Looks like you don’t have any prospects at this step yet, try viewing all candidates in outreach"
        svg="profileCard"
        actions={[
          {
            href: RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }),
            children: 'Go to all in outreach'
          }
        ]}
      />
    )
  }

  const { filteredCandidateJobs } = useFilteredCandidateJobs()

  const renderedCandidates =
    filterSearchParams === 'errors' ? findCandidatesWithErrors(candidateJobs ?? []) : filteredCandidateJobs(candidateJobs)

  return (
    <>
      <CandidatesTable
        pageHeaderHeight={pageHeaderHeight}
        isLoading={isPending}
        candidateJobs={renderedCandidates}
        emptyState={emptyState}
        visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.STATUS, COLUMN.LAST_CONTACT, COLUMN.NEXT_CONTACT, COLUMN.SOURCE]}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        selectedRowsActions={
          <>
            <PauseResumeSequenceButton
              selectedRows={rowSelection}
              onResetSelectedRows={() => {
                setRowSelection({})
              }}
            />
            <RejectButton
              selectedRows={rowSelection}
              onResetSelectedRows={() => {
                setRowSelection({})
              }}
            />
            <WriteEmailButton selectedRows={rowSelection} candidateJobs={candidateJobs} />
            <MoveToAnotherJobButton selectedRows={rowSelection} candidateJobs={candidateJobs} />
            <ExportToAtsButton selectedRows={rowSelection} candidateJobs={candidateJobs} job={job}/>
          </>
        }
      />
    </>
  )
}
