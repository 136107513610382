import * as S from './search-refinement-form.styled'
import { Icon, Icons } from 'src/components/primitives/icon/icon'
import { Button } from 'src/components/primitives/button'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SearchRefinementEditor } from '../search-refinement-editor'
import type { Editor } from '@tiptap/react'
import { Paragraph } from 'src/components/primitives/typography'
import { Flex } from 'src/components/primitives'
import { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { Spinner } from 'src/components/primitives/spinner'
import { useGenerateSearchCriteria } from 'src/hooks/mutations/use-generate-search-criteria'
import { RefinementFilterCriteria } from '../refinement-filter-criteria'
import { isEmpty, isEqual, isNil } from 'lodash'
import { useParams } from 'react-router'
import { When } from '../when'
import { useOnClickOutside } from 'usehooks-ts'
import { motion } from 'framer-motion'
import { Tooltip } from 'src/components/primitives/tooltip'
import { diffUpdatedCriterias, filterOutCurrentJobTitle, sanitizeRefinementCriteria } from 'src/utils/refinement-criteria'
import { isRefinementErrored } from 'src/libs/api/backend/jobs'
import type { JobSourcingState } from 'src/libs/api/backend/jobs'
import { DEFAULT_CRITERIA } from '../refinement-filter-criteria/constants'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useAtom } from 'jotai'
import { updatedCriteriasAtom } from 'src/stores/jobRefinement'

interface SearchRefinementSuggestion {
  tag: string
  query: string
}

interface SearchRefinementFormProps {
  initialCriteria: Criteria | undefined
  isRecommending: boolean
  setIsRecommending: (isRecommending: boolean) => void
  onSubmit: (criteria: Criteria) => void
  suggestions?: SearchRefinementSuggestion[]
  isNewRefinement?: boolean
  setNumberOfFilters: (numberOfFilters: number) => void
  setIsDirty: (isDirty: boolean) => void
  isDirty: boolean
  triggerClose: () => void
  searchRefinementStatus: JobSourcingState | null | undefined
}

export const SearchRefinementForm = ({
  initialCriteria,
  onSubmit,
  setIsRecommending,
  isRecommending,
  // suggestions = DEFAULT_REFINEMENT_SUGGESTIONS,
  isNewRefinement = false,
  setNumberOfFilters,
  setIsDirty,
  isDirty,
  triggerClose,
  searchRefinementStatus
}: SearchRefinementFormProps): JSX.Element => {
  const { jobId } = useParams()
  const { data: job } = useJobQuery()
  const [isFocused, setIsFocused] = useState(!isRecommending && isNewRefinement)
  const [firstCharacterInput, setFirstCharacterInput] = useState(false)
  const [searchQuery, setSeachQuery] = useState<string | null>(null)
  const [editor, setEditor] = useState<Editor | null>(null)
  const [generatingCriteria, setGeneratingCriteria] = useState(false)
  const [criteria, setCriteria] = useState<Criteria | undefined>(undefined)
  const [criteriaOrder, setCriteriaOrder] = useState<CriteriaKey[] | undefined>(undefined)
  const { generateSearchCriteria } = useGenerateSearchCriteria()
  const [updatedCriterias, setUpdatedCriterias] = useAtom(updatedCriteriasAtom)
  const inputFieldRef = useRef<HTMLDivElement>(null)

  const updateSystemCriteria = useCallback((criteria: Criteria) => {
    const { [CriteriaKey.CURRENT_JOB_TITLES]: _currentJobTitles, ...rest } = criteria
    setCriteria(criteria)
    setCriteriaOrder([CriteriaKey.CURRENT_JOB_TITLES, ...Object.keys(rest) as CriteriaKey[]])
  }, [])

  useOnClickOutside(inputFieldRef, () => {
    if (!generatingCriteria) {
      setIsFocused(false)
    }
  })

  useEffect(() => {
    if (firstCharacterInput) {
      setIsFocused(true)
    }
    return () => {
      setFirstCharacterInput(false)
    }
  }, [firstCharacterInput])

  useEffect(() => {
    if (isNil(criteria) && !isNil(initialCriteria)) {
      updateSystemCriteria(initialCriteria)
    }
  }, [criteria, initialCriteria, updateSystemCriteria])

  useEffect(() => {
    if (criteria && isNil(criteriaOrder)) {
      setCriteriaOrder(Object.keys(criteria) as CriteriaKey[])
    }
  }, [criteria, criteriaOrder])

  useEffect(() => {
    if (!isNil(criteria)) {
      const { custom_requirements: customRequirements, ...rest } = criteria
      const numberOfFilters = Object.keys(rest).length + (customRequirements ?? []).length
      setNumberOfFilters(numberOfFilters)
    }
  }, [criteria, setNumberOfFilters])

  const updateCriteria = useCallback((newCriteria: Criteria): void => {
    setCriteria(newCriteria)
    setIsDirty(true)
  }, [setCriteria, setIsDirty])

  const isProcessing = useMemo(() => {
    return isRecommending || generatingCriteria
  }, [isRecommending, generatingCriteria])

  const isErrored = useMemo(() => {
    return isRefinementErrored(searchRefinementStatus)
  }, [searchRefinementStatus])

  const disableSubmit = useMemo(() => {
    return (isProcessing || !isDirty || isEqual(criteria, DEFAULT_CRITERIA)) && !isErrored
  }, [isProcessing, isDirty, criteria, isErrored])

  const clearCriteria = useCallback(() => {
    updateSystemCriteria(DEFAULT_CRITERIA)
  }, [updateSystemCriteria])

  const handleSubmit = useCallback(async (searchQuery: string | null): Promise<void> => {
    if (searchQuery && !isProcessing && jobId) {
      const trimmedQuery = searchQuery.trim()
      // Add current job title to the criteria to generate results. This will be removed on the BE before saving
      const previousCriteria = {
        ...criteria,
        [CriteriaKey.CURRENT_JOB_TITLES]: [
          {
            name: job?.title ?? '',
            optional: false,
            negative: false
          },
          ...(criteria?.[CriteriaKey.CURRENT_JOB_TITLES] ?? [])
        ]
      }
      if (trimmedQuery) {
        setIsDirty(true)
        setGeneratingCriteria(true)
        generateSearchCriteria({
          jobId,
          instructions: trimmedQuery,
          previousCriteria: sanitizeRefinementCriteria(previousCriteria),
          onSuccess: (data) => {
            const sanitizedCriteria = sanitizeRefinementCriteria(data)
            const trimmedJobTitles = filterOutCurrentJobTitle(sanitizedCriteria[CriteriaKey.CURRENT_JOB_TITLES] ?? [], job?.title ?? '')
            const newCriteria = {
              ...sanitizedCriteria,
              [CriteriaKey.CURRENT_JOB_TITLES]: trimmedJobTitles
            }
            const diff = diffUpdatedCriterias(newCriteria, criteria)
            setUpdatedCriterias(diff)
            updateSystemCriteria(newCriteria)
            setGeneratingCriteria(false)
            setSeachQuery(null)
            editor?.commands.clearContent()
            editor?.commands.blur()
            setIsFocused(false)
          }
        })
      }
    }
  }, [criteria, editor?.commands, generateSearchCriteria, isProcessing, job?.title, jobId, setIsDirty, setUpdatedCriterias, updateSystemCriteria])

  const startSourcingCandidates = useCallback(() => {
    if (!disableSubmit) {
      const sanitizedCriteria = sanitizeRefinementCriteria(criteria)
      if (!isEmpty(sanitizedCriteria)) {
        setIsRecommending(true)
        onSubmit(sanitizedCriteria)
        editor?.commands.clearContent()
        editor?.commands.blur()
        setIsDirty(false)
        setIsFocused(false)
      }
    }
  }, [criteria, disableSubmit, editor?.commands, onSubmit, setIsDirty, setIsRecommending])

  // const handleAddSuggestion = useCallback((suggestion: string): void => {
  //   if (editor) {
  //     const metaCharacter = /\s/g
  //     const { $to } = editor.view.state.selection
  //     const lastCharacter = ($to.nodeBefore?.textContent ?? '').slice(-1)
  //     const isLastCharacterASpace = lastCharacter.match(metaCharacter)
  //     if (!lastCharacter || isLastCharacterASpace) {
  //       editor.chain().focus().insertContent(`${suggestion} `).run()
  //     } else {
  //       editor.chain().focus().insertContent(` ${suggestion} `).run()
  //     }
  //   }
  // }, [editor])

  const disabledSubmitTooltipText = useMemo(() => {
    if (isProcessing) {
      return 'Pin is recommending candidates'
    }
    if (generatingCriteria) {
      return 'Pin is generating criteria from your instructions'
    }
    if (isEqual(criteria, DEFAULT_CRITERIA)) {
      return 'Need 2 or more criteria for better results'
    }
    if (!isDirty) {
      return 'No new changes detected'
    }
    return ''
  }, [isProcessing, generatingCriteria, criteria, isDirty])

  return (
    <S.Wrapper>
      <S.InputFormWrapper
        ref={inputFieldRef}
        animate={{ height: isFocused ? 206 : 74 }}
        transition={{ duration: 0.2, delay: 0, ease: 'circOut' }}
        $isLoading={isProcessing}
      >
        <S.SearchRefinementForm $isLoading={isProcessing}>
          <S.Container
            onClick={() => {
              if (!isProcessing && !isFocused) {
                setIsFocused(true)
              }
            }}
          >
            <S.Icon $isLoading={isProcessing}>
              <Icon name={Icons.refinementInput} />
            </S.Icon>
            <SearchRefinementEditor
              placeholder='Tell Pin what you’re looking for or paste your full job description…'
              isEditable={!isProcessing}
              onDataChanged={(data) => {
                if (!isProcessing) {
                  setSeachQuery(data)
                  if (!firstCharacterInput && data.length > 0) {
                    setFirstCharacterInput(true)
                  }
                }
              }}
              forceEditorFocus={isFocused}
              onSubmit={(newQuery: string) => {
                void handleSubmit(newQuery)
              }}
              setEditor={setEditor}
            />
          </S.Container>
          {/* {suggestions && suggestions.length > 0 && newRefinement && (
            <S.SuggestionsArea>
              <S.SuggestedContainer>
                <Caption size='2XS' $color='fgSecondary' $align='left'>SUGGESTED</Caption>
              </S.SuggestedContainer>
              <S.Suggestions style={{ pointerEvents: isSearching ? 'none' : 'all' }}>
                {suggestions.map((suggestion) => (
                  <S.Suggestion
                    key={suggestion.tag}
                    aria-label={`Add ${suggestion.tag} to search input for refinement`}
                    onClick={() => {
                      handleAddSuggestion(suggestion.query)
                    }}
                  >
                    + {suggestion.tag}
                  </S.Suggestion>
                ))}
              </S.Suggestions>
            </S.SuggestionsArea>
          )} */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{
              opacity: isFocused ? 1 : 0,
              y: isFocused ? 0 : -20
            }}
            transition={{ duration: isFocused ? 0.2 : 0.05, delay: 0, ease: 'circOut' }}
            style={{ pointerEvents: isFocused ? 'all' : 'none' }}
          >
            <When condition={isFocused}>
              <S.ActionsBar>
                { isProcessing
                  ? <S.AskingPinText>
                      <Spinner />
                      <Paragraph size='XS'>Asking Pin</Paragraph>
                    </S.AskingPinText>
                  : <>&nbsp;</>
                }
                { !isProcessing && (
                  <Flex $align='center' $justify='flex-end' $gap={8} $width='auto'>
                    <Button
                      $variant='outline'
                      $height={32}
                      $fontSize={14}
                      onClick={() => {
                        editor?.commands.clearContent()
                        editor?.commands.blur()
                        setIsFocused(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      $variant="fill"
                      $colorTheme="tint"
                      $height={32}
                      $fontSize={14}
                      disabled={!searchQuery}
                      onClick={() => {
                        void handleSubmit(searchQuery)
                      }}
                    >
                      Send
                    </Button>
                  </Flex>
                )}
              </S.ActionsBar>
            </When>
          </motion.div>
        </S.SearchRefinementForm>
      </S.InputFormWrapper>
      <RefinementFilterCriteria
        criteria={criteria}
        setCriteria={updateCriteria}
        updatedGeneratedCriteria={updatedCriterias}
        criteriaOrder={criteriaOrder}
        setCriteriaOrder={setCriteriaOrder}
        disabled={isProcessing}
        clearCriteria={clearCriteria}
      />
      <S.SourceCandidates>
        <Tooltip
          trigger={
            <span>
              <Button
                nested
                $variant='fill'
                $colorTheme='tint'
                $height={32}
                $fontSize={14}
                disabled={disableSubmit}
                onClick={startSourcingCandidates}
              >
                Source Candidates
              </Button>
            </span>
          }
          disabled={!disableSubmit}
          triggerDisabled={disableSubmit}
        >
          {disabledSubmitTooltipText}
        </Tooltip>

        {!isNewRefinement && (
          <Button
            $variant='outline'
            $height={32}
            $fontSize={14}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              triggerClose()
            }}
          >
            Cancel
          </Button>
        )}
      </S.SourceCandidates>
    </S.Wrapper>
  )
}
