import * as Dialog from 'src/components/primitives/dialog'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import type { AlertArgs } from 'src/contexts/dialogs'
import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import type { ButtonStyleProps } from 'src/components/primitives/button'
import { Caption } from 'src/components/primitives/typography'
import * as S from './alert-dialog.styled'
import { Spacer } from 'src/components/primitives/spacer'
import { useTheme } from 'styled-components'

interface AlertDialogProps extends AlertArgs {}

export const AlertDialog = ({
  variant = 'negative',
  message,
  description,
  confirmText = 'Continue',
  cancelText = 'Cancel',
  onConfirm,
  onCancel
}: AlertDialogProps): JSX.Element => {
  const theme = useTheme()
  const { isDialogOpen, controlDialog } = useDialog()

  const getButtonColorTheme = (): ButtonStyleProps['$colorTheme'] => {
    switch (variant) {
      case 'negative': {
        return 'negative'
      }
      case 'tint': {
        return 'tint'
      }
      default: {
        return 'tint'
      }
    }
  }

  return (
    <Dialog.Root
      id={DialogId.ALERT}
      isOpen={isDialogOpen(DialogId.ALERT)}
      onOpenChange={(value) => {
        controlDialog(DialogId.ALERT, value)
      }}
      $width="480px"
    >
      <Dialog.Portal onCancel={onCancel} zIndex={theme.zindeces.alert as number}>
        <Dialog.Content>
          <S.AlertDialogInner>
            <Caption size="MD" $color="fgPrimary">
              {message}
            </Caption>
            {description && (
              <Caption size="SM" $color="fgSecondary" $align="center" $fontWeight={400}>
                {description}
              </Caption>
            )}
            <Spacer $size={12} />
            <Flex $gap={12}>
              <Button
                $variant="raised"
                $colorTheme="muted"
                $width="full"
                $align="center"
                onClick={() => {
                  controlDialog(DialogId.ALERT, false)
                  if (onCancel) {
                    onCancel()
                  }
                }}
              >
                {cancelText}
              </Button>
              <Button
                $variant="raised"
                $colorTheme={getButtonColorTheme()}
                $width="full"
                $align="center"
                onClick={() => {
                  controlDialog(DialogId.ALERT, false)
                  if (onConfirm) {
                    onConfirm()
                  }
                }}
              >
                {confirmText}
              </Button>
            </Flex>
          </S.AlertDialogInner>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
