import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { EmptyState } from 'src/components/blocks/empty-state'
import RouteBuilder from 'src/libs/route-builder'
import { CandidatesTable } from '../candidates-table'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { ExportToAtsButton, UndoRejectButton, WriteEmailButton } from '../candidate-table-actions'
import { COLUMN } from '../candidates-table/table-builder'
import { useJobQuery } from 'src/hooks/queries/use-job'

export const CandidatesArchivedTable = (): JSX.Element => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const { jobId } = useParams()
  const { data: job } = useJobQuery()
  const { isPending, data: rejectedCandidates } = useCandidateJobsQuery({
    archived: true
  })

  return (
    <CandidatesTable
      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.REJECTION_REASON, COLUMN.REJECTED_AT, COLUMN.SOURCE]}
      isLoading={isPending}
      candidateJobs={rejectedCandidates}
      setRowSelection={setRowSelection}
      rowSelection={rowSelection}
      pageHeaderHeight={64}
      emptyState={
        <EmptyState
          heading="No one archived"
          description="Looks like there’s no one archived yet. Candidates that are not interested, non responsive or passed by you will show up here."
          svg="userCards"
          actions={[
            {
              href: RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }),
              children: 'Go to sourcing'
            }
          ]}
        />
      }
      selectedRowsActions={
        <>
          <UndoRejectButton selectedRows={rowSelection} />
          <WriteEmailButton selectedRows={rowSelection} candidateJobs={rejectedCandidates} />
          <ExportToAtsButton selectedRows={rowSelection} candidateJobs={rejectedCandidates} job={job} />
        </>
      }
    />
  )
}
