import { useCallback, useEffect, useState } from 'react'
import { When } from 'src/components/blocks/when'
import { AddCandidateDialog } from 'src/components/dialogs/add-candidate-dialog'
import { CandidateDetailsDialog } from 'src/components/dialogs/candidate-details-dialog'
import { CreateCompanyDialog } from 'src/components/dialogs/create-company-dialog'
import { CreateNewPositionDialog } from 'src/components/dialogs/create-new-position-dialog'
import { CreateSequenceDialog } from 'src/components/dialogs/create-sequence-dialog'
import { EditCandidateDialog } from 'src/components/dialogs/edit-candidate-dialog'
import { WriteEmailDialog } from 'src/components/dialogs/write-email-dialog'
import { SearchCandidatesDialog } from 'src/components/dialogs/search-candidates-dialog'
import { DialogContext, DialogId } from 'src/contexts/dialogs'
import type { AlertArgs, DialogArgs } from 'src/contexts/dialogs'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { AlertDialog } from 'src/components/dialogs/alert-dialog'
import { JobSettingsDialog } from 'src/components/dialogs/job-settings-dialog'
import { DefaultSettingsDialog } from 'src/components/dialogs/default-settings-dialog'
import { useQueryParams } from 'src/hooks/use-query-params'
import { MoveToAnotherJobDialog } from 'src/components/dialogs/move-to-another-job-dialog'
import { NotificationsDialog } from 'src/components/dialogs/notifications-dialog'
import { DisconnectedEmailAccountDialog } from 'src/components/dialogs/disconnected-email-account-dialog'
import type { EmailAccount } from 'src/libs/api/backend/users'
import { SendFeedbackDialog } from 'src/components/dialogs/send-feedback-dialog'
import type { FeedbackDialogPayload } from 'src/components/dialogs/send-feedback-dialog'
import { WriteCustomOutreachDialog } from 'src/components/dialogs/write-custom-outreach-dialog'
import { BillingSubscribeDialog } from 'src/components/dialogs/billing-subscribe-dialog'
import { CalendarDialog } from 'src/components/dialogs/calendar-dialog'
import { EditCompanyDialog } from 'src/components/dialogs/edit-company-dialog/edit-company-dialog'
import { ArchiveJobDialog } from 'src/components/dialogs/archive-job-dialog'
import { useSession } from 'src/hooks/use-session'
import { RefinementFeedbackDialog } from 'src/components/dialogs/refinement-feedback-dialog'
import { ExportToAtsDialog } from 'src/components/dialogs/export-to-ats-dialog/export-to-ats-dialog'
import type { Job } from 'src/libs/api/backend/jobs'
import { EnableAutoOutreachDialog } from 'src/components/dialogs/enable-auto-outreach-dialog/enable-auto-outreach-dialog'
import type { CalendarProps } from 'src/components/blocks/calendar'
import { ConnectATSDialog } from 'src/components/dialogs/connect-ats-dialog'
import { CreateNewJobDialog } from 'src/components/dialogs/create-new-job-dialog'
import { CreateProjectDialog } from 'src/components/dialogs/create-project-dialog'
import type { Project } from 'src/libs/api/backend/projects'
import { EditProjectDialog } from 'src/components/dialogs/edit-project-dialog'

interface DialogProviderProps {
  children: React.ReactNode
}

export const DialogProvider = ({ children }: DialogProviderProps): JSX.Element => {
  const { getParam, setParam, resetParam } = useQueryParams()
  const candidateSearchParams = getParam('candidate')
  const settingsSearchParams = getParam('settings')
  const subscribeSearchParams = getParam('subscribe')
  const newJobParams = getParam('createNewJob')
  const { org } = useSession()

  const [dialogs, setDialogs] = useState<DialogArgs[]>([])

  const openDialog = <T,>(id: DialogArgs['id'], payload?: T): void => {
    setDialogs((prev) => [
      ...prev,
      {
        id,
        payload
      }
    ])
    switch (id) {
      case 'candidate-details': {
        if (payload) {
          const candidateJobId = payload as string
          if (candidateJobId) {
            setParam('candidate', candidateJobId)
          }
        }
        break
      }
      default: {
        break
      }
    }
  }

  const openAlert = (args: AlertArgs): void => {
    setDialogs((prev) => {
      if (prev.some((dialog) => dialog.id === DialogId.ALERT)) {
        return prev
      } else {
        return [
          ...prev,
          {
            id: DialogId.ALERT,
            payload: args
          }
        ]
      }
    })
  }

  const closeDialog = (id: DialogArgs['id']): void => {
    setDialogs((prev) => prev.filter((d) => d.id !== id))
    switch (id) {
      case 'candidate-details': {
        resetParam('candidate')
        break
      }
      default: {
        break
      }
    }
  }

  const controlDialog = (id: DialogArgs['id'], newState: boolean): void => {
    if (newState) {
      openDialog(id)
    } else {
      closeDialog(id)
    }
  }

  const isDialogOpen = useCallback((id: DialogArgs['id']): boolean => {
    return dialogs.some((dialog) => dialog.id === id)
  }, [dialogs])

  const getPayload = <T = unknown,>(id: DialogArgs['id']): T | undefined => {
    const dialog = dialogs.find((dialog) => dialog.id === id)
    return dialog?.payload as T | undefined
  }

  // Open candidate dialog when `?candidate=value` is detected in url
  useEffect(() => {
    if (candidateSearchParams && !isDialogOpen(DialogId.CANDIDATE_DETAILS)) {
      openDialog(DialogId.CANDIDATE_DETAILS, candidateSearchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateSearchParams])

  // Open settings dialog if `?settings=value` is detected in the url
  useEffect(() => {
    if (settingsSearchParams && !isDialogOpen(DialogId.DEFAULT_SETTINGS)) {
      openDialog(DialogId.DEFAULT_SETTINGS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsSearchParams])

  // Open subscribe dialog if `?subscribe=value` is detected in the url
  useEffect(() => {
    if (subscribeSearchParams && !isDialogOpen(DialogId.BILLING_SUBSCRIBE)) {
      openDialog(DialogId.BILLING_SUBSCRIBE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeSearchParams])

  useEffect(() => {
    if (newJobParams && !isDialogOpen(DialogId.CREATE_NEW_JOB)) {
      openDialog(DialogId.CREATE_NEW_JOB)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newJobParams])

  return (
    <DialogContext.Provider
      value={{
        dialogs,
        isDialogOpen,
        controlDialog,
        openDialog,
        openAlert,
        closeDialog,
        getPayload
      }}
    >
      {children}
      <>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.ADD_CANDIDATE)}>
          <AddCandidateDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.EDIT_CANDIDATE)}>
          <>
            {dialogs
              .filter((dialog) => dialog.id === DialogId.EDIT_CANDIDATE)
              .map((dialog, index) => {
                const candidateJob = dialog.payload as CandidateJobExpanded
                return <EditCandidateDialog key={index} candidateJobId={candidateJob.id} />
              })}
          </>
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.CREATE_SEQUENCE)}>
          <CreateSequenceDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.CREATE_JOB_POSITION)}>
          <CreateNewPositionDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.CREATE_DEPARTMENT)}>
          <CreateCompanyDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.EDIT_DEPARTMENT)}>
          <EditCompanyDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.WRITE_EMAIL)}>
          <>
            {dialogs
              .filter((dialog) => dialog.id === DialogId.WRITE_EMAIL)
              .map((dialog, index) => {
                const payloads = dialog.payload as CandidateJobExpanded[]
                return <WriteEmailDialog key={index} candidateJobs={payloads} />
              })}
          </>
        </When>
        {org?.id && dialogs
          .filter((dialog) => dialog.id === DialogId.CANDIDATE_DETAILS)
          .map((dialog, index) => {
            const candidateJobId = dialog.payload as string
            return <CandidateDetailsDialog key={index} candidateJobId={candidateJobId} />
          })
        }
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.SEARCH_CANDIDATES)}>
          <SearchCandidatesDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.NOTIFICATIONS)}>
          <NotificationsDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.ALERT)}>
          <>
            {dialogs
              .filter((dialog) => dialog.id === DialogId.ALERT)
              .map((dialog, index) => {
                const payload = dialog.payload as AlertArgs
                return <AlertDialog key={index} {...payload} />
              })}
          </>
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.JOB_SETTINGS)}>
          <JobSettingsDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.DEFAULT_SETTINGS)}>
          <DefaultSettingsDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.MOVE_TO_ANOTHER_JOB)}>
          {dialogs
            .filter((dialog) => dialog.id === DialogId.MOVE_TO_ANOTHER_JOB)
            .map((dialog, index) => {
              const { candidateJobs, requiredReason } = dialog.payload as {
                candidateJobs: CandidateJobExpanded[]
                requiredReason?: boolean
              }
              return (
                <MoveToAnotherJobDialog
                  key={index}
                  candidateJobs={candidateJobs}
                  requiredReason={requiredReason}
                />
              )
            })}
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.EXPORT_CANDIDATES)}>
          {dialogs
            .filter((dialog) => dialog.id === DialogId.EXPORT_CANDIDATES)
            .map((dialog, index) => {
              const { candidateJobs, job } = dialog.payload as {
                job: Job
                candidateJobs: CandidateJobExpanded[]
              }
              return (
                <ExportToAtsDialog
                  key={index}
                  job={job}
                  candidateJobs={candidateJobs}
                />
              )
            })}
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.DISCONNECTED_EMAIL_ACCOUNT)}>
          {dialogs
            .filter((dialog) => dialog.id === DialogId.DISCONNECTED_EMAIL_ACCOUNT)
            .map((dialog) => {
              const payload = dialog.payload as EmailAccount
              return <DisconnectedEmailAccountDialog sequenceSenderEmailAccount={payload} />
            })
          }
        </When>
        {dialogs
          .filter((dialog) => dialog.id === DialogId.SEND_FEEDBACK)
          .map((dialog, index) => {
            const payload = (dialog.payload ?? {}) as FeedbackDialogPayload
            return <SendFeedbackDialog key={index} type={payload.type} defaultContent={payload.defaultContent} />
          })
        }
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.WRITE_CUSTOM_OUTREACH)}>
          {dialogs
            .filter((dialog) => dialog.id === DialogId.WRITE_CUSTOM_OUTREACH)
            .map((dialog, index) => {
              const payload = dialog.payload as CandidateJobExpanded
              return <WriteCustomOutreachDialog key={index} candidateJob={payload} />
            })}

        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.BILLING_SUBSCRIBE)}>
          <BillingSubscribeDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.CALENDAR)}>
          {dialogs
            .filter((dialog) => dialog.id === DialogId.CALENDAR)
            .map((dialog, index) => {
              const candidateJobPayload = dialog.payload.candidateJob as CandidateJobExpanded
              const scheduleNewEvent = dialog.payload.scheduleNewEvent as CalendarProps['scheduleNewEvent']
              return <CalendarDialog key={index} candidateJob={candidateJobPayload} scheduleNewEvent={scheduleNewEvent} />
            })}
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.ARCHIVE_JOB)}>
          <ArchiveJobDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.REFINEMENT_FEEDBACK)}>
          <RefinementFeedbackDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.ENABLE_AUTO_OUTREACH)}>
          <EnableAutoOutreachDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.CONNECT_ATS)}>
          <ConnectATSDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.CREATE_NEW_JOB)}>
          <CreateNewJobDialog />
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.CREATE_PROJECT)}>
          {dialogs
            .filter((dialog) => dialog.id === DialogId.CREATE_PROJECT)
            .map((dialog) => {
              const departmentId = dialog.payload as string
              return <CreateProjectDialog departmentId={departmentId} />
            })
          }
        </When>
        <When condition={dialogs.some((dialog) => dialog.id === DialogId.EDIT_PROJECT)}>
          {dialogs
            .filter((dialog) => dialog.id === DialogId.EDIT_PROJECT)
            .map((dialog) => {
              const project = dialog.payload as Project
              return <EditProjectDialog project={project} />
            })
          }
        </When>
      </>
    </DialogContext.Provider>
  )
}
