import { useCallback, useMemo, useState } from 'react'
import { isNil } from 'lodash'
import type { NewJobFormData } from 'src/components/blocks/create-job-form/types/new-job-form-data'
import { JobDetailsForm } from 'src/components/blocks/job-details-form'
import { LoadingBox } from 'src/components/blocks/loading-box'
import { PageHeader } from 'src/components/blocks/page-header'
import { When } from 'src/components/blocks/when'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { useSettings } from 'src/hooks/use-settings'
import { useUpdateJobPosition } from 'src/hooks/mutations/use-update-job-position'
import { useSession } from 'src/hooks/use-session'
import { Card } from 'src/components/primitives/card'
import { Divider } from 'src/components/primitives/divider'
import { DeleteOrRestoreCard } from 'src/components/blocks/delete-or-restore-card'
import type { DeleteOrRestoreCardProps } from 'src/components/blocks/delete-or-restore-card'
import { Spacer } from 'src/components/primitives'
import { SEO } from 'src/components/primitives/seo'
import { Paragraph } from 'src/components/primitives/typography'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { format } from 'date-fns'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'

const JobSettingsDetailsPage = (): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { org } = useSession()
  const { currentJobId } = useSettings()
  const { isPending, data: jobData } = useJobQuery()
  const { updateJobPosition } = useUpdateJobPosition()
  const { data: orgUsers } = useOrgUsersQuery()
  const { openAlert, openDialog } = useDialog()

  // Placeholder to handle the job archive status
  const handleUpdateJob = useCallback(async (updatedJob: NewJobFormData): Promise<void> => {
    setIsSubmitting(true)
    const jobId = jobData?.id ?? currentJobId
    if (isNil(jobId)) {
      return
    }
    try {
      const updated = {
        ...updatedJob,
        id: jobId,
        title: updatedJob.title ?? '',
        departmentId: updatedJob?.departmentId === org?.id ? null : updatedJob.departmentId,
        salaryMin: updatedJob?.salaryMin ?? undefined,
        salaryMax: updatedJob?.salaryMax ?? undefined,
        workspace: updatedJob?.workspace ?? undefined
      }
      updateJobPosition({
        updatedJob: updated
      })
    } catch (e) {
      console.log(e)
    } finally {
      setIsSubmitting(false)
    }
  }, [currentJobId, jobData?.id, org?.id, updateJobPosition])

  const deleteOrRestoreProps = (archived: boolean): DeleteOrRestoreCardProps => {
    if (archived) {
      return {
        action: 'restore',
        heading: 'Restore job position',
        description:
          'Restore this job position if you accidentially archived it or if you need to access it again.',
        onClick: () => {
          openAlert({
            variant: 'tint',
            message: 'Are you sure you want to unarchive this job position?',
            description: 'Restoring a job will not unarchive candidates.',
            confirmText: 'Restore',
            cancelText: 'Keep archived',
            onConfirm: () => {
              // TODO: Not yet implemented
            }
          })
        }
      }
    } else {
      return {
        action: 'delete',
        heading: 'Archive job position',
        description:
          'You should archive a position once you’ve filled it or if you’re no longer looking.',
        onClick: () => {
          openDialog(DialogId.ARCHIVE_JOB, { job: jobData })
        }
      }
    }
  }

  const createdText = useMemo(() => {
    if (isNil(jobData)) {
      return null
    }

    const creatingOrgUser = orgUsers?.find((user) => user.id === jobData.createdByUserId)

    const textParts = ['Created']
    if (creatingOrgUser) {
      textParts.push(`by ${creatingOrgUser.name}`)
    }

    textParts.push(`on ${format(jobData.createdAt, 'MMM d, yyyy')}`)

    return textParts.join(' ')
  }, [jobData, orgUsers])

  return (
    <>
      <PageHeader
        heading="Job Position Details"
        actions={<Paragraph size="XS" $whiteSpace="nowrap">{createdText}</Paragraph>}
      />
      <SEO title={'Job Position Details'} />

      <Card $padding={{ top: 12, left: 24, right: 24, bottom: 24 }}>
        <When condition={isPending}>
          <LoadingBox $variant="solid">Loading...</LoadingBox>
        </When>
        <When condition={!isPending}>
          <JobDetailsForm
            onSubmit={(data) => {
              void handleUpdateJob(data)
            }}
            formIsSubmitting={isSubmitting}
            jobData={jobData}
            initialValues={{
              diversitySearch: false
            }}
            submitButtonText="Save changes"
            isCreating={false}
          />
        </When>
      </Card>

      {!isPending && !isNil(jobData) && !jobData.deleted &&
        <>
          <Divider $spacing={{ before: 40, after: 40 }} />
          <DeleteOrRestoreCard {...deleteOrRestoreProps(jobData.deleted)} />
        </>
      }

      <Spacer $size={80} />
    </>
  )
}

export default JobSettingsDetailsPage
