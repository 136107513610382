import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing[12]};
  height: 64px;
  margin-bottom: 18px;
`

export const FilterButton = styled.div<{ $isActive: boolean, $isDisabled: boolean }>`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing[12]} ${theme.spacing[18]}`};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[4]};
  flex: 1 0 0;
  border-radius: ${({ theme }) => theme.spacing[4]};
  background: ${({ theme }) => theme.colors.bgPrimary};
  box-shadow: ${({ theme }) => theme.boxShadows.sm};
  height: 64px;
  ${({ $isActive, theme }) => $isActive && css`
    border: 1px solid ${theme.colors.tintTranslucent15};
    background: ${theme.colors.tintTranslucent5};
    box-shadow: none;
    p {
      color: ${theme.colors.tintBg};
      opacity: 0.8;
      &:last-child {
        opacity: 0.5;
      }
    }
  `}
  @media (hover: hover) {
    ${({ $isActive, theme, $isDisabled }) => !$isActive && !$isDisabled && css`
      &:hover {
        background: ${theme.colors.bgTertiary};
        border-color: ${theme.colors.borderTranslucent};
      }
    `}
  }
`
