import { z } from 'zod'
import Api from '..'
import { jobParser } from './jobs'
import { CandidateJobRejectionReason, candidateJobParser } from './candidate_jobs'
import { manualEmailMessageParser } from './sequences'
import { calendarEventParser } from './calendar_events'

export enum CandidateActivityType {
  EMAIL = 'EMAIL',
  NOTE = 'NOTE',
  CANDIDATE_JOB_CREATED = 'CANDIDATE_JOB_CREATED',
  CANDIDATE_JOB_STAGE_TRANSITION = 'CANDIDATE_JOB_STAGE_TRANSITION',
  EMAIL_BOUNCED = 'EMAIL_BOUNCED',
  MANUAL_EMAIL = 'MANUAL_EMAIL',
  CALENDAR_EVENT = 'CALENDAR_EVENT',
  PRIOR_CONTACT = 'PRIOR_CONTACT'
}

export enum EmailMessageType {
  RECEIVED = 'RECEIVED',
  SENT = 'SENT',
  DRAFT = 'DRAFT'
}

const candidateSequenceStepParser = z.object({
  id: z.string().uuid(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  opened: z.boolean(),
  openedAt: z.coerce.date().nullable(),
  openedCount: z.number()
})

const attachmentParser = z.object({
  contentType: z.string(),
  fileName: z.string(),
  id: z.string().uuid(),
  s3Key: z.string(),
  size: z.number()
})

export type Attachment = z.infer<typeof attachmentParser>

const mentionedTimeParser = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
  matchingText: z.string().nullish()
})

export type MentionedTime = z.infer<typeof mentionedTimeParser>

const emailMessageClassificationParser = z.object({
  id: z.string().uuid(),
  mentionedTimes: mentionedTimeParser.array().nullish()
})

export type EmailMessageClassification = z.infer<typeof emailMessageClassificationParser>

const emailMessageParser = z.object({
  id: z.string().uuid(),
  type: z.nativeEnum(EmailMessageType),
  createdAt: z.coerce.date(),
  candidateSequenceId: z.string().uuid().nullish(),
  subject: z.string().nullish(),
  bodyHtml: z.string().nullish(),
  bodyText: z.string().nullish(),
  strippedText: z.string().nullish(),
  to: z.array(z.string()).nullish(),
  from: z.string().nullish(),
  cc: z.array(z.string()).nullish(),
  bcc: z.array(z.string()).nullish(),
  userId: z.string().uuid(),
  emailAccountId: z.string().uuid(),
  manualEmailMessage: manualEmailMessageParser.nullish(),
  candidateSequenceStep: candidateSequenceStepParser.nullish(),
  attachments: z.array(attachmentParser).optional(),
  emailMessageClassifications: z.array(emailMessageClassificationParser).nullish(),
  sendingEmailAlias: z.string().nullable()
})

export type EmailMessage = z.infer<typeof emailMessageParser>

const candidateNoteParser = z.object({
  id: z.string().uuid(),
  body: z.string(),
  createdAt: z.coerce.date(),
  userId: z.string().uuid().optional()
})

export type CandidateNote = z.infer<typeof candidateNoteParser>

const candidateJobStageTransition = z.object({
  id: z.string().uuid(),
  fromStage: z.string(),
  toStage: z.string(),
  rejectionReason: z.nativeEnum(CandidateJobRejectionReason).nullish()
})

const candidateActivityParser = z.object({
  id: z.string(),
  type: z.nativeEnum(CandidateActivityType),
  date: z.coerce.date(),
  userId: z.string().uuid().optional(),
  emailMessage: emailMessageParser.optional(),
  candidateNote: candidateNoteParser.optional(),
  candidateJob: candidateJobParser.optional(),
  manualEmailMessage: manualEmailMessageParser.optional(),
  job: jobParser.optional(),
  candidateJobStageTransition: candidateJobStageTransition.optional(),
  calendarEvent: calendarEventParser.optional()
})

export type CandidateActivity = z.infer<typeof candidateActivityParser>

export async function fetchCandidateActivities (candidateId: string): Promise<CandidateActivity[]> {
  const { data } = await Api.get(`/candidates/${candidateId}/activities`)
  const { activities } = z.object({
    activities: z.array(candidateActivityParser)
  }).parse(data)

  return activities
}

export async function createCandidateNote (candidateId: string, body: string): Promise<CandidateNote> {
  const { data } = await Api.post(`/candidates/${candidateId}/notes`, null, { body })
  return candidateNoteParser.parse(data)
}

export async function updateCandidateNote (candidateId: string, noteId: string, body: string): Promise<CandidateNote> {
  const { data } = await Api.put(`/candidates/${candidateId}/notes/${noteId}`, null, { body })
  return candidateNoteParser.parse(data)
}
