import { isEqual, isNil } from 'lodash'
import { DEFAULT_CRITERIA } from 'src/components/blocks/refinement-filter-criteria/constants'
import { COMPANY_CRITERIA_KEYS, LOCATION_CRITERIA_KEYS, SCHOOL_CRITERIA_KEYS, CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { Criteria, LocationCriteria, MinMaxCriteria, SchoolCompanyCriteria, CustomRequirementCriteria, JobTitleCriteria } from 'src/libs/api/backend/candidate_search'

const sanitizeSchoolCompanyCriteria = (schoolsCompanies: SchoolCompanyCriteria[]): SchoolCompanyCriteria[] => {
  return schoolsCompanies.filter((schoolCompany) => !!schoolCompany.name)
}

const sanitizeLocationCriteria = (locations: LocationCriteria[]): LocationCriteria[] => {
  return locations.filter((location) => !!location.city || !!location.state || !!location.metro)
}

const sanitizeMinMaxCriteria = (minMax: MinMaxCriteria): MinMaxCriteria | undefined => {
  if (!isNil(minMax.min) && !isNil(minMax.max) && minMax.min > minMax.max) {
    return undefined
  }
  if (isNil(minMax.min) && isNil(minMax.max)) {
    return undefined
  }
  return minMax
}

export const isDuplicatedCriteria = <T>(item: T, itemsList: T[], key: keyof T): boolean => {
  return itemsList.some((listItem) => (listItem[key] as string).toLowerCase().trim() === (item[key] as string).toLowerCase().trim())
}

export const deduplicateJobTitles = (jobTitles: JobTitleCriteria[]): JobTitleCriteria[] => {
  return jobTitles.filter((jobTitle, index, self) => (
    index === self.findIndex((t) => t.name.toLowerCase().trim() === jobTitle.name.toLowerCase().trim())
  ))
}

export const filterOutCurrentJobTitle = (jobTitles: JobTitleCriteria[], currJobTitle: string): JobTitleCriteria[] => {
  // Current job title is always appended and not stored in the criteria
  return jobTitles.filter((jobTitle) => jobTitle.name.toLowerCase().trim() !== currJobTitle.toLowerCase().trim())
}

const sanitizeJobTitles = (jobTitles: JobTitleCriteria[]): JobTitleCriteria[] => {
  return deduplicateJobTitles(jobTitles.filter((jobTitle) => jobTitle.name.length > 0)).map((jobTitle) => {
    return {
      ...jobTitle,
      optional: false, // EVERY job title is required
      negative: false // We don't support this yet
    }
  })
}

export const sanitizeRefinementCriteria = (criteria: Criteria | undefined | null): Criteria => {
  if (isNil(criteria)) {
    return DEFAULT_CRITERIA
  }
  let sanitizedCriteria: Criteria = {}
  Object.keys(criteria).forEach((key: string) => {
    const criteriaKey = key as CriteriaKey
    const value = criteria[criteriaKey]
    if (criteriaKey === CriteriaKey.CURRENT_JOB_TITLES) {
      const sanitizedJobTitles = sanitizeJobTitles(value as JobTitleCriteria[])
      if (sanitizedJobTitles.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedJobTitles
        }
      }
    } else if (LOCATION_CRITERIA_KEYS.includes(criteriaKey)) {
      const sanitizedLocationCriteria = sanitizeLocationCriteria(value as LocationCriteria[])
      if (sanitizedLocationCriteria.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedLocationCriteria
        }
      }
    } else if (
      COMPANY_CRITERIA_KEYS.includes(criteriaKey) ||
      SCHOOL_CRITERIA_KEYS.includes(criteriaKey)
    ) {
      const sanitizedSchoolCompanyCriteria = sanitizeSchoolCompanyCriteria(value as SchoolCompanyCriteria[])
      if (sanitizedSchoolCompanyCriteria.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedSchoolCompanyCriteria
        }
      }
    } else if (criteriaKey === CriteriaKey.CUSTOM_REQUIREMENTS) {
      const sanitizedCustomRequirements = (value as CustomRequirementCriteria[]).filter((customRequirement) => customRequirement.requirement)
      if (sanitizedCustomRequirements.length > 0) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedCustomRequirements
        }
      }
    } else {
      const sanitizedMinMaxCriteria = sanitizeMinMaxCriteria(value as MinMaxCriteria)
      if (sanitizedMinMaxCriteria) {
        sanitizedCriteria = {
          ...sanitizedCriteria,
          [criteriaKey]: sanitizedMinMaxCriteria
        }
      }
    }
  })

  return {
    [CriteriaKey.CURRENT_JOB_TITLES]: [], // Backfill old criteria with empty current job titles
    ...sanitizedCriteria
  }
}

export const isDifferentCollection = <T>(newCollection: T[], oldCollection: T[] | undefined, keys: Array<keyof T>): boolean => {
  if (!oldCollection) return true
  if (newCollection.length !== oldCollection.length) return true

  return newCollection.some((newItem) => {
    return !oldCollection.some((oldItem) => {
      return keys.some((key) => {
        return isEqual(newItem[key], oldItem[key])
      })
    })
  })
}

export const diffUpdatedCriterias = (newCriteria: Criteria, oldCriteria: Criteria | undefined): CriteriaKey[] => {
  if (isNil(oldCriteria)) {
    return Object.keys(newCriteria) as CriteriaKey[]
  }
  const diffCriteriaKeys: CriteriaKey[] = []
  Object.keys(newCriteria).forEach((key) => {
    const newCriteriaKey = key as CriteriaKey
    if (!(newCriteriaKey in oldCriteria)) {
      // New field added by AI is highlighted regardless of its value
      diffCriteriaKeys.push(newCriteriaKey)
    } else {
      const oldCriteriaValue = oldCriteria[newCriteriaKey]
      const newCriteriaValue = newCriteria[newCriteriaKey]
      if (newCriteriaKey === CriteriaKey.CURRENT_JOB_TITLES) {
        if (isDifferentCollection(newCriteriaValue as JobTitleCriteria[], oldCriteriaValue as JobTitleCriteria[], ['name', 'negative', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else if (LOCATION_CRITERIA_KEYS.includes(newCriteriaKey)) {
        if (isDifferentCollection(newCriteriaValue as LocationCriteria[], oldCriteriaValue as LocationCriteria[], ['city', 'state', 'metro', 'negative', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else if (
        COMPANY_CRITERIA_KEYS.includes(newCriteriaKey) ||
        SCHOOL_CRITERIA_KEYS.includes(newCriteriaKey)
      ) {
        if (isDifferentCollection(newCriteriaValue as SchoolCompanyCriteria[], oldCriteriaValue as SchoolCompanyCriteria[], ['name', 'linkedin', 'domain', 'negative', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else if (newCriteriaKey === CriteriaKey.CUSTOM_REQUIREMENTS) {
        if (isDifferentCollection(newCriteriaValue as CustomRequirementCriteria[], oldCriteriaValue as CustomRequirementCriteria[], ['requirement', 'optional'])) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      } else {
        const { min, max, optional } = newCriteriaValue as MinMaxCriteria
        const { min: oldMin, max: oldMax, optional: oldOptional } = oldCriteriaValue as MinMaxCriteria
        if (min !== oldMin || max !== oldMax || optional !== oldOptional) {
          diffCriteriaKeys.push(newCriteriaKey)
        }
      }
    }
  })

  return diffCriteriaKeys
}
