import { Icons } from 'src/components/primitives/icon'
import { CriteriaKey, DEFAULT_CUSTOM_REQUIREMENT_CRITERIA, DEFAULT_MIN_MAX_CRITERIA } from 'src/libs/api/backend/candidate_search'
import type { CriteriaValue } from 'src/libs/api/backend/candidate_search'

export const FUNDING_ROUND_CRITERIA = new Map<number, string>([
  [1, 'Pre-Seed'],
  [2, 'Seed'],
  [3, 'Series A'],
  [4, 'Series B'],
  [5, 'Series C'],
  [6, 'Series D'],
  [7, 'Series E'],
  [8, 'Series F+'],
  [9, 'IPO']
])

export const DEGREE_OPTIONS = new Map<number, string>([
  [0, 'No degree'],
  [1, 'Bachelors'],
  [2, 'Masters'],
  [3, 'Doctorate']
])

export const DEFAULT_CRITERIA = {
  [CriteriaKey.CURRENT_JOB_TITLES]: []
}

export const CriteriaProperties = new Map<string, {
  label: string
  defaultValue: CriteriaValue
}>([
  [CriteriaKey.CURRENT_JOB_TITLES, {
    label: 'Candidate current job titles',
    defaultValue: []
  }],
  [CriteriaKey.LOCATIONS, {
    label: 'Candidate location',
    defaultValue: []
  }],
  [CriteriaKey.MONTHS_IN_CURRENT_PROFESSION, {
    label: 'Years in current profession',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.MONTHS_AT_CURRENT_COMPANY, {
    label: 'Years at current company',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_HC, {
    label: "Company's current headcount",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT, {
    label: "Company's headcount at time of hire",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND, {
    label: "Company's current funding stage",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND, {
    label: "Company's funding stage at time of hire",
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.AVERAGE_TENURE_IN_MONTHS, {
    label: 'Average tenure at companies',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.COMPANIES, {
    label: 'Companies worked at',
    defaultValue: []
  }],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT, {
    label: 'Headcount when hired at any company worked at',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND, {
    label: 'Funding stage when hired at any company worked at',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.SCHOOLS, {
    label: 'Universities attended',
    defaultValue: []
  }],
  [CriteriaKey.GRADUATION_YEAR, {
    label: 'Graduation year',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.DEGREE, {
    label: 'Highest degree',
    defaultValue: DEFAULT_MIN_MAX_CRITERIA
  }],
  [CriteriaKey.CUSTOM_REQUIREMENTS, {
    label: 'Custom',
    defaultValue: [DEFAULT_CUSTOM_REQUIREMENT_CRITERIA]
  }]
])

export const NewCriteriaList = [
  {
    id: 'candidate-details-title',
    title: 'CANDIDATE DETAILS',
    type: 'label'
  },
  {
    id: CriteriaKey.LOCATIONS,
    icon: Icons.mapPin
  },
  {
    id: CriteriaKey.MONTHS_IN_CURRENT_PROFESSION,
    icon: Icons.briefcase
  },
  {
    id: 'candidate-details-separator',
    title: 'candidate-details-separator',
    type: 'separator'
  },
  {
    id: 'candidate-current-position',
    title: "CANDIDATE'S CURRENT POSITION",
    type: 'label'
  },
  {
    id: CriteriaKey.MONTHS_AT_CURRENT_COMPANY,
    icon: Icons.calendarClock
  },
  {
    id: CriteriaKey.CURRENT_COMPANY_HC,
    icon: Icons.users
  },
  {
    id: CriteriaKey.CURRENT_COMPANY_JOINED_AT_HEADCOUNT,
    icon: Icons.users
  },
  {
    id: CriteriaKey.CURRENT_COMPANY_CURRENT_FUNDING_ROUND,
    icon: Icons.rocket
  },
  {
    id: CriteriaKey.CURRENT_COMPANY_JOINED_AT_FUNDING_ROUND,
    icon: Icons.rocket
  },
  {
    id: 'candidate-current-position-separator',
    title: 'candidate-current-position-separator',
    type: 'separator'
  },
  {
    id: 'candidate-overall-experience',
    title: "CANDIDATE'S OVERALL EXPERIENCE",
    type: 'label'
  },
  {
    id: CriteriaKey.AVERAGE_TENURE_IN_MONTHS,
    icon: Icons.calendarDays
  },
  {
    id: CriteriaKey.COMPANIES,
    icon: Icons.building2
  },
  {
    id: CriteriaKey.ANY_COMPANY_JOINED_AT_HEADCOUNT,
    icon: Icons.users
  },
  {
    id: CriteriaKey.ANY_COMPANY_JOINED_AT_FUNDING_ROUND,
    icon: Icons.rocket
  },
  {
    id: 'candidate-overall-separator',
    title: 'candidate-overall-separator',
    type: 'separator'
  },
  {
    id: 'education-title',
    title: 'EDUCATION',
    type: 'label'
  },
  {
    id: CriteriaKey.SCHOOLS,
    icon: Icons.book
  },
  {
    id: CriteriaKey.GRADUATION_YEAR,
    icon: Icons.graduationCap
  },
  {
    id: CriteriaKey.DEGREE,
    icon: Icons.scroll
  }
]
