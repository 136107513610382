import { Flex } from 'src/components/primitives/flex'
import { Button } from 'src/components/primitives/button'
import { Spacer } from 'src/components/primitives/spacer'
import { BenefitsList } from 'src/components/blocks/benefits-list'
import { When } from 'src/components/blocks/when'
import { CONNECTED_ACCOUNTS_BENEFITS } from 'src/libs/data'
import CONFIG from 'src/config'
import { Caption } from 'src/components/primitives/typography'
import type { Color } from 'src/styles/theme/types'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { useSession } from 'src/hooks/use-session'
import type { EmailAccount } from 'src/libs/api/backend/session'
import * as S from './connected-email-accounts.styled'
import { useLocation } from 'react-router'
import LoginError from '../login-error'
import { isNil } from 'lodash'
import { useDeleteEmailAccount } from 'src/hooks/mutations/use-delete-email-account'
import { StandaloneSwitch } from 'src/components/blocks/standalone-switch'
import { useSetEmailAccountTeamSendAsPermission, useSetPrimaryOrgEmailAccount } from 'src/hooks/mutations/use-set-email-account-team-send-as-permission'
import { useDialog } from 'src/hooks/use-dialog'
import { useCallback } from 'react'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { Dropdown } from '../../primitives/dropdown'
import { useNotification } from '../../../hooks/use-notification'

interface ConnectedAccountProps {
  emailAccount: EmailAccount
  onRemove: () => void
}

const AccountStatus = ({ emailAccount, onRemove }: ConnectedAccountProps): JSX.Element => {
  const getStatus = (): { color: Color, text: string } => {
    switch (emailAccount.hasAccessToken) {
      case true: {
        return {
          color: 'positiveBg' as Color,
          text: 'Connected'
        }
      }
      default: {
        return {
          color: 'negativeBg' as Color,
          text: 'Disconnected'
        }
      }
    }
  }

  const status = getStatus()

  const { refetchSession } = useSession()
  const { notify } = useNotification()

  const { setEmailAccountTeamSendAsPermission } = useSetEmailAccountTeamSendAsPermission()
  const { mutate: setPrimaryOrgEmailAccount } = useSetPrimaryOrgEmailAccount({
    onSuccess: () => {
      refetchSession()
    },
    onError: () => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred while setting ${emailAccount.email} as default. Please try again later.`
      })
    }
  })

  const reconnect = useCallback((): void => {
    if (!isNil(emailAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(emailAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          void queryClient.invalidateQueries({
            queryKey: [queryKeys.jobStats]
          })
          clearInterval(timer)
        }
      }, 500)
    }
  }, [emailAccount])

  return (
    <S.ConnectedAccount>
      <S.ConnectedAccountTopHalf>
        <S.EmailAccountGrid>
          <BrandIcon name={emailAccount.type} />
          <Flex $gap={16} $align="center">
            <Caption size="SM">{emailAccount.email}</Caption>
            {emailAccount.isPrimary && <Caption size="SM" $color='fgTertiary'>Default</Caption>}
          </Flex>

          <Flex $gap={12} $align="center" $justify="flex-end">
            <Flex $gap={8} $align="center" $justify="flex-end">
              <S.StatusIndicator $color={status.color} />
              <Caption size="SM">{status.text}</Caption>
            </Flex>

            <Flex $flex="1">
              <Dropdown
                trigger={
                  <Button
                    nested
                    leadingIcon='more-vertical'
                    $variant="ghost"
                    $colorTheme='muted'
                    ariaLabel='More options'
                    $height={24}
                    $width={24}
                    $fontSize={12}
                  />
                }
                items={[
                  {
                    id: '0',
                    title: emailAccount.isPrimary ? 'Current default' : 'Set as default',
                    isDisabled: emailAccount.isPrimary,
                    onSelect: () => {
                      setPrimaryOrgEmailAccount({
                        emailAccountId: emailAccount.emailAccountId
                      })
                    }
                  },
                  emailAccount.hasAccessToken
                    ? {
                        variant: 'negative',
                        id: '1',
                        title: 'Remove account',
                        onSelect: onRemove
                      }
                    : {
                        variant: 'tint',
                        id: '1',
                        title: 'Reconnect',
                        onSelect: reconnect
                      }
                ]}
                menuPosition='end'
              />
            </Flex>
          </Flex>
          {emailAccount.emailAliases.length > 0
            ? emailAccount.emailAliases.map(alias => {
              return (
                <>
                  <S.EmailAliasRowSeparator />
                  <S.EmailAliasRow key={alias.sendAsEmail}>
                    <Flex $gap={16} $align="center">
                      <Caption size="SM">{alias.sendAsEmail}</Caption>
                      <Caption size="SM" $color='fgTertiary'>Alias</Caption>
                    </Flex>
                  </S.EmailAliasRow>
                </>
              )
            })
            : null}
        </S.EmailAccountGrid>
      </S.ConnectedAccountTopHalf>
      <S.ConnectedAccountBottomHalf>
        <Flex $direction="row">
          <StandaloneSwitch name="HI" label="Allow team to send emails from this account" onChange={(switchState) => {
            setEmailAccountTeamSendAsPermission({ emailAccountId: emailAccount.emailAccountId, teamSendAsPermission: switchState })
          }}
            defaultChecked={emailAccount.teamSendAsPermission ?? false}
            $direction="row-reverse" $variant="ghost"
          />
        </Flex>
      </S.ConnectedAccountBottomHalf>
    </S.ConnectedAccount>
  )
}

export const ConnectedEmailAccounts = (): JSX.Element => {
  const { openAlert } = useDialog()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const error = params.get('error')

  const redirectPath = window.location.href
  const queryString = `?redirect=${redirectPath}&errorRedirect=${redirectPath}`
  const googleUrl = `${CONFIG.API_DOMAIN}/auth/google/consent${queryString}`
  const microsoftUrl = `${CONFIG.API_DOMAIN}/auth/microsoft/consent${queryString}`

  const location = useLocation()
  const isOnboarding = location.pathname.toLowerCase().includes('onboarding')

  const { emailAccountAccessTokens } = useSession()
  const hasConnectedAccounts = !isNil(emailAccountAccessTokens) && emailAccountAccessTokens.some((token) => token.hasAccessToken)

  const { deleteEmailAccount } = useDeleteEmailAccount()

  const handleDeleteEmailAccount = async (emailAccountId: string): Promise<void> => {
    deleteEmailAccount({ emailAccountId })
  }

  return (
    <>
      {error && (
        <>
          <LoginError error={error} />
          <Spacer $size={24} />
        </>
      )}
      <Flex $align="center" $gap={16}>
        <Button
          $variant="raised"
          $colorTheme="normal"
          $align="center"
          leadingIcon="google"
          $width="full"
          $height={40}
          href={googleUrl}
          hrefTarget='_self'
        >
          Connect New Google Account
        </Button>
        <Button
          $variant="raised"
          $colorTheme="normal"
          $align="center"
          leadingIcon="microsoft"
          $width="full"
          $height={40}
          hrefTarget='_self'
          href={microsoftUrl}
        >
          Connect New Microsoft Account
        </Button>
      </Flex>
      <Spacer $size={40} />
      <When condition={isOnboarding && !hasConnectedAccounts}>
        <BenefitsList benefits={CONNECTED_ACCOUNTS_BENEFITS} />
      </When>

      <When condition={!isOnboarding || hasConnectedAccounts}>
        <Caption size='SM' $color='fgSecondary'>Connected Accounts</Caption>
        <Spacer $size={12} />
        <Flex $direction="column" $gap={8}>
          {emailAccountAccessTokens?.map((emailAccount) => (
            <AccountStatus
              key={emailAccount.emailAccountId}
              emailAccount={emailAccount}
              onRemove={() => {
                openAlert({
                  message: 'Are you sure you want to disconnect?',
                  description: `This will disconnect ${emailAccount.email} from Pin which will disable syncing & sending emails and calendar events.`,
                  onConfirm: () => {
                    void handleDeleteEmailAccount(emailAccount.emailAccountId)
                  }
                })
              }}
            />
          ))}
        </Flex>
      </When>
    </>
  )
}
