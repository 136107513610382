import { useMemo } from 'react'
import { isNil } from 'lodash'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { CandidateJobSequenceFilter } from 'src/libs/api/backend/candidate_jobs'
import { useQueryParams } from './use-query-params'

export const useFilteredCandidateJobs = (): {
  filteredCandidateJobs: (candidates: CandidateJobExpanded[] | undefined) => CandidateJobExpanded[] | undefined
} => {
  const { getParam } = useQueryParams()
  const candidateJobSequenceFilterParams = getParam('candidateJobsFilter')

  const filteredCandidateJobs = useMemo(() => {
    return (candidates: CandidateJobExpanded[] | undefined): CandidateJobExpanded[] | undefined => {
      if (!candidates || !candidateJobSequenceFilterParams) return candidates

      return candidates.filter(candidate => {
        switch (candidateJobSequenceFilterParams) {
          case CandidateJobSequenceFilter.ACTIVE:
            return candidate.candidateSequence?.currentSequence
          case CandidateJobSequenceFilter.REPLIED:
            return candidate.candidateSequence?.responded ?? !isNil(candidate.candidateSequence?.respondedAt)
          case CandidateJobSequenceFilter.OPENED:
            return candidate.candidateSequence?.opened ?? !isNil(candidate.candidateSequence?.openedAt)
          // case CandidateJobSequenceFilter.SCHEDULED:
          //   return candidate.candidateSequence?.latestStep !== undefined && candidate.candidateSequence.latestStep > 0
          case CandidateJobSequenceFilter.CONTACTED:
            return candidate.candidateSequence?.latestStep !== undefined && candidate.candidateSequence.latestStep > 0
          default:
            return true
        }
      })
    }
  }, [candidateJobSequenceFilterParams])

  return { filteredCandidateJobs }
}
