import { Button } from 'src/components/primitives/button'
import * as S from './header.styled'
import { Flex } from 'src/components/primitives/flex'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'
import { useSession } from 'src/hooks/queries/use-session'
import { Caption, Span } from 'src/components/primitives/typography'
import { Avatar } from 'src/components/primitives/avatar'
import { addWeeks, format, subWeeks } from 'date-fns'
import { useQueryParams } from 'src/hooks/use-query-params'
import { forwardRef, useEffect, useMemo } from 'react'
import { When } from '../when'
import { Badge } from 'src/components/primitives/badge'
import { isNil } from 'lodash'
import { getEmailAccountAuthUrl } from 'src/libs/auth-urls'
import { invalidateEmailAccounts } from 'src/hooks/invalidate-email-accounts'
import { Dropdown } from 'src/components/primitives/dropdown'
import type { MenuItemProps } from 'src/components/primitives/dropdown'
import { useOrgUsersQuery } from 'src/hooks/queries/use-org-users'
import { Icon } from 'src/components/primitives/icon'
import type { EmailAccount, OrgUser } from 'src/libs/api/backend/users'
import { useGetCalendarEvents } from 'src/hooks/queries/use-calendar-events'
import type { CalendarEvent } from 'src/libs/api/backend/calendar_events'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'

interface Attendee {
  address: string
  name: string
}

interface HeaderProps {
  selectedEvent?: CalendarEvent | null
  renderedWeek: Date
  onGoToNextWeek: () => void
  onGoToPrevWeek: () => void
  onGoToToday: () => void
  selectedEmailAccount: EmailAccount | null
  onSelectEmailAccount: (emailAccount: EmailAccount) => void
  onAttendeesChange?: (attendees: Attendee[]) => void
  candidateJob?: CandidateJobExpanded
}

interface GetEmailAccountsArgs {
  orgUsers?: OrgUser[]
  onSelectAction: 'onSelectEmailAccount' | 'onAttendeesChange'
}

export const Header = forwardRef<HTMLDivElement, HeaderProps>(({
  renderedWeek,
  onGoToNextWeek,
  onGoToPrevWeek,
  onGoToToday,
  selectedEmailAccount,
  onSelectEmailAccount,
  selectedEvent,
  candidateJob,
  onAttendeesChange
}, forwardedRef): JSX.Element => {
  const { closeDialog } = useDialog()
  const { data: sessionData } = useSession()
  const currentSessionEmails = sessionData?.emailAccountAccessTokens?.map((token) => token.email)
  const { setParam, resetParam } = useQueryParams()
  const { data: orgUsers } = useOrgUsersQuery()
  const selectedOrgUser = useMemo(() => {
    return orgUsers?.find((user) =>
      user.emailAccounts.some((account) => account.id === selectedEmailAccount?.id)
    )
  }, [orgUsers, selectedEmailAccount])
  const selectedCalendarIsSessionUser = currentSessionEmails?.includes(
    selectedEmailAccount?.email ?? ''
  )

  const requestedCalendarEmail = selectedEmailAccount?.email
  const { data: calendarEvents } = useGetCalendarEvents({
    requestedCalendars: requestedCalendarEmail ? [requestedCalendarEmail] : undefined
  })

  // We need to get profile photos etc for every attendee from our org
  const preparedOrgMemberAttendees = useMemo((): OrgUser[] => {
    const attendees = selectedEvent?.attendees as unknown as Attendee[] || []
    const allAttendeeEmails = new Set(attendees.map((attendee) => attendee.address))
    if (candidateJob?.candidate?.emails?.[0]) {
      allAttendeeEmails.delete(candidateJob.candidate.emails[0])
    }
    if (selectedEmailAccount?.email) {
      allAttendeeEmails.delete(selectedEmailAccount.email)
    }
    const filteredOrgUsers = orgUsers?.filter((user) => 
      user.emailAccounts.some((account) => allAttendeeEmails.has(account.email))
    ) ?? []

    return filteredOrgUsers.map((user) => ({
      ...user,
      id: user.id,
      name: user.name,
      email: user.email,
      profilePhotoUrl: user.profilePhotoUrl,
      active: user.active,
      emailAccounts: user.emailAccounts
    }))
  }, [orgUsers, selectedEvent?.attendees, candidateJob, selectedEmailAccount])

  useEffect(() => {
    if (renderedWeek) {
      const startDateTime = format(subWeeks(renderedWeek, 2), "yyyy-MM-dd'T'HH:mm:ssXXX")
      const endDateTime = format(addWeeks(renderedWeek, 4), "yyyy-MM-dd'T'HH:mm:ssXXX")
      setParam('startDateTime', startDateTime)
      setParam('endDateTime', endDateTime)
    }
  }, [renderedWeek])

  const reconnect = (): void => {
    if (!isNil(selectedEmailAccount)) {
      const redirectUrl = `${window.location.origin}/login/redirect/close`
      const authUrl = getEmailAccountAuthUrl(selectedEmailAccount, redirectUrl)
      const loginWindow = window.open(authUrl, '_blank', 'popup=1,height=600,width=600')
      const timer = setInterval(() => {
        if (loginWindow?.closed) {
          void invalidateEmailAccounts()
          clearInterval(timer)
        }
      }, 500)
    }
  }

  const getEmailAccountsForDropdown = ({ orgUsers, onSelectAction }: GetEmailAccountsArgs): MenuItemProps[] => {
    return (
      orgUsers?.flatMap((user) =>
        user.emailAccounts.map((emailAccount) => ({
          title: `${user.name} (${emailAccount.email})`,
          value: emailAccount.email,
          icon: (
            <Avatar
              photoUrl={user.profilePhotoUrl}
              initials={user.name}
              $shape="circle"
              $size={16}
            />
          ),
          trailingIcon: !emailAccount.hasAccessToken ? <Icon name="alert-triangle" size={12} color="negativeBg" /> : undefined,
          isDisabled: !user.active,
          onSelect: () => {
            if (onSelectAction === 'onSelectEmailAccount') {
              onSelectEmailAccount(emailAccount)
            } else {
              const newAttendee: Attendee = {
                address: emailAccount.email,
                name: user.name
              }
              const updatedAttendees: Attendee[] = selectedEvent?.attendees ? [...(selectedEvent.attendees as Attendee[]), newAttendee] : [newAttendee]
              onAttendeesChange?.(updatedAttendees)
            }
          }
        }))
      ) ?? []
    )
  }

  const prepareEmailAccountsForDropdown = (emailAccounts: MenuItemProps[]): MenuItemProps[] => {
    return emailAccounts
      .filter((emailAccount) => !emailAccount.isDisabled)
      .sort((a, b) => (a.title as string).localeCompare(b.title as string))
      .sort((a, b) => {
        if (currentSessionEmails) {
          return currentSessionEmails.includes(a.value ?? '') ? -1 : currentSessionEmails.includes(b.value ?? '') ? 1 : 0
        }
        return 0
      })
  }

  const selectableTeamMembers = useMemo(() => {
    const emailAccounts = getEmailAccountsForDropdown({ orgUsers, onSelectAction: 'onSelectEmailAccount' })
    return prepareEmailAccountsForDropdown(emailAccounts)
  }, [orgUsers, onSelectEmailAccount])

  const selectableAttendeesFromOrg = useMemo(() => {
    const attendees = selectedEvent?.attendees as Attendee[] || []
    const attendeeEmails = new Set(attendees.map((attendee) => attendee.address))

    const emailAccounts = getEmailAccountsForDropdown({ orgUsers, onSelectAction: 'onAttendeesChange' })
    const filteredEmailAccounts = emailAccounts.filter(account => account.value && !attendeeEmails.has(account.value))
    return prepareEmailAccountsForDropdown(filteredEmailAccounts)
  }, [orgUsers, onSelectEmailAccount, selectedEvent?.attendees])

  return (
    <S.Header ref={forwardedRef}>
      <S.Titlebar>
        <Caption size="XS">Schedule meeting</Caption>
        <Button
          $variant="ghost"
          $colorTheme="muted"
          $height={24}
          $width={24}
          leadingIcon="x"
          ariaLabel="Close calendar"
          onClick={() => {
            resetParam('startDateTime')
            resetParam('endDateTime')
            closeDialog(DialogId.CALENDAR)
          }}
        />
      </S.Titlebar>
      <S.Addressbar>
        <Span size="XS" $color="fgSecondary">
          From
        </Span>
        <Flex $align="center">
          <Dropdown
            trigger={
              <Button
                nested
                leadingIcon={
                  <Avatar
                    photoUrl={sessionData?.user?.profilePhoto}
                    initials={sessionData?.user?.name}
                    $size={16}
                    $shape="circle"
                  />
                }
                trailingIcon="chevrons-up-down"
                $variant="outline"
                $colorTheme="muted"
                $fontSize={12}
                $height={24}
              >
                {selectedOrgUser?.name}
              </Button>
            }
            $menuWidth="full"
            selectedValue={selectedEmailAccount?.email}
            items={selectableTeamMembers}
          />
        </Flex>
      </S.Addressbar>
      <S.Addressbar>
        <Span size="XS" $color="fgSecondary">
          Invite
        </Span>
        <Flex $align="center" $gap={8}>
          <Button
            leadingIcon={
              <Avatar
                photoUrl={candidateJob?.candidate?.profilePhotoUrl}
                initials={candidateJob?.candidate?.name}
                $size={16}
                $shape="circle"
              />
            }
            $variant="flat"
            $colorTheme="normal"
            disabled
            $fontSize={12}
            $height={24}
          >
            {candidateJob?.candidate?.name}
          </Button>
          {preparedOrgMemberAttendees?.map((attendee) => (
            <Button
              leadingIcon={
                <Avatar
                  photoUrl={attendee?.profilePhotoUrl}
                  initials={attendee?.name}
                  $size={16}
                  $shape="circle"
                />
              }
              $variant="flat"
              $colorTheme="normal"
              trailingIcon="x"
              $fontSize={12}
              $height={24}
              onClick={() => {
                const updatedAttendees = (selectedEvent?.attendees as Attendee[]).filter(a => a.address !== attendee.emailAccounts?.[0]?.email)
                onAttendeesChange?.(updatedAttendees)
              }}
            >
              {attendee?.name}
            </Button>
          ))}
          <Dropdown
            trigger={
              <Button
                nested
                leadingIcon="plus"
                $variant="flat"
                $colorTheme={!selectedEvent?.isPinEvent ? 'muted' : 'tint'}
                $fontSize={12}
                $height={24}
                $width={24}
                disabled={!selectedEvent?.isPinEvent}
                tooltip={{
                  text: !selectedEvent?.isPinEvent ? 'Select a time on the calendar to invite team members' : undefined
                }}
              />
            }
            $menuWidth="full"
            items={selectableAttendeesFromOrg}
            disabled={!selectedEvent?.isPinEvent}
          />
        </Flex>
      </S.Addressbar>
      <S.CalendarNavbar>
        <Caption size="XS" $whiteSpace="nowrap">
          {(selectedEvent?.title?.length ?? 0) >= 1 ? selectedEvent?.title : 'New event'}
        </Caption>
        <Flex $align="center" $justify="flex-end" $gap={24}>
          <When condition={!selectedEmailAccount?.hasAccessToken}>
            <Flex $align="center" $justify="flex-end" $gap={4}>
              <Badge
                $variant="negativeLight"
                leadingIcon="alert-triangle"
                $transform="none"
                $fontSize={12}
                $height={24}
                $padding="casual"
              >
                Email account not connected
              </Badge>
              <Button
                $height={24}
                $variant="fill"
                $colorTheme="tint"
                leadingIcon="refresh-cw"
                $fontSize={12}
                ariaLabel="Reconnect email account"
                disabled={!selectedCalendarIsSessionUser}
                onClick={reconnect}
              >
                Reconnect to schedule events
              </Button>
            </Flex>
          </When>
          <When condition={calendarEvents?.accessible != null && !calendarEvents?.accessible}>
            <Badge
              $variant="negativeLight"
              leadingIcon="alert-triangle"
              $transform="none"
              $fontSize={12}
              $height={24}
              $padding="casual"
            >
              You don&rsquo;t have permission to view this calendar
            </Badge>
          </When>
          <Caption $whiteSpace="nowrap" size="XS">
            {format(renderedWeek, 'MMMM yyyy')}
          </Caption>
          <Flex $width="auto" $align="center" $justify="flex-end" $gap={4}>
            <Button
              $variant="raised"
              $colorTheme="normal"
              $height={24}
              $fontSize={12}
              leadingIcon="chevron-left"
              onClick={onGoToPrevWeek}
            />
            <Button
              $variant="raised"
              $colorTheme="normal"
              $height={24}
              $fontSize={12}
              onClick={onGoToToday}
            >
              Today
            </Button>
            <Button
              $variant="raised"
              $colorTheme="normal"
              $height={24}
              $fontSize={12}
              leadingIcon="chevron-right"
              onClick={onGoToNextWeek}
            />
          </Flex>
        </Flex>
      </S.CalendarNavbar>
    </S.Header>
  )
})

Header.displayName = 'Header'
