import styled from 'styled-components'

export const CriteriaExpandedHeader = styled.div`
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(5, 24px);
  grid-column-gap: 1.5rem;
  /* padding: 0 2.5rem; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding-left: 1rem;
  height: 100px;
`

export const RotatedCaption = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  padding: 5px 10px;
  white-space: nowrap;
  transform: translate(0, 80%);
  /* display: flex; */
`

export const RotatedCaptionInner = styled.div`
  transform: rotate(-45deg);
  transform-origin: left center;
`
