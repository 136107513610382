import { CandidatesPageHeader } from 'src/components/blocks/candidates-page-header'
import { CandidatesAllTable } from 'src/components/tables/candidates-all-table/candidates-all-table'
import { CandidatesTablePagesContentInner, CandidatesTablePagesHeader } from './candidates.styled'
import { useDialog } from 'src/hooks/use-dialog'
import { DialogId } from 'src/contexts/dialogs'
import { When } from 'src/components/blocks/when'
import { EmptyStateArchivedJob } from 'src/components/blocks/empty-state-archived-job'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { SEO } from '../../../components/primitives/seo'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Button } from 'src/components/primitives/button'
import { useExportCandidatesToCsv } from 'src/hooks/use-export-candidates-to-csv'
// import { CandidatesInSequenceStats } from 'src/components/blocks/candidates-in-sequence-stats'

interface Props {
  heading?: string
}

const JobCandidatesOverviewPage = ({ heading = 'All Candidates' }: Props): JSX.Element => {
  const { openDialog } = useDialog()
  const { data: job } = useJobQuery()
  const { exportCandidates } = useExportCandidatesToCsv()

  return (
    <>
      <SEO title="All Candidates" />

      <CandidatesTablePagesContentInner>
        <CandidatesTablePagesHeader>
          <CandidatesPageHeader
            heading={heading}
            actions={[
              {
                children: 'Add Candidate',
                icon: 'plus',
                // $variant: 'outline',
                $colorTheme: 'tint',
                onClick: () => {
                  openDialog(DialogId.ADD_CANDIDATE)
                }
              }
            ]}
            context={
              <Dropdown
                trigger={
                  <Button
                    nested
                    leadingIcon="more-vertical"
                    $height={24}
                    $width={24}
                    $fontSize={12}
                    $variant="ghost"
                    $colorTheme="muted"
                  />
                }
              items={
                [
                  {
                    id: 'exportToCsv',
                    title: 'Export candidates to CSV',
                    variant: 'neutral',
                    icon: 'file-down',
                    onSelect: () => { void exportCandidates() }
                  }
                ]
              }
              menuPosition="end"
              size="small"
            />
            }
          />
        </CandidatesTablePagesHeader>
        {/* <CandidatesInSequenceStats isFilterable={false} /> */}
        <When condition={Boolean(job?.deleted)}>
          <EmptyStateArchivedJob />
        </When>
        <When condition={!job?.deleted}>
          <CandidatesAllTable job={job}/>
        </When>
      </CandidatesTablePagesContentInner>
    </>
  )
}

export default JobCandidatesOverviewPage
