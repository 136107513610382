import { useState } from 'react'
import { Button, Flex } from 'src/components/primitives'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Caption } from 'src/components/primitives/typography'
import * as S from './index.styled'
import { Tooltip } from 'src/components/primitives/tooltip'
import { Avatar } from 'src/components/primitives/avatar'

type CriteriaPillType = 'location' | 'company' | 'school'

const PillActions = ({
  onUpdate,
  optional,
  negative,
  type
}: {
  onUpdate: (key: string, value: boolean) => void
  optional: boolean | undefined
  negative: boolean | undefined
  type?: CriteriaPillType
}): JSX.Element => {
  const newOptional = !optional
  const newNegative = !negative
  let negativeLabel = ''
  switch (type) {
    case 'company':
      negativeLabel = newNegative ? 'Change to have not worked at' : 'Change to have worked at'
      break
    case 'school':
      negativeLabel = newNegative ? 'Change to have not attended' : 'Change to have attended'
      break
    case 'location':
      negativeLabel = newNegative ? 'Change to not located in' : 'Change to located in'
      break
    default:
      break
  }
  return (
    <S.CriteriaPillActions>
      <Tooltip
        position='top'
        trigger={
          <span>
            <Button
              $variant='ghost'
              $colorTheme={optional ? 'normal' : 'muted'}
              leadingIcon={Icons.pin}
              $height={16}
              $width={16}
              $fontSize={12}
              onClick={() => {
                onUpdate('optional', newOptional)
              }}
            />
          </span>
        }
      >
        {optional ? 'Change to must have' : 'Change to preferred'}
      </Tooltip>
      <Tooltip
        position='top'
        disabled={false}
        trigger={
          <span>
            <Button
              $variant='ghost'
              $colorTheme={newNegative ? 'negative' : 'muted'}
              leadingIcon={Icons.ban}
              $height={16}
              $width={16}
              $fontSize={12}
              onClick={() => {
                onUpdate('negative', newNegative)
              }}
            />
          </span>

        }
      >
        {negativeLabel}
      </Tooltip>
    </S.CriteriaPillActions>
  )
}

interface CriteriaPillProps {
  value: {
    label?: string
    icon?: string
    optional?: boolean
    negative?: boolean
    required?: boolean
  }
  type?: CriteriaPillType
  onRemove: () => void
  onUpdate?: (key: string, value: boolean) => void
  useOptional?: boolean
  useNegative?: boolean
  tooltip?: string
}

export const CriteriaPill = ({
  value,
  onUpdate,
  onRemove,
  type,
  useOptional = true,
  useNegative = true,
  tooltip
}: CriteriaPillProps): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Tooltip
      position='top'
      disabled={!tooltip}
      trigger={
        <S.CriteriaPill
          onMouseEnter={() => {
            setIsHovered(true)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
          }}
        >
          <Flex $align='center' $gap={4} $width='auto' $flex='1 1 auto'>
            {!value.optional && useOptional && <Icon name={Icons.pin} size={12} color='fgPrimary' />}
            {value.negative && useNegative && <Icon name={Icons.ban} size={12} color='negativeBg' />}
            {value.required && <Icon name={Icons.lock} size={12} color='fgPrimary' />}
            {value.icon &&
              <Avatar
                $size={16}
                $innerPadding={0}
                $border={false}
                $type="logo"
                initials={value.label}
                company={{
                  name: value.label,
                  logoUrl: value.icon
                }}
              />
            }
            <Caption size='XS' $fontWeight={400} $lineHeight={1}>{value.label}</Caption>
          </Flex>
          {!value.required && <Button
            $variant='ghost'
            $colorTheme='muted'
            leadingIcon={Icons.x}
            $height={16}
            $width={16}
            $fontSize={12}
            $align='flex-end'
            onClick={() => {
              onRemove()
            }}
          />}
          {isHovered && onUpdate &&
            <PillActions
              onUpdate={onUpdate}
              optional={value.optional}
              negative={value.negative}
              type={type}
            />
          }
        </S.CriteriaPill>
      }
    >
      {tooltip}
    </Tooltip>
  )
}
