import { useState, useMemo } from 'react'
import { CandidatesTable } from '../candidates-table'
import { RejectButton } from '../candidate-table-actions'
import { COLUMN } from '../candidates-table/table-builder'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { Caption } from 'src/components/primitives/typography'
import * as S from './candidates-sourced-table.styled'
import { isNil } from 'lodash'

interface CandidatesSourcedTableProps {
  isLoading: boolean
  candidateJobs: CandidateJobExpanded[]
  visibleColumns: COLUMN[]
  pageHeaderHeight?: number
}

export const CandidatesSourcedTable = ({
  candidateJobs,
  isLoading,
  visibleColumns,
  pageHeaderHeight
}: CandidatesSourcedTableProps): JSX.Element => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const [tableIsExpanded, setTableIsExpanded] = useState(false)

  const criteriaMatches = candidateJobs?.[0]?.sourcingScores?.criteria_matches_array

  const criteriaExpandedColumnWidth = useMemo(() => {
    return (((criteriaMatches?.length ?? 0) + 1) * 56)
  }, [criteriaMatches])

  if (isNil(candidateJobs) || candidateJobs.length === 0) {
    return <></>
  }

  return (
    <div
      data-component="candidates-sourced-table"
      style={{ width: tableIsExpanded ? `calc(100% + ${criteriaExpandedColumnWidth}px)` : '100%', height: '100%' }}
    >
      <CandidatesTable
        pageHeaderHeight={pageHeaderHeight}
        visibleColumns={visibleColumns}
        isLoading={isLoading}
        candidateJobs={candidateJobs}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        emptyState={
          <></>
        }
        selectedRowsActions={
          <>
            <RejectButton
              selectedRows={rowSelection}
              onResetSelectedRows={() => {
                setRowSelection({})
              }}
            />
          </>
        }
        expandableColumns={
          criteriaMatches
            ? [
                {
                  columnId: COLUMN.CRITERIA,
                  expandableColumnId: COLUMN.CRITERIA_EXPANDED,
                  width: criteriaExpandedColumnWidth,
                  expandableColumnHeader: (
                    <>
                      {criteriaMatches.every(criteria => criteria.shortName) && (
                        <S.CriteriaExpandedHeader>
                          {criteriaMatches.map((criteria, index) => (
                            <S.RotatedCaption key={index}>
                              <S.RotatedCaptionInner>
                                <Caption size="XS" $whiteSpace="nowrap">
                                  {criteria.shortName}
                                </Caption>
                              </S.RotatedCaptionInner>
                            </S.RotatedCaption>
                          ))}
                        </S.CriteriaExpandedHeader>
                      )}
                    </>
                  )
                }
              ]
            : []
        }
        onToggleExpandableColumn={(columnId) => {
          setTableIsExpanded(columnId === 'criteriaExpanded')
        }}
      />
    </div>
  )
}
