import { atom } from 'jotai'
import type { Criteria, CriteriaKey } from 'src/libs/api/backend/candidate_search'

export const cloneRefinementCriteriaAtom = atom<Criteria | undefined>(undefined)

export const updatedCriteriasAtom = atom<CriteriaKey[]>([])

export const removeUpdatedCriteriaAtom = atom(null, (get, set, criteriaKey: CriteriaKey) => {
  const updatedCriteria = get(updatedCriteriasAtom)
  set(updatedCriteriasAtom, updatedCriteria.filter((key) => key !== criteriaKey))
})
