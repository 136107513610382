import { useMutation } from '@tanstack/react-query'
import { useNotification } from 'src/hooks/use-notification'
import { generateSearchCriteriaApi } from 'src/libs/api/backend/candidate_search'
import type { Criteria, GenerateSearchCriteriaParams } from 'src/libs/api/backend/candidate_search'

interface Args extends GenerateSearchCriteriaParams {
  onSuccess?: (data: Criteria) => void
  onError?: () => void
}

interface Res {
  generateSearchCriteria: (args: Args) => void
}

export const useGenerateSearchCriteria = (): Res => {
  const { notify } = useNotification()

  const mutation = useMutation({
    mutationFn: async ({ onSuccess: _onSuccess, onError: _onError, ...args }: Args) => await generateSearchCriteriaApi(args),
    onError: (err, variables) => {
      notify({
        type: 'toast',
        variant: 'negative',
        position: 'bottom-right',
        icon: 'x-octagon',
        message: `An error occurred when generating search criteria: ${err.message}`
      })
      if (variables.onError) {
        variables.onError()
      }
    },
    onSuccess: async (data, variables) => {
      if (variables.onSuccess) {
        variables.onSuccess(data)
      }
    }
  })

  const generateSearchCriteria = (args: Args): void => {
    mutation.mutate(args)
  }

  return { generateSearchCriteria }
}
