import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'
import { useSession } from 'src/hooks/use-session'
import { SequenceStepType } from 'src/libs/api/backend/sequences'
import { FeatureFlags } from 'src/libs/api/backend/session'

interface EmailSequenceStepTypeProps {
  type: SequenceStepType
  onSequenceStepTypeUpdate: (update: SequenceStepType) => void
  isGenerating: boolean
}

const getTitle = (type: SequenceStepType): string => {
  switch (type) {
    case SequenceStepType.AUTOMATED_EMAIL:
      return 'Send automatically'
    case SequenceStepType.MANUAL_EMAIL:
      return 'Send manually from queue'
    default:
      return ''
  }
}

export const EmailSequenceStepTypeSelector = (
  { type, onSequenceStepTypeUpdate, isGenerating }: EmailSequenceStepTypeProps
): JSX.Element | null => {
  const { featureFlags } = useSession()

  const DEFAULT_SEQUENCE_STEP_TYPES_OPTIONS = useMemo(() => (
    [SequenceStepType.AUTOMATED_EMAIL, SequenceStepType.MANUAL_EMAIL].map((type) => ({
      id: type,
      type: 'item',
      title: getTitle(type),
      onSelect: () => { onSequenceStepTypeUpdate(type) }
    }))
  ), [onSequenceStepTypeUpdate])

  if (!featureFlags?.includes(FeatureFlags.MANUAL_OUTREACH)) {
    return null
  }

  return (
    <Dropdown
      disabled={isGenerating}
      trigger={
        <Button
          nested
          disabled={isGenerating}
          $disabled={isGenerating}
          $variant='raised'
          $colorTheme='normal'
          trailingIcon={Icons.chevronsUpDownSmall}
          $height={24}
          $fontSize={12}
        >
          {getTitle(type)}
        </Button>
      }
      menuPosition='end'
      size='small'
      selectedValue={type}
      items={DEFAULT_SEQUENCE_STEP_TYPES_OPTIONS}
    />
  )
}
