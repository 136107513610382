import * as RadixDialog from '@radix-ui/react-dialog'
import { Button } from 'src/components/primitives/button'
import type { Spacing } from 'src/styles/theme/types'
import { DialogProvider } from './dialog-context'
import { useDialogContext } from './context-hooks'
import { Caption } from '../typography'
import * as S from './dialog.styled'
import { useDialog } from 'src/hooks/use-dialog'
import { VisuallyHidden } from '../visually-hidden'
import { forwardRef, useImperativeHandle, useRef } from 'react'

interface InnerPaddingProps {
  top: Spacing
  right: Spacing
  bottom: Spacing
  left: Spacing
}

export interface DialogStyleProps {
  $position?: 'top' | 'center' | 'right'
  $width?: 'full' | 'almostFull' | 'large' | 'half' | `${string}px` | `${string}%`
  $maxWidth?: string
  $height?: 'auto' | 'full' | 'almostFull' | 'innerPage' | 'large' | 'half' | `${string}px` | `${string}%`
  $minHeight?: 'auto' | `${string}px`
  $innerPadding?: InnerPaddingProps
  $zIndex?: number
}

interface DialogPortalProps {
  children: React.ReactNode
  zIndex?: number
  onCancel?: () => void
}

interface DialogTriggerProps {
  children: React.ReactNode
}

interface DialogHeaderProps {
  onClose?: () => void
  title: string
}

interface DialogContentProps {
  children?: React.ReactNode
  hasHeader?: boolean
}

interface DialogActionsProps {
  children?: React.ReactNode
  divider?: boolean
  $justify?: 'space-between' | 'flex-end' | 'flex-start'
}

interface DialogProps extends DialogStyleProps {
  id: string
  isOpen: boolean
  onOpenChange: (value: boolean) => void
  children: React.ReactNode
  modal?: boolean
}

export const Root = ({
  id,
  $position = 'center',
  $width = 'half',
  $maxWidth = '480px',
  $minHeight = 'auto',
  $height = 'auto',
  $innerPadding = { top: 24, right: 24, bottom: 24, left: 24 },
  modal = true,
  isOpen = false,
  onOpenChange,
  children
}: DialogProps): JSX.Element => {
  return (
    <RadixDialog.Root
      open={isOpen}
      onOpenChange={(value) => {
        onOpenChange(value)
      }}
      modal={modal}
    >
      <DialogProvider
        id={id}
        $position={$position}
        $width={$width}
        $maxWidth={$maxWidth}
        $height={$height}
        $minHeight={$minHeight}
        $innerPadding={$innerPadding}
      >
        {children}
      </DialogProvider>
    </RadixDialog.Root>
  )
}

export interface DialogPortalForwardedRefs {
  content: HTMLDivElement | null
  overlay: HTMLDivElement | null
}

export const Portal = forwardRef<DialogPortalForwardedRefs, DialogPortalProps>(({
  children,
  zIndex,
  onCancel
}, forwardedRef): JSX.Element => {
  const { id, $position, $width, $maxWidth, $height, $minHeight } = useDialogContext()
  const { dialogs } = useDialog()
  const currentDialogIndex = dialogs.findIndex((dialog) => dialog.id === id) ?? 0

  const contentRef = useRef<HTMLDivElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)

  useImperativeHandle(forwardedRef, () => ({
    content: contentRef.current,
    overlay: overlayRef.current
  }))

  return (
    <RadixDialog.Portal>
      <S.DialogOverlay ref={overlayRef} $zIndex={zIndex ?? 9990 + currentDialogIndex} />
      <S.DialogContent
        ref={contentRef}
        id={id}
        aria-describedby={`${id}-dialog-label`}
        aria-labelledby={`${id}-dialog-label`}
        $position={$position}
        $width={$width}
        $maxWidth={$maxWidth}
        $height={$height}
        $minHeight={$minHeight}
        $zIndex={zIndex ?? 9990 + currentDialogIndex + 1}
        onEscapeKeyDown={onCancel}
        onPointerDownOutside={onCancel}
      >
        <VisuallyHidden>
          <RadixDialog.Title />
        </VisuallyHidden>
        <S.DialogContentInner>{children}</S.DialogContentInner>
      </S.DialogContent>
    </RadixDialog.Portal>
  )
})

Portal.displayName = 'Portal'

export const Trigger = ({ children }: DialogTriggerProps): JSX.Element => {
  return <S.Trigger>{children}</S.Trigger>
}

export const Header = ({ onClose, title }: DialogHeaderProps): JSX.Element => {
  const { id } = useDialogContext()
  return (
    <>
      <S.Header id={`${id}-dialog-label`}>
        <Caption size="MD">{title}</Caption>
      </S.Header>
      <S.CloseTrigger>
        <Button
          nested
          ariaLabel="Close"
          leadingIcon="x"
          $variant="ghost"
          $colorTheme="normal"
          $width={24}
          $height={24}
          onClick={onClose}
        />
      </S.CloseTrigger>
    </>
  )
}

export const Content = ({ children, hasHeader = true }: DialogContentProps): JSX.Element => {
  const { $innerPadding } = useDialogContext()
  return (
    <S.Main id="dialog-content" $innerPadding={$innerPadding} $hasHeader={hasHeader}>
      {children}
    </S.Main>
  )
}

export const Actions = ({
  children,
  divider = true,
  $justify = 'flex-end'
}: DialogActionsProps): JSX.Element => {
  return <S.Actions $justify={$justify} divider={divider}>{children}</S.Actions>
}
