import { useEffect, useState } from 'react'
import { EmptyState } from 'src/components/blocks/empty-state'
import { CandidatesTable } from '../candidates-table'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { ExportToAtsButton, RejectButton, WriteEmailButton } from '../candidate-table-actions'
import { useQueryParams } from 'src/hooks/use-query-params'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'
import { isNil } from 'lodash'
import { COLUMN } from '../candidates-table/table-builder'
import type { Job } from 'src/libs/api/backend/jobs'
import { useFilteredCandidateJobs } from 'src/hooks/use-filtered-candidate-jobs'

interface CandidateAllTableProps {
  job?: Job
}

export const CandidatesAllTable = ({
  job = undefined
}: CandidateAllTableProps): JSX.Element => {
  const { getParam } = useQueryParams()
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const { isPending, data: candidateJobs } = useCandidateJobsQuery({
    hideDataWarehouseSourcedCandidates: true
  })

  // For extension to open dialog with write email
  const writeManualEmailParams = getParam('writeManualEmail')
  const exportCandidatesParams = getParam('exportCandidate')

  const { openDialog, isDialogOpen } = useDialog()

  useEffect(() => {
    if (writeManualEmailParams && !isDialogOpen(DialogId.WRITE_EMAIL) && !isNil(candidateJobs)) {
      openDialog(DialogId.WRITE_EMAIL, candidateJobs.filter((candidateJob) => candidateJob.candidateId === writeManualEmailParams))
    } else if (exportCandidatesParams && !isDialogOpen(DialogId.EXPORT_CANDIDATES) && !isNil(candidateJobs)) {
      openDialog(DialogId.EXPORT_CANDIDATES, candidateJobs.filter((candidateJob) => candidateJob.candidateId === exportCandidatesParams))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeManualEmailParams, candidateJobs])

  const { filteredCandidateJobs } = useFilteredCandidateJobs()
  const renderedCandidates = filteredCandidateJobs(candidateJobs)

  if (!candidateJobs) {
    return <></>
  }

  return (
    <CandidatesTable
      visibleColumns={[COLUMN.FAVORITE, COLUMN.NAME, COLUMN.STATUS, COLUMN.SOURCE, COLUMN.CREATED_AT]}
      isLoading={isPending}
      candidateJobs={renderedCandidates}
      setRowSelection={setRowSelection}
      rowSelection={rowSelection}
      pageHeaderHeight={64}
      emptyState={
        <EmptyState
          heading="No candidates"
          description={candidateJobs?.length > 0 && !filteredCandidateJobs(candidateJobs)?.length ? 'None of your candidates match the selected filter.' : candidateJobs?.length === 0 ? "Looks like you don't have any prospects yet, start sourcing candidates and all of them will show up right here." : ''}
          svg="userCards"
        />
      }
      selectedRowsActions={
        <>
          <RejectButton
            selectedRows={rowSelection}
            onResetSelectedRows={() => {
              setRowSelection({})
            }}
          />
          <WriteEmailButton selectedRows={rowSelection} candidateJobs={candidateJobs} />
          <ExportToAtsButton selectedRows={rowSelection} candidateJobs={candidateJobs} job={job} />
        </>
      }
    />
  )
}
