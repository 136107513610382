import { Divider } from 'src/components/primitives/divider'
import * as S from './navigation.styled'
import { When } from '../when'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey, PinExtensionChromeStoreUrl } from 'src/constants'
import { useMemo } from 'react'
import { BrandIcon } from 'src/components/primitives/brand-icon'
import { Button, Flex } from 'src/components/primitives'
import { Caption } from 'src/components/primitives/typography'
import { Icon, Icons } from 'src/components/primitives/icon'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Avatar } from 'src/components/primitives/avatar'
import { Tooltip } from 'src/components/primitives/tooltip'
import { sleep } from 'src/libs/sleep'
import { useSession } from 'src/hooks/use-session'
import { usePinExtensionVersion } from 'src/hooks/use-pin-extension'
import { useIsChrome } from 'src/hooks/use-is-browser'
import { useUserNotificationsCountQuery } from 'src/hooks/queries/use-user-notifications-count'
import { useQueryParams } from 'src/hooks/use-query-params'
import CONFIG from 'src/config'
import { DialogId } from 'src/contexts/dialogs'
import { useDialog } from 'src/hooks/use-dialog'
import { differenceInDays } from 'date-fns'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'
import { SUBSCRIPTION_ISSUE } from 'src/libs/api/backend/session'

interface NavigationBottomProps {
  isCollapsed: boolean
}

export const NavigationBottom = ({ isCollapsed }: NavigationBottomProps): JSX.Element => {
  const { user: currentUser, emailAccountAccessTokens, org, subscribed, trialActive, impersonating, subscriptionIssue } = useSession()
  const { openDialog } = useDialog()
  const extensionVersion = usePinExtensionVersion()
  const isChrome = useIsChrome()
  const { setParam } = useQueryParams()
  const { data: notificationsCountData } = useUserNotificationsCountQuery()
  const primaryEmail = emailAccountAccessTokens?.find(account => account.isPrimary) ?? emailAccountAccessTokens?.[0]
  const [submitFeedbackNoticed, setSubmitFeedbackNoticed] = useLocalStorage(LocalStorageKey.SUBMIT_FEEDBACK_NOTICED, false)
  const [chromeExtensionNoticed, setChromeExtensionNoticed] = useLocalStorage(LocalStorageKey.CHROME_EXTENSION_NOTICED, false)

  const isChromeExtensionNotInstalled = useMemo(() => {
    return isChrome && !extensionVersion
  }, [extensionVersion, isChrome])

  return (
    <S.Bottom>
      <Divider $color="bgTertiary" $spacing={{ before: 8, after: 8 }} />
      <When condition={!isCollapsed && !chromeExtensionNoticed && isChromeExtensionNotInstalled}>
        <a href={PinExtensionChromeStoreUrl} target='_blank' rel='noopener noreferrer'>
          <S.FeatureBanner>
            <BrandIcon name='chrome' size={16} color='original' />
            <Flex $flex='1'>
              <Caption size='XS' $fontWeight={400}>Install Chrome Extension</Caption>
            </Flex>
            <Button
              leadingIcon={Icons.x}
              $variant='ghost'
              $colorTheme='normal'
              $align='flex-end'
              $width={12}
              $height={12}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setChromeExtensionNoticed(true)
              }}
            />
          </S.FeatureBanner>
        </a>
      </When>
      {!isCollapsed && !subscribed && trialActive && !!org?.trialActiveUntil &&
        <S.FeatureBanner $gap={8}>
          <Flex $flex='1'>
            <Caption size='XS' $fontWeight={400}>{differenceInDays(org.trialActiveUntil, new Date())} days left in trial</Caption>
          </Flex>
          <Button
            $variant='ghost'
            $colorTheme='tint'
            $align='flex-end'
            $height={24}
            $width='auto'
            $fontSize={12}
            onClick={async () => {
              await sleep(10)
              setParam('settings', SettingParams.SUBSCRIPTION)
              openDialog(DialogId.DEFAULT_SETTINGS)
            }}
          >
            Manage plan
          </Button>
        </S.FeatureBanner>
      }
      <When condition={!isCollapsed && subscriptionIssue === SUBSCRIPTION_ISSUE.PAYMENT_FAILED}>
        <S.FeatureBanner $backgroundColor='negativeFaded'>
          <Flex $flex='1'>
            <Caption size='XS' $fontWeight={400}>Payment failed</Caption>
          </Flex>
          <Button
            $variant='ghost'
            $colorTheme='negative'
            $align='flex-end'
            $height={24}
            $width='auto'
            $fontSize={12}
            href={`${CONFIG.API_DOMAIN}/billing/portal`}
          >
            Manage
          </Button>
        </S.FeatureBanner>
      </When>
      <When condition={!isCollapsed && !submitFeedbackNoticed}>
        <S.FeatureBanner $showArrow={true}>
          <Icon name={Icons.megaphone} size={16} />
          <Flex $flex='1'>
            <Caption size='XS' $fontWeight={400}>Submit Feedback</Caption>
          </Flex>
          <Button
            leadingIcon={Icons.x}
            $variant='ghost'
            $colorTheme='normal'
            $align='flex-end'
            $width={12}
            $height={12}
            onClick={() => {
              setSubmitFeedbackNoticed(true)
            }}
          />
        </S.FeatureBanner>
      </When>
      <Flex
        $direction={isCollapsed ? 'column' : 'row'}
        $gap={12}
        $align="center"
        $justify="space-between"
      >
        <Dropdown
          triggerFullWidth={!isCollapsed}
          trigger={
            <S.UserProfileButton $impersonating={impersonating ?? false}>
              <Avatar
                $shape="circle"
                $type="photo"
                $size={24}
                initials={currentUser?.name}
                photoUrl={currentUser?.profilePhoto}
                ariaLabel="Toggle account menu"
              />
              <When condition={!isCollapsed}>
                <Caption as="span" $color="fgSecondary" size="SM" $ellipsis>
                  {currentUser?.name}
                </Caption>
              </When>
            </S.UserProfileButton>
          }
          modal={false}
          menuPosition="start"
          items={[
            {
              id: 'account',
              title: currentUser?.name ?? '',
              subTitle: primaryEmail?.email ?? '',
              isSelectable: false
            },
            {
              id: 'account-separator',
              title: 'AccountSeparator',
              type: 'separator'
            },
            ...(isChromeExtensionNotInstalled
              ? [{
                  id: 'install-extension',
                  title: 'Chrome Extension',
                  href: PinExtensionChromeStoreUrl,
                  target: '_blank',
                  icon: <BrandIcon name='chrome' size={16} color='original' />
                },
                {
                  id: 'account-separator-2',
                  title: 'AccountSeparator',
                  type: 'separator'
                }]
              : []
            ),
            {
              id: 'settings',
              title: 'Settings',
              icon: 'settings',
              onSelect: async () => {
                await sleep(10)
                setParam('settings', SettingParams.PROFILE)
                openDialog(DialogId.DEFAULT_SETTINGS)
              }
              // href: jobId
              //   ? RouteBuilder.build('SETTINGS_JOB_ACCOUNT_PROFILE', { jobId })
              //   : RouteBuilder.build('SETTINGS_ACCOUNT_PROFILE')
            },
            {
              id: 'settings-separator',
              title: 'SettingsSeparator',
              type: 'separator'
            },
            {
              id: 'logout',
              title: 'Log out',
              variant: 'negative',
              icon: 'log-out',
              href: `${CONFIG.API_DOMAIN}/auth/logout?redirect=${CONFIG.APP_DOMAIN}`
            }
          ]}
        />
        <When condition={!isCollapsed}>
          <Flex $gap={4} $width='auto' $align='flex-end'>
            <Tooltip
              trigger={
                <S.FeatureButton
                  aria-label='Send feedback'
                  onClick={() => {
                    if (!submitFeedbackNoticed) {
                      setSubmitFeedbackNoticed(true)
                    }
                    openDialog(DialogId.SEND_FEEDBACK)
                  }}
                >
                  <Icon name={Icons.megaphone} />
                </S.FeatureButton>
              }
            >
              Send feedback
            </Tooltip>
            <Tooltip
              trigger={
                <S.FeatureButton
                  aria-label="Notifications"
                  onClick={() => {
                    openDialog(DialogId.NOTIFICATIONS)
                  }}
                >
                  <Icon name={Icons.bell} />
                  {(notificationsCountData && notificationsCountData.count > 0)
                    ? (
                        <S.Badge>
                          {notificationsCountData.count > 20 ? '20+' : notificationsCountData.count}
                        </S.Badge>
                      )
                    : null
                  }
                </S.FeatureButton>
              }
            >
              Notifications
            </Tooltip>
          </Flex>
        </When>
      </Flex>
    </S.Bottom>
  )
}
