import styled from 'styled-components'

export const SequenceStatusCell = styled.div`
  button {
    margin-top: 0.25rem;
    display: inline-flex;
    transform: translateX(-0.375rem);
  }
`

export const CandidateStatusCell = styled.div`
  button {
    margin-top: 0.25rem;
    display: inline-flex;
    transform: translateX(-0.375rem);
  }
`

export const FavoriteHeader = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const CandidateStageActionsCell = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5rem;
`

export const CandidateCriteriaMatchDetailsCell = styled.div`
  position: relative;
  width: calc(100% + (0.5rem * 2));
  /* width: 301px; */
  height: calc(100% + (0.75rem * 2));
  /* background-color: ${({ theme }) => theme.colors.bgTertiary}; */
  border-right: solid 1px;
  border-color: ${({ theme }) => theme.colors.borderTranslucent};
  transform: translate(-0.5rem, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.bgTertiary};
    z-index: -1;
    opacity: 0.5;
  }
`

export const CandidateCriteriaMatchDetailsCellInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`
