import type { EmailSequence } from 'src/models/sequence'
import type { Benefit } from 'src/components/blocks/benefits-list'
import { SequenceStepType } from './api/backend/sequences'

export const ACCOUNT_ONBOARDING_STEPS = [
  {
    id: 0,
    title: 'Company details',
    pathname: 'ONBOARDING_COMPANY_PROFILE',
    image: 'onboarding/company-profile.jpg'
  },
  {
    id: 0,
    title: 'Company details',
    pathname: 'ONBOARDING_JOIN_TEAM',
    image: 'onboarding/company-profile.jpg'
  },
  {
    id: 1,
    title: 'Job position',
    pathname: 'ONBOARDING_JOB_POSITION',
    image: 'onboarding/company-profile.jpg'
  },
  {
    id: 2,
    title: 'Connected accounts',
    pathname: 'ONBOARDING_CONNECTED_ACCOUNTS',
    image: 'onboarding/connect-accounts.jpg'
  },
  {
    id: 3,
    title: 'Invite team',
    pathname: 'ONBOARDING_INVITE_TEAM',
    image: 'onboarding/invite-team.jpg'
  },
  {
    id: 4,
    title: 'Pin’s browser extension',
    pathname: 'ONBOARDING_BROWSER_EXTENSION',
    image: 'onboarding/chrome-extension.jpg'
  }
]

export const JOB_SETUP_STEPS = [
  {
    id: 0,
    title: 'Job description',
    description: 'Create your Job description',
    pathname: 'JOBS_NEW'
  },
  {
    id: 1,
    title: 'Outreach sequence',
    description: 'Automate candidate outreach',
    pathname: 'JOBS_NEW_EMAIL_SEQUENCE'
  }
]

export const INITIAL_EMAIL_SEQUENCE_STEPS: EmailSequence['sequenceSteps'] = [
  {
    position: 0,
    type: SequenceStepType.AUTOMATED_EMAIL,
    waitDays: 0,
    subject: 'Intro email',
    body: '',
    sendingEmailAlias: null
  },
  {
    position: 1,
    type: SequenceStepType.AUTOMATED_EMAIL,
    waitDays: 3,
    subject: 'First follow up',
    body: '',
    sendingEmailAlias: null
  },
  {
    position: 2,
    type: SequenceStepType.AUTOMATED_EMAIL,
    waitDays: 5,
    subject: 'Next follow up',
    body: '',
    sendingEmailAlias: null
  }
]

export const ATS_IMPORT_OPTIONS = [
  {
    brand: 'Indeed',
    logoUrl:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADQklEQVR4AcXXA7AjWxSF4fPGtm3btm3btm3btm3btm3bSO7/1pg3SWe0qr7qUtde7T7GWQDBm8SXujJe9ss9sYtN7sl+GS/1JNHHfYyleDI4pfSTs/IW53kr52WIpLdcBPgkrHSR67ifW9JdIogRl4cnk9Xiwa/HQzZKajHidHhGOcLvzwnJJkY8HZ5cjvLnckLS/FACEMLJajzJk6fPmbd8A536jaV1jxG07um5Vj2G03/0DB49ecpPslHCyzfDvUgXPMmr169p02skvqJkwIRKLikcC5GM6GmKcuX6LTxJX/EqnwukkRt4kg3b9xE4djZM6JSYsKmcC52C6OmLOypwXdKJ+XT0/XGQsdMX/3TQf+I7TGoChkmDL20tFFAYKV4NkEDO4CCjpi78YbgfDYwXMj1Fg2ahfqAc1AuUnYzBM+HzXZF3BdIVc1bgjMQ3QB15a6WAjpo0ITJSNXA2WgfIwUC/uZnuOy+LfOWnbOCs6B4gc7E6PHj0BAexSRsDjBWsFIipI68SJBttA+VidrTy7IxTiyNRqnEhdGUOhixHx8od2LLnEC5koQH2WyngVXIGy0wbHfm0iGV4WqQP5O0FqdtB7CYQtjaM24CLOWCAe1YKeA+bmrJBsjJIp31TtCp45O4Jqd4Nbwxha4H/ijBwOS7mngFsVi9BId14033nY3+4Snikavvt8KBVYd0RXIzdAHarBaKGTMdYv3k4G7w8xNLwMBrurwJ4KQVVRsKL17gYmwHuWy0gJAuegSXhykD0hhCkCkSsC/UmwJV7KJYuwQF3CuiVTNlyzbBvPAaL9sKhS/DmLRZz0AAT3CoQMjkla7fDZrfzC1logHry1p0CpX6tgE3aGiChnP0HBc5JIgN4lUH/oMA48fb15/im5XuglgrY7O7+rGb9+n/Aq/TwtMCUBRqYTJJ/ESIp9dr2w830F6/f/xVFlA38JFt2HaRGi57U/KhG85607D6Mk2cv4Ua2SST56U9pajnBn8sZyejszzibnPxDw/O6ujZILRt/48Jku2SwujoKL33l5i8uzQZ8uuburBG9SiYZY2FxapNzH/fJ+DtXyfGllSyQA3JPbGKX+3JQFkk7SSjeXRn8P2dRK5CJI06CAAAAAElFTkSuQmCC'
  },
  {
    brand: 'Zip Recruiter',
    logoUrl:
      'https://images.crunchbase.com/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/v9drsv7kviovnjd8j6ew'
  },
  {
    brand: 'Front',
    logoUrl:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADQklEQVR4AcXXA7AjWxSF4fPGtm3btm3btm3btm3btm3bSO7/1pg3SWe0qr7qUtde7T7GWQDBm8SXujJe9ss9sYtN7sl+GS/1JNHHfYyleDI4pfSTs/IW53kr52WIpLdcBPgkrHSR67ifW9JdIogRl4cnk9Xiwa/HQzZKajHidHhGOcLvzwnJJkY8HZ5cjvLnckLS/FACEMLJajzJk6fPmbd8A536jaV1jxG07um5Vj2G03/0DB49ecpPslHCyzfDvUgXPMmr169p02skvqJkwIRKLikcC5GM6GmKcuX6LTxJX/EqnwukkRt4kg3b9xE4djZM6JSYsKmcC52C6OmLOypwXdKJ+XT0/XGQsdMX/3TQf+I7TGoChkmDL20tFFAYKV4NkEDO4CCjpi78YbgfDYwXMj1Fg2ahfqAc1AuUnYzBM+HzXZF3BdIVc1bgjMQ3QB15a6WAjpo0ITJSNXA2WgfIwUC/uZnuOy+LfOWnbOCs6B4gc7E6PHj0BAexSRsDjBWsFIipI68SJBttA+VidrTy7IxTiyNRqnEhdGUOhixHx8od2LLnEC5koQH2WyngVXIGy0wbHfm0iGV4WqQP5O0FqdtB7CYQtjaM24CLOWCAe1YKeA+bmrJBsjJIp31TtCp45O4Jqd4Nbwxha4H/ijBwOS7mngFsVi9BId14033nY3+4Snikavvt8KBVYd0RXIzdAHarBaKGTMdYv3k4G7w8xNLwMBrurwJ4KQVVRsKL17gYmwHuWy0gJAuegSXhykD0hhCkCkSsC/UmwJV7KJYuwQF3CuiVTNlyzbBvPAaL9sKhS/DmLRZz0AAT3CoQMjkla7fDZrfzC1logHry1p0CpX6tgE3aGiChnP0HBc5JIgN4lUH/oMA48fb15/im5XuglgrY7O7+rGb9+n/Aq/TwtMCUBRqYTJJ/ESIp9dr2w830F6/f/xVFlA38JFt2HaRGi57U/KhG85607D6Mk2cv4Ua2SST56U9pajnBn8sZyejszzibnPxDw/O6ujZILRt/48Jku2SwujoKL33l5i8uzQZ8uuburBG9SiYZY2FxapNzH/fJ+DtXyfGllSyQA3JPbGKX+3JQFkk7SSjeXRn8P2dRK5CJI06CAAAAAElFTkSuQmCC'
  },
  {
    brand: 'Zoho Recruit',
    logoUrl:
      'https://www.zohoservices.dotsquares.com/apps.11350.8570248a-1e5e-4540-ac37-1abcbf30d9a5.e91665473035557.png'
  },
  {
    brand: 'Workable',
    logoUrl:
      'https://res.cloudinary.com/startup-grind/image/upload/c_fill,dpr_2,f_auto,g_center,q_auto:good/v1/gcs/platform-data-workable/contentbuilder/Chapter%20Thumbnail%20-%201080x1080px.png'
  }
]

export const CONNECTED_ACCOUNTS_BENEFITS: Benefit[] = [
  {
    title: 'Seamless Candidate Communication',
    description:
      'Effortlessly email potential hires directly through the app, streamlining your recruitment process.',
    icon: 'mail-arrow-right'
  },
  {
    title: 'Sync Contacts for Efficiency',
    description:
      'Never double-email a candidate. Avoid redundancy and maintain a polished, professional approach.',
    icon: 'contact'
  },
  {
    title: 'Simplify Scheduling',
    description:
      'Make scheduling meetings with candidates a breeze. Save time and build your dream team.',
    icon: 'calendar-days'
  }
]

export const DEFAULT_REFINEMENT_SUGGESTIONS = [
  { tag: 'Worked at a Series A company', query: 'Worked at a Series A company' },
  { tag: 'First 50 employees', query: 'First 50 employees' },
  { tag: 'Based in NYC', query: 'Based in NYC' },
  { tag: 'Aerospace Industry', query: 'Aerospace Industry' },
  { tag: '2+ years experience', query: '2+ years experience' },
  { tag: 'Top Tier University', query: 'Top Tier University' },
  { tag: 'Knows React', query: 'Knows React' }
]

interface AtsIntegrationAppUrl {
  mergeIntegrationSlug: string
  url: string | null
}

export const ATS_INTEGRATION_APP_URLS: AtsIntegrationAppUrl[] = [
  {
    mergeIntegrationSlug: 'applicantstack',
    url: 'https://www.applicantstack.com/login/'
  },
  {
    mergeIntegrationSlug: 'ashby',
    url: 'https://app.ashbyhq.com/signin'
  },
  {
    mergeIntegrationSlug: 'bamboohr',
    url: 'https://app.bamboohr.com/login/'
  },
  {
    mergeIntegrationSlug: 'breezy',
    url: 'https://app.breezy.hr/signin'
  },
  {
    mergeIntegrationSlug: 'cats',
    url: 'https://login.catsone.com'
  },
  {
    mergeIntegrationSlug: 'clayhr',
    url: 'https://www.clayhr.com/'
  },
  {
    mergeIntegrationSlug: 'clockwork',
    url: 'https://account.clockworkrecruiting.com/session/new'
  },
  {
    mergeIntegrationSlug: 'comeet',
    url: 'https://app.comeet.co'
  },
  {
    mergeIntegrationSlug: 'cornerstone-talentlink',
    url: 'https://www.cornerstoneondemand.com/'
  },
  {
    mergeIntegrationSlug: 'engageats',
    url: 'https://www.engage-ats.com/'
  },
  {
    mergeIntegrationSlug: 'fountain',
    url: 'https://web.fountain.com/users/sign_in'
  },
  {
    mergeIntegrationSlug: 'freshteam',
    url: 'https://login.freshworks.com/email-login/'
  },
  {
    mergeIntegrationSlug: 'greenhouse',
    url: 'https://app.greenhouse.io/users/sign_in'
  },
  {
    mergeIntegrationSlug: 'greenhouse-job-boards-api',
    url: 'https://app.greenhouse.io/users/sign_in'
  },
  {
    mergeIntegrationSlug: 'homerun',
    url: 'https://app.homerun.co'
  },
  {
    mergeIntegrationSlug: 'hr-cloud',
    url: 'https://corehr.hrcloud.com/'
  },
  {
    mergeIntegrationSlug: 'jazzhr',
    url: 'https://app.jazz.co/'
  },
  {
    mergeIntegrationSlug: 'jobscore',
    url: 'https://hire.jobscore.com/login'
  },
  {
    mergeIntegrationSlug: 'jobsoid',
    url: 'https://app.jobsoid.com/login'
  },
  {
    mergeIntegrationSlug: 'jobvite',
    url: 'https://id.jobvite.com/'
  },
  {
    mergeIntegrationSlug: 'lano',
    url: 'https://fms.lano.io/login'
  },
  {
    mergeIntegrationSlug: 'lever',
    url: 'https://hire.lever.co/auth/login'
  },
  {
    mergeIntegrationSlug: 'oracle-taleo',
    url: 'https://www.oracle.com/human-capital-management/taleo/signin/'
  },
  {
    mergeIntegrationSlug: 'polymer',
    url: 'https://app.polymer.co/'
  },
  {
    mergeIntegrationSlug: 'recruiterflow',
    url: 'https://recruiterflow.com/login'
  },
  {
    mergeIntegrationSlug: 'sage-hr',
    url: 'https://id.sage.com/login'
  },
  {
    mergeIntegrationSlug: 'sap-successfactors',
    url: null
  },
  {
    mergeIntegrationSlug: 'smartrecruiters',
    url: 'https://www.smartrecruiters.com/account/sign-in'
  },
  {
    mergeIntegrationSlug: 'talentlyft',
    url: 'https://accounts.talentlyft.com/login'
  },
  {
    mergeIntegrationSlug: 'talentreef',
    url: 'https://login.jobappnetwork.com/login'
  },
  {
    mergeIntegrationSlug: 'teamtailor',
    url: 'https://app.teamtailor.com/login'
  },
  {
    mergeIntegrationSlug: 'tellent',
    url: null
  },
  {
    mergeIntegrationSlug: 'ukg-pro-recruiting',
    url: null
  },
  {
    mergeIntegrationSlug: 'workable',
    url: 'https://id.workable.com/'
  },
  {
    mergeIntegrationSlug: 'workday',
    url: null
  }
]
