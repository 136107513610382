import * as RadixDropdown from '@radix-ui/react-dropdown-menu'
import { Button, Flex } from 'src/components/primitives'
import { Icons } from 'src/components/primitives/icon'
import * as S from './refinement-filter.styled'
import { SearchRefinementForm } from 'src/components/blocks/search-refinement-form'
import { CriteriaKey } from 'src/libs/api/backend/candidate_search'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Caption } from 'src/components/primitives/typography'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { useDialog } from 'src/hooks/use-dialog'
import { sanitizeRefinementCriteria } from 'src/utils/refinement-criteria'
import { isEmpty } from 'lodash'
import { useAtom } from 'jotai'
import { cloneRefinementCriteriaAtom } from 'src/stores/jobRefinement'
import { useBlocker } from 'react-router-dom'

interface RefinementFilterProps {
  jobSearchRefinement?: JobSearchRefinement
  isRecommending: boolean
  setIsRecommending: (isRecommending: boolean) => void
  newSearchRefinement?: boolean
  onSubmit: (criteria: Criteria) => void
}

export const RefinementFilter = ({
  jobSearchRefinement,
  onSubmit,
  newSearchRefinement,
  setIsRecommending,
  isRecommending
}: RefinementFilterProps): JSX.Element => {
  const [opened, setOpened] = useState(!!newSearchRefinement)
  const [numberOfFilters, setNumberOfFilters] = useState(0)
  const [isDirty, setIsDirty] = useState(false)
  const [cloneRefinementCriteria, setCloneRefinementCriteria] = useAtom(cloneRefinementCriteriaAtom)
  const { openAlert } = useDialog()

  const initialCriteria = useMemo((): Criteria | undefined => {
    if (newSearchRefinement) {
      if (cloneRefinementCriteria) {
        setIsDirty(true)
        return sanitizeRefinementCriteria(cloneRefinementCriteria)
      }
      return {
        [CriteriaKey.CURRENT_JOB_TITLES]: []
      }
    } else {
      return sanitizeRefinementCriteria(jobSearchRefinement?.searchCriteria)
    }
  }, [cloneRefinementCriteria, jobSearchRefinement?.searchCriteria, newSearchRefinement])

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      return isDirty && (
        currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search
      )
    }
  )

  useEffect(() => {
    if (!isEmpty(initialCriteria)) {
      const { custom_requirements: customRequirements, ...rest } = initialCriteria
      const numberOfFilters = Object.keys(rest).length + (customRequirements ?? []).length
      setNumberOfFilters(numberOfFilters)
    }
  }, [initialCriteria])

  const resetState = useCallback(() => {
    setCloneRefinementCriteria(undefined)
    setOpened(false)
    setIsDirty(false)
  }, [setCloneRefinementCriteria])

  const triggerClosed = useCallback(() => {
    if (isDirty) {
      openAlert({
        message: 'You have unsaved changes',
        description: 'Closing the criteria options will erase your unsaved changes.',
        cancelText: 'Keep Editing',
        confirmText: 'Discard Changes',
        onConfirm: () => {
          resetState()
        }
      })
    } else {
      resetState()
    }
  }, [isDirty, openAlert, resetState])

  useEffect(() => {
    if (blocker.state === 'blocked' && newSearchRefinement) {
      openAlert({
        message: 'Are you sure you want to leave this page?',
        description: 'All your unsaved criteria will be lost.',
        cancelText: 'Stay on page',
        confirmText: 'Discard and leave',
        onCancel: () => {
          blocker.reset()
        },
        onConfirm: () => {
          resetState()
          blocker.proceed()
        }
      })
    }
  }, [blocker, newSearchRefinement, openAlert, resetState])

  return (
    <>
      {opened && !newSearchRefinement && <S.Overlay />}
      <RadixDropdown.Root
        open={opened}
        onOpenChange={(isOpen: boolean) => {
          if (!newSearchRefinement) {
            if (isOpen) {
              setOpened(true)
            } else {
              triggerClosed()
            }
          }
        }}
        modal={false} // IMPORTANT
      >
        <S.Trigger>
          <Button
            nested
            $variant='raised-light'
            $colorTheme='muted'
            $height={24}
            $fontSize={12}
            leadingIcon={Icons.filter}
            trailingIcon={Icons.chevronDown}
          >
            <Flex $align='center' $gap={6}>
              <Caption size='XS'>Criteria</Caption>
              <S.NumberOfFilters>{numberOfFilters}</S.NumberOfFilters>
            </Flex>
          </Button>
        </S.Trigger>
        <RadixDropdown.Portal>
          <S.DropdownContent
            align="start"
            sideOffset={4}
          >
            <SearchRefinementForm
              initialCriteria={initialCriteria}
              setIsRecommending={setIsRecommending}
              isRecommending={isRecommending}
              onSubmit={(criteria: Criteria) => {
                onSubmit(criteria)
                setOpened(false)
              }}
              isNewRefinement={newSearchRefinement}
              setNumberOfFilters={setNumberOfFilters}
              setIsDirty={setIsDirty}
              isDirty={isDirty}
              triggerClose={triggerClosed}
              searchRefinementStatus={jobSearchRefinement?.sourcingState}
            />
          </S.DropdownContent>
        </RadixDropdown.Portal>
      </RadixDropdown.Root>
    </>
  )
}
