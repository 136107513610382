import { useMemo } from 'react'
import { Button } from 'src/components/primitives'
import { Dropdown } from 'src/components/primitives/dropdown'
import { Icons } from 'src/components/primitives/icon'

interface CriteriaSelectionProps {
  value: boolean
  onUpdate: (key: string, value: boolean) => void
  disabled: boolean
}
export const CriteriaSelection = ({ value, onUpdate, disabled }: CriteriaSelectionProps): JSX.Element => {
  const options = useMemo(() => ([
    {
      id: 'must-have',
      title: 'Must have',
      onSelect: () => {
        onUpdate('optional', false)
      }
    },
    {
      id: 'prefer',
      title: 'Preferred',
      onSelect: () => {
        onUpdate('optional', true)
      }
    }
  ]), [onUpdate])
  return (
    <Dropdown
      $minWidth='160px'
      size='small'
      disabled={disabled}
      fontWeight={400}
      trigger={
        <Button
          nested
          $variant='outline'
          $width={160}
          $height={32}
          $fontSize={12}
          $fontWeight={400}
          trailingIcon={Icons.chevronsUpDownSmall}
          $align='space-between'
          disabled={disabled}
        >
          {value ? 'Preferred' : 'Must have'}
        </Button>
      }
      items={options}
    />
  )
}
