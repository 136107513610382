import type { CriteriaKey, CriteriaValue, LocationCriteria } from 'src/libs/api/backend/candidate_search'
import * as S from './index.styled'
import { CriteriaPill } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { useMemo, useState } from 'react'
import { useLocationSuggestions } from 'src/hooks/queries/use-location-suggestion'
import { debounce } from 'lodash'
import { SuggestionInput } from './suggestion-input'
import type { LocationSuggestion } from 'src/libs/api/backend/typeaheads'
import { titleCase } from 'src/libs/string'

interface LocationRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: LocationCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const LocationRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: LocationRowProps): JSX.Element => {
  const [query, setQuery] = useState<string | undefined>(undefined)
  const setQueryDebounced = useMemo(() => {
    return debounce(setQuery, 300)
  }, [])
  const { isPending, data: locationSuggestions } = useLocationSuggestions({ query })

  return (
    <S.RowContainer>
      <SuggestionInput
        isLoading={isPending}
        disabled={disabled}
        triggerSearch={setQueryDebounced}
        suggestions={locationSuggestions ?? []}
        onSelect={(suggestion: LocationSuggestion) => {
          onCriteriaUpdate(
            criteriaKey,
            [...criteriaValue, {
              city: suggestion.city ?? undefined,
              metro: suggestion.metro ?? undefined,
              state: suggestion.state ?? undefined,
              optional: false,
              negative: false
            }]
          )
        }}
        placeholder={criteriaValue.length === 0 ? 'Add location' : undefined}
        items={
          <>
            {criteriaValue.map((locationCriteria, index) => {
              const label = [
                locationCriteria.city,
                locationCriteria.metro,
                locationCriteria.state
              ].filter(Boolean).join(', ')
              return (
                <CriteriaPill
                  key={index}
                  value={{
                    label: titleCase(label),
                    optional: locationCriteria.optional ?? true,
                    negative: locationCriteria.negative ?? false
                  }}
                  type='location'
                  onUpdate={(key: string, value: boolean) => {
                    const updatedCriteriaValue = [...criteriaValue]
                    updatedCriteriaValue[index] = {
                      ...updatedCriteriaValue[index],
                      [key]: value
                    }
                    onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
                  }}
                  onRemove={() => {
                    const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
                    onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
                  }}
                />
              )
            })}
          </>
        }
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
