import type { Color, Spacing } from 'src/styles/theme/types'
import { CompanyLogo } from '../company-logo'
import type { CompanyLogoProps } from '../company-logo'
import { ProfilePhoto } from '../profile-photo'
import * as S from './avatar.styled'
import { isNil } from 'lodash'
import { Icon } from '../icon'
import type { IconName } from '../icon'
import { isExternalLink } from 'src/libs/is-external-link'

type AvatarType = 'logo' | 'photo' | 'icon' | 'initials'

export interface AvatarStyleProps {
  $type?: AvatarType
  $shape?: 'sharp' | 'soft' | 'rounded' | 'circle'
  $size?: Spacing
  $status?: Color
  $stacked?: boolean
  $overflow?: 'hidden' | 'visible'
  $border?: boolean
  $innerPadding?: Spacing
}

interface InnerProps extends Pick<AvatarStyleProps, '$type' | '$shape' | '$size' | '$innerPadding'> {
  initials?: string | null
  company?: CompanyLogoProps
  photoUrl?: string | null
  icon?: {
    name: IconName
    color: Color
  }
  fallbackIcon?: {
    name: IconName
    color?: Color
  }
  fallbackAvatar?: 'random'
}

export interface AvatarProps extends InnerProps, AvatarStyleProps {
  as?: 'div' | 'button'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  ariaLabel?: string
  componentName?: string
  href?: string
}

const Inner = ({
  $type = 'initials',
  $size,
  $shape,
  $innerPadding,
  initials = 'P',
  company,
  photoUrl,
  icon,
  fallbackIcon,
  fallbackAvatar
}: InnerProps): JSX.Element => {
  // We want to remove special characters such as emojis from the initials
  const preparedInitials = initials?.replace(/[^\p{L}\p{N}\s]/gu, '').replace(/\s+/g, ' ').trim() ?? null

  if (!isNil(photoUrl) && (!isNil(company) || !isNil(icon))) {
    return (
      <S.ProfilePhotoAndLogo data-name="ProfilePhotoAndLogo">
        <ProfilePhoto
          $size={$size}
          $shape={$shape}
          key={photoUrl}
          url={photoUrl}
          initials={preparedInitials}
        />
        <S.Logo $innerPadding={$innerPadding}>
          {company && (
            <CompanyLogo
              $shape={$shape}
              name={company.name}
              url={company.url}
              logoUrl={company.logoUrl}
              fallbackIcon={fallbackIcon?.name}
            />
          )}
          {icon && (
            <S.AvatarIcon data-name="AvatarIcon">
              <Icon name={icon.name} color={icon.color ?? 'fgSecondary'} size={$size && $size <= 24 ? 8 : 12} />
            </S.AvatarIcon>
          )}
        </S.Logo>
      </S.ProfilePhotoAndLogo>
    )
  }

  if (!isNil(photoUrl)) {
    return (
      <ProfilePhoto
        data-name="ProfilePhoto"
        $size={$size}
        $shape={$shape}
        key={photoUrl}
        url={photoUrl}
        initials={preparedInitials}
        fallbackAvatar={fallbackAvatar}
      />
    )
  }

  if ($type === 'logo' && company?.name) {
    return (
      <S.Logo
        data-name="CompanyLogo"
        $innerPadding={$innerPadding}
      >
        <CompanyLogo
          $shape={$shape}
          name={company.name}
          url={company.url}
          logoUrl={company.logoUrl}
          externalLogoUrl={company.externalLogoUrl}
          fallbackIcon={fallbackIcon?.name}
        />
      </S.Logo>
    )
  }

  return <ProfilePhoto
    data-name="PhotoPhotoEmpty"
    $shape={$shape}
    $size={$size}
    key={initials}
    initials={preparedInitials}
 />
}

export const Avatar = ({
  $type = 'initials',
  $shape = 'soft',
  $size = 36,
  $stacked = false,
  $status,
  $border = true,
  $innerPadding,
  $overflow,
  as = 'div',
  onClick,
  href,
  ariaLabel,
  initials = 'P',
  company,
  photoUrl,
  icon,
  fallbackIcon,
  fallbackAvatar,
  componentName
}: AvatarProps): JSX.Element => {
  if (onClick ?? as === 'button') {
    return (
      <S.AvatarWrapper data-name={componentName}>
        {$status && <S.Status $status={$status} />}
        <S.Avatar
          as="button"
          onClick={onClick}
          aria-label={ariaLabel}
          $shape={$shape}
          $size={$size}
          $type={$type}
          $border={$border}
          $stacked={$stacked}
          $overflow={$overflow}
        >
          <Inner
            $type={$type}
            $shape={$shape}
            $size={$size}
            $innerPadding={$innerPadding}
            initials={initials}
            company={company}
            photoUrl={photoUrl}
            icon={icon}
            fallbackIcon={fallbackIcon}
            fallbackAvatar={fallbackAvatar}
          />
        </S.Avatar>
      </S.AvatarWrapper>
    )
  }

  if (href && isExternalLink(href)) {
    return (
      <S.AvatarWrapper as="a" href={href} target="_blank" rel="noopener noreferrer" data-name={componentName}>
        {$status && <S.Status $status={$status} />}
        <S.Avatar
          $shape={$shape}
          $size={$size}
          $type={$type}
          $status={$status}
          $stacked={$stacked}
          $border={$border}
          $overflow={$overflow ?? (!isNil(company) || !isNil(icon) ? 'visible' : 'hidden')} // allows the overflow logo to appear on top
        >
          <Inner
            $type={$type}
            $shape={$shape}
            $size={$size}
            $innerPadding={$innerPadding}
            initials={initials}
            company={company}
            photoUrl={photoUrl}
            icon={icon}
            fallbackIcon={fallbackIcon}
            fallbackAvatar={fallbackAvatar}
          />
        </S.Avatar>
      </S.AvatarWrapper>
    )
  }

  return (
    <S.AvatarWrapper data-name={componentName}>
      {$status && <S.Status $status={$status} />}
      <S.Avatar
        $shape={$shape}
        $size={$size}
        $type={$type}
        $status={$status}
        $stacked={$stacked}
        $border={$border}
        $overflow={$overflow ?? (!isNil(company) || !isNil(icon) ? 'visible' : 'hidden')} // allows the overflow logo to appear on top
      >
        <Inner
          $type={$type}
          $shape={$shape}
          $size={$size}
          $innerPadding={$innerPadding}
          initials={initials}
          company={company}
          photoUrl={photoUrl}
          icon={icon}
          fallbackIcon={fallbackIcon}
          fallbackAvatar={fallbackAvatar}
        />
      </S.Avatar>
    </S.AvatarWrapper>
  )
}
