import { CandidateDetailsCard } from 'src/components/blocks/candidate-details-card'
import { CandidateActions } from 'src/components/blocks/candidate-actions'
import { useCandidateJobsQuery } from 'src/hooks/queries/use-candidate-jobs'
import { useDialog } from 'src/hooks/use-dialog'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { isNil } from 'lodash'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useAddCandidateToSequence } from 'src/hooks/mutations/use-add-candidate-to-sequence'
import type { CandidateJobExpanded } from 'src/libs/api/backend/candidate_jobs'
import { CandidateJobStage } from 'src/libs/api/backend/candidate_jobs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { CANDIDATES_PAGES_MAX_WIDTH, CONTENT_PADDING } from 'src/styles/constants'
import { DialogId } from 'src/contexts/dialogs'
import { useDebounceCallback, useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey, ViewMode } from 'src/constants'
import { CompaniesPreferencesProvider } from 'src/providers/companies-preferences'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import { SourcingPageHeader, SourcingStatus } from 'src/components/blocks/sourcing'
import { When } from 'src/components/blocks/when'
import { useUpdateJobSearchRefinement } from 'src/hooks/mutations/use-update-job-search-refinement'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { CHANNEL_TYPE, EVENT_TYPE, getChannelName } from 'src/libs/api/backend/websockets'
import { isSourcing, JobSourcingState } from 'src/libs/api/backend/jobs'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { LoadingSkeleton } from 'src/components/blocks/loading-skeleton'
import { SEO } from '../../../components/primitives/seo'
import { generateUrlWithParams } from 'src/utils/format-url'
import RouteBuilder from 'src/libs/route-builder'
import { RefinementActionsBar } from 'src/components/blocks/refinement-actions-bar'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import { ToggleCandidateView } from 'src/components/blocks/toggle-candidate-view'
import { CandidatesSourcedTable } from 'src/components/tables/candidates-sourced-table'
import { CandidatesTablePagesContentInner } from './candidates.styled'
import { useChannel } from 'ably/react'
import { COLUMN } from 'src/components/tables/candidates-table/table-builder'
import { usePrintView } from 'src/hooks/use-print-view'
import { Logo } from 'src/components/primitives/logo'
import { IfElse } from 'src/components/blocks/if-else'
// import { UpgradeCtaBanner } from 'src/components/blocks/upgrade-cta-banner'

interface JobCandidatesSourcingPageProps {
  isManualSourceView?: boolean
}

const JobCandidatesSourcingPage = ({ isManualSourceView = false }: JobCandidatesSourcingPageProps): JSX.Element => {
  const navigate = useNavigate()
  const { openDialog } = useDialog()
  const { jobId } = useParams()
  const { updateJobSearchRefinement } = useUpdateJobSearchRefinement()
  const { data: job } = useJobQuery()
  const isPrintView = usePrintView()

  const [currViewMode] = useLocalStorage(LocalStorageKey.VIEW_MODE, ViewMode.DEFAULT)

  const [searchParams] = useSearchParams()
  const jobSearchRefinementId = searchParams.get('jobSearchRefinementId')
  const { data: jobSearchRefinements, isPending: jobSearchRefinementsPending } = useListJobSearchRefinementsQuery(jobId)
  const [isRecommending, setIsRecommending] = useState(false)

  useEffect(() => {
    // Auto navigate to the 1st search refinement if there is no search query and NOT a manual view
    if (!jobSearchRefinementsPending && searchParams.size === 0 && !isManualSourceView) {
      if (!isNil(jobSearchRefinements) && jobSearchRefinements.length > 0) {
        navigate(generateUrlWithParams(
          RouteBuilder.build('JOBS_CANDIDATES_SOURCING', {
            jobId: jobId ?? ''
          }), {
            jobSearchRefinementId: jobSearchRefinements[0].id
          }
        ))
      } else {
        navigate(RouteBuilder.build('JOBS_CANDIDATES_SOURCING_SEARCH', {
          jobId: jobId ?? ''
        }))
      }
    }
  }, [isManualSourceView, jobId, jobSearchRefinements, jobSearchRefinementsPending, navigate, searchParams])

  const jobSearchRefinement = useMemo(() => {
    return jobSearchRefinements?.find((refinement) => refinement.id === jobSearchRefinementId)
  }, [jobSearchRefinements, jobSearchRefinementId])

  const autoApproveIsEnabled = useMemo(() => {
    return jobSearchRefinement?.automateAutoApproveCandidates ?? false
  }, [jobSearchRefinement?.automateAutoApproveCandidates])

  const sourcingState = useMemo(() => {
    return jobSearchRefinement?.sourcingState
  }, [jobSearchRefinement?.sourcingState])

  const [renderedCandidates, setRenderedCandidates] = useState<CandidateJobExpanded[]>()

  const {
    isPending,
    data: candidateJobs
  } = useCandidateJobsQuery({
    stage: CandidateJobStage.SOURCED,
    jobSearchRefinementId,
    source: isManualSourceView ? null : undefined
  })
  const { isPending: isSequenceLoading, data: sequence } = useJobSequenceQuery()
  const { addCandidateToSequence } = useAddCandidateToSequence()

  const parentRef = useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: renderedCandidates?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 300,
    overscan: 5
  })

  useEffect(() => {
    setRenderedCandidates([])
    virtualizer.scrollToOffset(-100000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSearchRefinementId])

  const invalidateCandidateJobs = useCallback(() => {
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.job, jobId]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobs, jobId]
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobCounts, jobId]
    })
  }, [jobId])

  const debouncedInvalidateCandidateJobs = useDebounceCallback(invalidateCandidateJobs, 2000)

  useChannel({ channelName: getChannelName(CHANNEL_TYPE.CANDIDATE_SEARCH) }, (message) => {
    const { name: eventName } = message
    if (eventName === jobSearchRefinementId) {
      if (
        jobSearchRefinement?.sourcingState !== JobSourcingState.COMPLETED ||
        isNil(jobSearchRefinement.title)
      ) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.jobSearchRefinements, jobId]
        })
      }
      if (message.data?.sourcingState) {
        setIsRecommending(isSourcing(message.data.sourcingState as JobSourcingState))
        invalidateCandidateJobs()
      } else if (message.data?.candidateJobId) {
        debouncedInvalidateCandidateJobs()
      }
    }
  })

  useChannel({ channelName: getChannelName(CHANNEL_TYPE.CANDIDATE_DETAILS) }, EVENT_TYPE.CANDIDATES_UPDATE, (message) => {
    const idsSet = new Set((message.data.ids as string[]) ?? [])
    renderedCandidates?.forEach((c) => {
      if (idsSet.has(c.candidateId)) {
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.candidateActivities, c.candidateId]
        })
      }
    })
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.candidateJobs, jobId, {
        stage: CandidateJobStage.SOURCED,
        jobSearchRefinementId,
        source: null
      }]
    })
  })

  useEffect(() => {
    if (candidateJobs) {
      const sourcedCandidates = candidateJobs?.filter((c) => c.stage === 'SOURCED')

      setRenderedCandidates(sourcedCandidates)
    }
  }, [candidateJobs])

  useEffect(() => {
    setIsRecommending(isSourcing(sourcingState ?? ''))
  }, [sourcingState])

  const maxWidth = CANDIDATES_PAGES_MAX_WIDTH

  const isEmpty = useMemo((): boolean => renderedCandidates?.length === 0, [renderedCandidates])

  const isArchived = useMemo((): boolean => Boolean(job?.deleted), [job?.deleted])

  const isLoading = useMemo(
    () => isPending || isSequenceLoading || isNil(job),
    [isPending, isSequenceLoading, job]
  )

  // TODO: This is a temporary fix to prevent jitter when opening up an
  // experience item. Ideally, this should be implemented when the item is
  // opened then reset, rather than just displaying it entirely here.
  virtualizer.shouldAdjustScrollPositionOnItemSizeChange = () => false

  const isSourcingView = useMemo(
    () => !!jobSearchRefinementId,
    [jobSearchRefinementId]
  )

  const handleUpdateSearchRefinementTitle = (updatedTitle: string): void => {
    updateJobSearchRefinement({
      jobId: jobId ?? '',
      jobSearchRefinementId: jobSearchRefinementId ?? '',
      title: updatedTitle,
      automateAutoApproveCandidates: autoApproveIsEnabled
    })
  }

  const pageTitle = useMemo(() => {
    if (isManualSourceView) {
      return 'Manually Added'
    }

    if (jobSearchRefinement?.title) {
      return `Sourcing: "${jobSearchRefinement.title}"`
    }

    return 'Sourcing'
  }, [isManualSourceView, jobSearchRefinement?.title])

  const title = useMemo(() => {
    if (isManualSourceView) {
      return 'Sourcing · Manually Added'
    }
    if (jobSearchRefinement) {
      return jobSearchRefinement.title ?? 'Sourcing · New Search'
    }
    return 'Sourcing · All sourced'
  }, [isManualSourceView, jobSearchRefinement])

  // const jobSearchRefinementSuggestedAdditions = useJobSearchRefinementSuggestedAdditions({
  //   jobId,
  //   jobSearchRefinementId
  // })

  // const { searchHistory } = useJobSearchRefinementSearchHistory({
  //   jobId: jobSearchRefinement?.jobId,
  //   jobSearchRefinementId: jobSearchRefinement?.id
  // })

  const updateJobRefinementOnClick = useCallback((searchCriteria: Criteria) => {
    setIsRecommending(true)
    updateJobSearchRefinement({
      jobId: jobId ?? '',
      jobSearchRefinementId: jobSearchRefinementId ?? '',
      automateAutoApproveCandidates: autoApproveIsEnabled,
      searchCriteria,
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [
            queryKeys.candidateJobs,
            jobId,
            {
              stage: CandidateJobStage.SOURCED,
              source: null,
              jobSearchRefinementId
            }
          ]
        })
        setRenderedCandidates([])
      }
    })
  }, [
    setIsRecommending,
    updateJobSearchRefinement,
    jobId,
    jobSearchRefinementId,
    autoApproveIsEnabled,
    setRenderedCandidates
  ])

  const visibleColumns: COLUMN[] = useMemo(() => {
    const columns: COLUMN[] = [COLUMN.FAVORITE, COLUMN.NAME, COLUMN.JOB_TITLE]
    if (!isManualSourceView) {
      columns.push(COLUMN.CRITERIA, COLUMN.CRITERIA_EXPANDED)
    }
    columns.push(COLUMN.CANDIDATE_STAGE_ACTIONS)
    return columns
  }, [isManualSourceView])

  if (isPending) {
    return (
      <div
        style={{
          width: '100%',
          maxWidth,
          padding: `${CONTENT_PADDING}`
        }}
      >
        <LoadingSkeleton $variant="CandidateDetailsCard" />
      </div>
    )
  }

  return (
    <>
      <SEO title={pageTitle} />
      <CompaniesPreferencesProvider key={`${jobId}-${jobSearchRefinementId}`}>
        <div
          ref={parentRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            ...(!isPrintView
              ? {
                  contain: 'strict',
                  padding: `0 ${CONTENT_PADDING}`,
                  scrollBehavior: 'smooth'
                }
              : {}
            )
          }}
        >
          {isPrintView && <Logo variant="dark" size={64} />}
          <SourcingPageHeader
            headingLoading={jobSearchRefinement && !jobSearchRefinement.title}
            showActions={!isArchived && !jobSearchRefinementId}
            title={title}
            isEditable={(jobSearchRefinement?.title?.length ?? 0) >= 1}
            onEdit={(updatedTitle) => {
              handleUpdateSearchRefinementTitle(updatedTitle)
            }}
            jobId={jobId}
            jobSearchRefinement={jobSearchRefinement}
            candidateJobIds={renderedCandidates?.map((c) => c.id) ?? []}
            isSequenceEmpty={isSequenceStepsEmpty(sequence)}
            customActions={isManualSourceView && !job?.deleted ? [<ToggleCandidateView />] : []}
            printUrl={
              isManualSourceView
                ? RouteBuilder.build('JOBS_CANDIDATES_SOURCING_MANUAL', { jobId }, { print: true })
                : jobSearchRefinementId
                  ? RouteBuilder.build('JOBS_CANDIDATES_SOURCING', { jobId }, { jobSearchRefinementId, print: true })
                  : undefined
            }
          />
          {!isNil(job) && !isNil(jobSearchRefinement) && !job.deleted && (
            <RefinementActionsBar
              jobSearchRefinement={jobSearchRefinement}
              autoApproveIsEnabled={autoApproveIsEnabled}
              setIsRecommending={setIsRecommending}
              isRecommending={isRecommending}
              maxWidth={maxWidth}
              onSubmit={updateJobRefinementOnClick}
            />
          )}
          <IfElse
            condition={isPrintView}
            ifNode={
              <>
                {renderedCandidates?.map((candidateJob, index) => (
                  <div
                    style={{
                      width: '100%',
                      maxWidth: CANDIDATES_PAGES_MAX_WIDTH,
                      breakBefore: index === 0 ? 'avoid-page' : 'page',
                      breakInside: 'avoid',
                      breakAfter: 'avoid-page'
                    }}
                  >
                    <CandidateDetailsCard
                      isPrintView={isPrintView}
                      stage={candidateJob.stage}
                      candidate={candidateJob.candidate}
                      candidateJob={candidateJob}
                      viewMode={ViewMode.DEFAULT}
                    />
                  </div>
                ))}
              </>
            }
            elseNode={
              <IfElse
                condition={currViewMode === ViewMode.TABLE}
                ifNode={
                  (renderedCandidates?.length ?? 0) > 0
                    ? <CandidatesTablePagesContentInner
                        data-component="CandidatesTablePagesContentInner-X"
                        $padding={0}
                        $maxWidth={CANDIDATES_PAGES_MAX_WIDTH}
                      >
                        <CandidatesSourcedTable
                          candidateJobs={renderedCandidates ?? []}
                          isLoading={isLoading}
                          visibleColumns={visibleColumns}
                          pageHeaderHeight={jobSearchRefinement?.subtitle ? 132 : (jobSearchRefinementId ? 116 : 64)}
                        />
                      </CandidatesTablePagesContentInner>
                    : null
                }
                elseNode={
                  <div
                    style={{
                      minHeight: virtualizer.getTotalSize(),
                      position: 'relative'
                    }}
                  >
                    {virtualizer.getVirtualItems().map((virtualRow) => {
                      const candidateJob = renderedCandidates?.[virtualRow.index]
                      if (isNil(candidateJob)) {
                        return <></>
                      }
                      return (
                        <div
                          style={{
                            width: '100%',
                            maxWidth,
                            position: 'absolute',
                            top: virtualRow?.start ?? 0
                          }}
                          key={virtualRow.key}
                          data-index={virtualRow.index}
                          ref={virtualizer.measureElement}
                        >
                          <CandidateDetailsCard
                            key={candidateJob.id}
                            stage={candidateJob.stage}
                            candidate={candidateJob.candidate}
                            candidateJob={candidateJob}
                            actions={
                              <CandidateActions
                                candidateJob={candidateJob}
                                onAddToSequenceClick={() => {
                                  if (isSequenceStepsEmpty(sequence)) {
                                    openDialog(DialogId.CREATE_SEQUENCE)
                                  } else {
                                    addCandidateToSequence([candidateJob.id])
                                  }
                                }}
                              />
                            }
                            viewMode={currViewMode}
                          />
                        </div>
                      )
                    })}
                  </div>
                }
              />
            }
          />
          {/*
          <UpgradeCtaBanner
            heading="10k other candidates found"
            benefits={[
              'Unlock this premium feature for unlimited results',
              'Auto-outreach to new candidates daily',
              'Get more meetings with high-quality candidates'
            ]}
            addOn="AI Sourcing"
            addOnDescription="Add AI sourcing to your open job positions."
            addOnPrice={99}
          />
          */}
          <When condition={!isPrintView && !!jobSearchRefinementId}>
            <SourcingStatus
              isRecommending={isRecommending}
              isEmpty={isEmpty}
              lastRequestedAt={jobSearchRefinement?.lastSourcingRequestedAt ?? new Date()}
              isSourcingView={isSourcingView}
              isManualSourceView={isManualSourceView}
              sourcingState={sourcingState}
              isLoading={isLoading}
              isArchived={isArchived}
              viewMode={currViewMode}
            />
          </When>
        </div>
      </CompaniesPreferencesProvider>
    </>
  )
}

export default JobCandidatesSourcingPage
