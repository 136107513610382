import { createContext } from 'react'

export enum DialogId {
  ADD_CANDIDATE = 'add-candidate',
  ALERT = 'alert',
  CANDIDATE_DETAILS = 'candidate-details',
  CREATE_DEPARTMENT = 'create-company',
  EDIT_DEPARTMENT = 'edit-department',
  CREATE_PROJECT = 'create-project',
  EDIT_PROJECT = 'edit-project',
  // Legacy, replace with CREATE_NEW_JOB
  CREATE_JOB_POSITION = 'create-position',
  CREATE_NEW_JOB = 'create-new-job',
  CREATE_SEQUENCE = 'create-sequence',
  DEFAULT_SETTINGS = 'default-settings',
  EDIT_CANDIDATE = 'edit-candidate',
  JOB_SETTINGS = 'job-settings',
  MOVE_TO_ANOTHER_JOB = 'move-to-another-job',
  SEARCH_CANDIDATES = 'search-candidates',
  WRITE_EMAIL = 'write-email',
  NOTIFICATIONS = 'notifications',
  DISCONNECTED_EMAIL_ACCOUNT = 'disconnected-email-account',
  SEND_FEEDBACK = 'send-feedback',
  WRITE_CUSTOM_OUTREACH = 'write-custom-outreach',
  BILLING_SUBSCRIBE = 'billing-subscribe',
  CALENDAR = 'calendar',
  ARCHIVE_JOB = 'archive-job',
  REFINEMENT_FEEDBACK = 'refinement-feedback',
  EXPORT_CANDIDATES = 'export-candidates',
  ENABLE_AUTO_OUTREACH = 'enable-auto-outreach',
  UPGRADE_YOUR_PLAN_FROM_TRIAL = 'upgrade-your-plan-from-trial',
  UPGRADE_YOUR_PLAN_FROM_PROFESSIONAL = 'upgrade-your-plan-from-professional',
  CONNECT_ATS = 'connect-ats'
}

export interface DialogArgs<T = any> {
  id: DialogId
  payload?: T
}

export interface AlertArgs {
  variant?: 'neutral' | 'tint' | 'negative'
  message: string
  description?: string
  confirmText?: string
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
}

export interface DialogContextProps {
  dialogs: Array<DialogArgs<any>>
  isDialogOpen: (id: DialogId) => boolean
  controlDialog: (id: DialogId, state: boolean) => void
  openDialog: <T>(id: DialogId, payload?: T) => void
  openAlert: (payload: AlertArgs) => void
  closeDialog: (id: DialogId) => void
  getPayload: <T = any>(id: DialogId) => T | undefined
}

export const DialogContext = createContext<DialogContextProps>({
  dialogs: [],
  isDialogOpen: () => false,
  controlDialog: () => {},
  openDialog: () => {},
  openAlert: () => {},
  closeDialog: () => {},
  getPayload: () => undefined
})
