import * as S from './index.styled'
import type { CriteriaKey, CriteriaValue, SchoolCompanyCriteria } from 'src/libs/api/backend/candidate_search'
import { CriteriaPill } from './criteria-pill'
import { RemoveCriteria } from './remove-criteria'
import { useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { SuggestionInput } from './suggestion-input'
import { useCompanySuggestion } from 'src/hooks/queries/use-company-suggestion'
import type { CompanySuggestion } from 'src/libs/api/backend/company_preferences'

interface CompanyRowProps {
  criteriaKey: CriteriaKey
  criteriaValue: SchoolCompanyCriteria[]
  onCriteriaUpdate: (criteriaKey: CriteriaKey, criteriaValue: CriteriaValue) => void
  onCriteriaRemove: (criteriaKey: CriteriaKey) => void
  disabled: boolean
}

export const CompanyRow = ({
  criteriaKey,
  criteriaValue,
  onCriteriaUpdate,
  onCriteriaRemove,
  disabled
}: CompanyRowProps): JSX.Element => {
  const [query, setQuery] = useState<string | undefined>(undefined)
  const setQueryDebounced = useMemo(() => {
    return debounce(setQuery, 300)
  }, [])
  const [companyIcons, setCompanyIcons] = useState<Record<string, string>>({})
  const { isPending, data: companySuggestions } = useCompanySuggestion({ query })
  return (
    <S.RowContainer>
      <SuggestionInput
        isLoading={isPending}
        disabled={disabled}
        placeholder={criteriaValue.length === 0 ? 'Add company' : undefined}
        items={
          <>
            {criteriaValue.map((companyValue, index) => {
              return (
                <CriteriaPill
                  key={index}
                  value={{
                    label: companyValue.name,
                    icon: companyValue.logoUrl
                      ? companyValue.logoUrl
                      : companyValue.name
                        ? companyIcons[companyValue.name]
                        : undefined,
                    optional: companyValue.optional ?? true,
                    negative: companyValue.negative ?? false
                  }}
                  type='company'
                  onUpdate={(key: string, value: boolean) => {
                    const updatedCriteriaValue = [...criteriaValue]
                    updatedCriteriaValue[index] = {
                      ...updatedCriteriaValue[index],
                      [key]: value
                    }
                    onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
                  }}
                  onRemove={() => {
                    const updatedCriteriaValue = criteriaValue.filter((_, i) => i !== index)
                    onCriteriaUpdate(criteriaKey, updatedCriteriaValue)
                  }}
                />
              )
            })}
          </>
        }
        triggerSearch={setQueryDebounced}
        suggestions={companySuggestions ?? []}
        onSelect={(suggestion) => {
          onCriteriaUpdate(
            criteriaKey,
            [...criteriaValue, {
              name: suggestion.name,
              linkedin: (suggestion as CompanySuggestion).linkedin ?? undefined,
              domain: (suggestion as CompanySuggestion).website ?? undefined,
              logoUrl: (suggestion as CompanySuggestion).logoUrl ?? undefined,
              optional: false,
              negative: false
            }]
          )
          if ('logoUrl' in suggestion && suggestion.logoUrl) {
            setCompanyIcons({
              ...companyIcons,
              [suggestion.name]: suggestion.logoUrl
            })
          }
        }}
      />
      <RemoveCriteria
        onRemove={() => {
          onCriteriaRemove(criteriaKey)
        }}
        disabled={disabled}
      />
    </S.RowContainer>
  )
}
